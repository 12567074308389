<template>
  <div class="page-rooter-fff bg-gray">
    <nav-bar v-if="(appTypeStr === 'ios' || appTypeStr === 'and')" :header-title="title" :show-back="true"
      :headerBack="headerBack" :showShare="true" @right-txt-click="h5shareFn" />
    <app-page class="ios-bottom" id="pageId" :style="appTypeStr === 'ios' || appTypeStr === 'and' ? '' : 'height:100vh'">
      <!--            <div ref="top"  >-->
      <!-- appTypeStr !== 'mini' ? 'pdd' : '' -->
      <div :class="['top', 'bg-white',]">
        <!--                <div class="banner"></div>-->
        <img v-if="bannerImg" ref="top" class="banner" :src="bannerImg">

        <div :class="topFixed" ref="fixedRef">
          <div class="date-cont flex flex-start " v-if="formatType == 1">
            <!--                       -->
            <div v-for="(item, index) in dateArr" :key="index"
              :style="index === dateArr.length - 1 ? 'padding-right:0.75rem' : ''"
              :class="['date-for-cont', index === dateNowIndex ? 'active' : '', item.isHaveLesson === 0 ? 'grey' : '']"
              @click="dateClick(item, index)">
              <img v-if="item.isHaveLesson === 0" class="date-icon"
                src="https://img.chaolu.com.cn/MINI/icon/date-nolesson-icon.png">
              <img v-else-if="item.isOpen === 0" class="date-icon"
                src="https://img.chaolu.com.cn/MINI/icon/date-noopen-icon.png">
              <div class="date-weak">{{ item.week }}</div>

              <div class="date-day">
                {{ item.day }}
              </div>
            </div>

          </div>
          <div v-show="dateArr[dateNowIndex] && dateArr[dateNowIndex].toBeOpenNotice"
            class="wait-tip row-start-center f24 fw6">{{ dateArr[dateNowIndex] && dateArr[dateNowIndex].toBeOpenNotice }}
          </div>
          <div class="city-select-box bg-white flex flex-between">
            <div class="city-box-1" @click="cityShow = true" style="margin-left:1rem">
              <span class="vam">{{ cityTotal }}</span>
              <img class="icon-down" src='http://wx.chaolu.com.cn/images/appWeb/coachingModule/workbench/down.png' alt="">
            </div>
            <div class=" pl10" @click="storeShow = true" style="margin-right:1rem">
              <span class="vam " style="text-align: right">{{ storeTotal }}</span>
              <img class="icon-down" src='http://wx.chaolu.com.cn/images/appWeb/coachingModule/workbench/down.png' alt="">
            </div>
          </div>
        </div>

      </div>
      <!--            <van-pull-refresh v-model="isLoading" @refresh="onRefresh">-->
      <div class="main-cont" ref="mainCont" :style="mainContStyle">
        <!--            <div class="main-cont" ref="mainCont" style="padding-top: 282px">-->

        <div class="main pb20">

          <div v-if="messageList.length !== 0">
            <van-list :finished="finished" @load="onLoad" :error.sync="error" finished-text="没有更多了"
              error-text="请求失败，点击重新加载" id="listContain" class="van-list-contain" v-model="loading">
              <div v-for="(item, index) in messageList" :key="index" @click="goDetail(item, index)"
                class="content-center-cont">
                <div class="title-cont flex flex-between">
                  <div class="title">{{ item.title }}</div>
                  <div class="distant" v-if="item.distance || item.distance === 0">
                    {{ item.distance | distance }}
                  </div>
                </div>

                <div class="content-center flex flex-center"
                  :style="subIndex == item.scheduleLessonList.length - 1 ? 'margin-bottom:0' : ''"
                  v-for="(subItem, subIndex) in item.scheduleLessonList" @click="ruinapp(subItem.mouldId)"
                  :key="subItem.courseId + subIndex">

                  <img class="userImg"
                    :src="subItem.teacherImg ? $getImgUrl(subItem.teacherImg) : 'https://chaolucoachwebstatic.chaolu.com.cn/static/img/classteacherDefault.jpg'"
                    alt="">

                  <div class="message-content">
                    <div class="course-name nowrap">{{ subItem.courseName }}</div>
                    <div class="course-detail flex flex-center my-flex-start">
                      <div style="margin-right: 0.25rem" v-if="formatType == 2">
                        {{ dateChange(subItem.lessonDate) }}
                      </div>
                      <div class="time start-time ">{{ subItem.startTime }}</div>
                      <div>-</div>
                      <div class="time end-time ">{{ subItem.endTime }}</div>
                      <!--												<div style="color:#DDDDDD">|</div>-->
                      <div class="gan"
                        :style="appTypeStr === 'mini' ? ' margin: 0rem 0.5rem 0 0.5rem;' : ' margin: 0.15rem 0.5rem 0 0.5rem;'">
                      </div>
                      <div class="price">￥{{ subItem.costMoney }}</div>
                    </div>
                    <div class="course-teacher"><span>{{ subItem.teacherName }}</span><span class="tips"
                        v-if="subItem.firstClassVoucher == 1">{{ subItem.firstClassVoucherText }}</span></div>
                  </div>

                  <div class="tag tag1" v-if="subItem.hot == 2"><img
                      src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/selfGroupClass/icon_full.png">
                  </div>
                  <div class="tag tag2" v-else-if="subItem.hot == 1"><img
                      src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/selfGroupClass/icon_hot.png">
                  </div>
                </div>
              </div>
            </van-list>
          </div>
          <div v-if="messageList.length === 0 && finished" class="empty">
            <img class="empty-img"
              src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/selfGroupClass/emptyList.png" alt="">
            <div class="empry-text">鹿团团正在努力排课中，敬请期待</div>
          </div>
        </div>
      </div>
      <!--            </van-pull-refresh>-->

    </app-page>
    <cl-city-venue :data="cityList" :popShow.sync="cityShow" @popfun="choseCityPop" type='city'
      :chose-item="choseItem"></cl-city-venue>
    <cl-city-venue2 :data="storeList" :popShow.sync="storeShow" @popfun="choseStorePop" type='venue'
      :chose-item="choseItem"></cl-city-venue2>
    <van-popup v-model="loadingShow" :overlay="false" style="background: none">
      <van-loading></van-loading>
    </van-popup>
  </div>
</template>

<script>
import wx from "weixin-js-sdk"
import appMixin from "@/mixin/appMixin";
import navBar from "@/components/nav-bar/nav-bar";
import clCityVenue from "@/components/cl-city-venue/index";
import clCityVenue2 from "@/components/cl-city-venue/index3";
import appPage from "@/common/components/appPage";
import { hideAppBackBtn, appBack } from "@/lib/appMethod"
import {
  // PullRefresh,
  Popup,
  Loading,
  List,
} from 'vant';
import {
  appGetCityId,
  appGetLocal,
  appGetUser,
  appGetDevice,
  appGetLocalVenue,
  appGetToken,
  appType
} from "../lib/appMethod";


export default {
  mixins: [appMixin],
  components: {
    navBar,
    appPage,
    clCityVenue,
    clCityVenue2,
    // vanPullRefresh: PullRefresh,   
    vanLoading: Loading,
    vanList: List,
    vanPopup: Popup
  },
  data() {
    return {
      id: '',
      // userId: '',
      cityId: '',
      // 日期展示
      formatType: '1',
      // 顶部css
      sliderStyle: {},
      // appType
      appTypeStr: '',
      latAndlng: [],
      cityAndVenue: [],
      // 顶部图片
      bannerImg: '',
      title: '',
      // 顶部class
      topFixed: '',
      mainContStyle: {},

      sysUserId: "",
      sysToken: '',
      roleId: '', //角色
      baseImgUrl: '/images/appWeb/coachingModule/workbench/', //基本的图片地址
      // 日期数组
      dateArr: [],
      // 当前展示日期
      dateNowIndex: 0,
      dateNow: '',
      local: "", // 经纬度

      choseItem: {}, //原生的选择对象
      cityList: [], //城市弹窗-数组
      initCityList: [], //城市弹窗-数组
      cityShow: false, //城市弹窗-显示
      cityTotal: '', //城市选择数据显示

      provinceId: null, //省份ID
      // cityId: null, //城市弹窗-城市ID
      areaId: null, //城市弹窗-区域ID
      venueId: [], //门店弹窗-门店id

      storeList: [], //门店弹窗-数组
      storeShow: false, //门店弹窗-数组
      storeTotal: '全部门店', //门店选择数据显示

      startTime: '',
      endTime: '',
      pageNum: 1,
      totalList: [], //统计模块数据
      totalItemCheck: '', //统计模块选择项
      error: false,
      isLoading: false,
      hasSessin: false,
      loading: false,
      finished: false,
      // messageList: lesson,
      messageList: [],
      isClickindex: null,
      hasMounted: false,
      feedbackClassifyType: '0', // 类型选中的值
      loadingShow: false,
      checkNum: 0,

    };
  },
  filters: {
    distance(val) {
      let num = val * 1
      if (num < 100) {
        return `距您小于100m`
      } else if (num < 1000) {
        return `距您${num.toFixed(0)}m`
      } else {
        return `距您${(num / 1000).toFixed(2)}km`
      }
    }
  },
  async created() {
    console.log(666666666666666, this.$route.query);
    this.remAdaptate2(true);
    this.appTypeStr = appType()
    this.id = this.$route.query.topicId
    this.venueId = this.$route.query.venueId
    // let params =  getParam()
    // this.userId = this.$route.query.userId
    this.dateNow = this.dateToString(new Date())
    if (this.appTypeStr === 'ios' || this.appTypeStr === 'and') {
      hideAppBackBtn()
    }
  },
  mounted() {
    this.load()
  },
  methods: {
    // 页面初次加载
    async load() {
      this.scorllListener()
      // this.baseURLApp = 'https://app.chaolu.com.cn'
      // this.iosBackListener()
      setTimeout(() => {
        this.hasMounted = true
      })
      if (this.appTypeStr === 'ios' || this.appTypeStr === 'and') {
        try {
          this.appCommParams = await this.getAppParams2([
            appGetCityId(),
            appGetLocal(),
            appGetUser(),
            appGetDevice(),
            appGetLocalVenue(),
            appGetToken(),
          ]);

          if (!this.userId) {
            await this.getUserIdByAPI()
          }
          this.latAndlng = this.appCommParams[1].replace('lat-', '').replace('long-', '').replace(RegExp("Failed", "g"), '').split('|')
          if (!this.latAndlng[1]) {
            this.latAndlng[1] = ''
          }
          this.cityId = this.appCommParams[0]
          // 标题里面同时含有 ’拜月‘和’中秋瑜伽‘ 上报埋点
          if (this.$route.query.title && this.$route.query.title.indexOf('拜月') && this.$route.query.title.indexOf('中秋瑜伽')) {
            this.countPoint('29', '203', '1313')
          }
        } catch (err) {
        }
      } else if (this.appTypeStr === 'mini') {
        this.cityId = this.$route.query.cityId
        let lat = this.$route.query.latitude
        let lng = this.$route.query.longitude
        this.userId = this.$route.query.userId
        if (lat !== '0') {
          this.latAndlng[0] = lat
        }
        if (lng !== '0') {
          this.latAndlng[1] = lng
        }
        if (this.$route.query.title && this.$route.query.title.indexOf('拜月') && this.$route.query.title.indexOf('中秋瑜伽')) {
          this.countPoint('29', '203', '1313')
        }
      }
      await this.initHtml()
      await this.getDateArr()
      this.getCityAndVenue()
      // 燃脂搏击 渠道来源埋点
      this.countPointExt()
      // 朋友圈广告转化
      this.clickId = this.$route.query.clickId
      this.addUserAction('CONFIRM_EFFECTIVE_LEADS')

    },
    countPointExt() {
      if (this.id === '1420277316394708993' && this.$route.query.channelCode) {
        this.$axios.post(this.baseURLApp + '/dataPoint/dataPoint', {
          userId: this.userId,
          activityType: 52,
          activityId: 322,
          templateId: 1621,
          count: "0",
          amount: "0",
          ext1: this.$route.query.channelCode
        }).then((res) => {
          console.log('埋点成功', this.$route.query.channelCode)
        })
      }
    },
    /**
     * 小程序广告转化
     * @param actionType 预定义的行为类型，目前只支持COMPLETE_ORDER（下单）及RESERVATION（表单预约）、CONFIRM_EFFECTIVE_LEADS（有效销售线索）
     */
    addUserAction(actionType) {
      if (!this.clickId) {
        return false
      }
      this.$axios.post(`${this.baseURLApp}/chaolu-wx-applet/action/addUserAction/v1`, {
        actionUrl: '/selfGroupClass',
        clickId: this.clickId,
        actionType: actionType
      }, false).then(res => {
        console.log('广告数据转化埋点：类型', actionType, '返回参数', res)
      }).catch(r => {
        console.log('广告数据转化埋点：类型', actionType, '埋点接口调用失败')
      })
    },
    // 返回键
    headerBack() {
      if (window.history.length > 1) {
        this.$router.back(-1);
      } else {
        appBack()
      }
    },
    initHtml() {
      return new Promise((resolve, reject) => {
        let params = {
          id: this.id,
          cityId: this.cityId,
          userId: this.userId,
          lat: this.latAndlng[0],
          lng: this.latAndlng[1]
        }
        this.$axios.post(this.baseURLApp + '/lessionh5/main', params).then(res => {
          if (res.code == 1) {
            // if (res.data.status === 0) {
            //     // return
            //     this.$router.push("emptyPage")
            // }
            this.bannerImg = res.data.image
            this.cityId = this.cityId || res.data.cityId || '3'
            this.formatType = res.data.formatType
            // this.formatType = 2
            this.$nextTick(() => {
              document.title = res.data.navigateTitle || '超鹿运动';
              this.title = res.data.navigateTitle;
            })
            // let _host= window.location.protocol + '//' + window.location.host
            let _shareAppletUrl = res.data.shareAppletUrl || `/pages/webView/index?webUrl=${this.locationUrl}/#/selfGroupClass&userId=1&location=1&cityId=1&topicId=${res.data.id}`
            this.h5ShareObj = {
              title: res.data.shareAppletTitle,
              path: _shareAppletUrl,
              image: res.data.shareAppletImage
            }
            if (this.appTypeStr === 'mini') {
              wx.miniProgram.postMessage({
                data: {
                  // shareImage: 'https://chaolucoachwebstatic.chaolu.com.cn/static/img/anniversary2021/mini-share.png',
                  // shareTitle:'超鹿3周年限时充值特惠，低至5折，别再错过啦！',
                  // shareUrl:'/pages/webView/index?webUrl=appWeb/activePage/anniversary2021-recharge.html&userId=1&token=1&location=1'
                  type: 'share',
                  shareTitle: res.data.shareAppletTitle,
                  shareUrl: _shareAppletUrl,
                  shareImage: res.data.shareAppletImage,
                }
              });
            }

          }
          resolve()
        }).catch(() => {
          reject()
        })
      })
    },
    // 获取城市门店
    getCityAndVenue() {
      this.$axios.post(this.baseURLApp + '/app/sys/area/lessionh5/top').then(res => {
        if (res.code == 1) {
          this.cityAndVenue = []
          this.cityList = res.data[0].children
          this.initCityList = JSON.parse(JSON.stringify(res.data[0].children))
          // this.provinceId
          // res.data[0].map
          // this.cityId = '3'
          this.setCityAndStore()
        }
      })
    },
    async getAppParams2(promiseArr) {
      return await Promise.all(promiseArr)
    },
    // rem适配
    remAdaptate2(type) {
      !(function (n) {
        var e = n.document,
          t = e.documentElement,
          i = 750,
          d = i / 32,
          o = "orientationchange" in n ? "orientationchange" : "resize",
          a = function () {
            var n = t.clientWidth || 320;
            n > 750 && (n = 750);
            t.style.fontSize = n / d + "px";
          };
        if (type) {
          a()
          // fontSize需要在页面加载之前立即执行，这样才能在获取在rem值下生成的正确的宽高，而不需要根据原始的去处理
          //先这样处理。预防之前的页面出现问题
        }
        e.addEventListener &&
          (n.addEventListener(o, a, !1),
            e.addEventListener("DOMContentLoaded", a, !1));
      })(window);
    },
    getUserIdByAPI() {
      return new Promise((resolve, reject) => {
        let params = {
          userData: this.appCommParams[2],
          token: this.appCommParams[5]
        }
        this.$axios.post(this.baseURLApp + '/app/decryptUserData/v1', params).then((res) => {
          if (res.code == 1) {
            this.userId = res.data.id
            resolve()
          } else {
            reject()
          }
        }).catch(() => {
          reject()
        })
      })

    },
    // appBackFun() {
    //     if (this.appType() === 'ios') {
    //         window.webkit.messageHandlers.navBack.postMessage("11")
    //     } else {
    //         App.app_finish()
    //     }
    // },
    // 获取日期
    getDateArr() {

      return new Promise((resolve, reject) => {
        if (this.formatType != 2) {
          resolve()
        }
        this.$axios.post(this.baseURLApp + '/scheduleLesson/lessonDateWhenH5Activity', {
          topicId: this.id,
          venueIds: this.venueId,
          cityId: this.cityId
        }).then(res => {
          this.dateArr = res.data
          // this.dateArr = [res
          resolve()
        }).catch((e) => {
          reject()
        })
      })
    },
    // 获取建议列表
    getData({ replace = false } = {}) {
      if (replace) {
        this.pageNum = 1
        scrollTo(0, 0)
      }
      this.loadingShow = true
      let param = {
        id: this.id,
        // date: this.dateNow,
        userId: this.userId,
        cityId: this.cityId,
        venueIds: this.venueId,
        lat: this.latAndlng[0],
        lng: this.latAndlng[1]

      }
      let url = this.formatType == 1 ? '/scheduleLesson/lessonListWhenH5' : '/scheduleLesson/lessonListWhenNoDateH5'
      param.date = this.formatType == 1 ? this.dateNow : ''
      this.$axios.post(this.baseURLApp + url, param).then((res) => {
        // /lessionh5/list
        this.isLoading = false
        if (res.code === '1') {
          console.log(1)
          this.refreshing = false
          this.error = false
          // if (replace) { // 是否重新输入

          this.messageList = res.data.filter(x => x.scheduleLessonList && x.scheduleLessonList.length > 0)

          // this.messageList.push(...this.messageList)
          // } else {
          //     // this.messageList.push(...res.data)
          //     this.messageList = res.data
          // }
          // this.pageNum = res.data.pageNum
          // this.finished = !res.data.hasNextPage
          this.finished = true
          // this.pageNum++
        } else {
          console.log(2)
          this.error = true
        }
        this.refreshing = false
        this.loading = false
        this.loadingShow = false
      }).catch(() => {
        console.log(3)
        this.error = true
        this.refreshing = false
        this.loading = false
        this.loadingShow = false
      })
    },
    // 日期点击
    dateClick(item, index) {
      if (this.loadingShow) {
        return
      }
      // if (item.isHaveLesson === 0) return
      this.dateNowIndex = index
      this.dateNow = item.date
      this.getData()

    },
    async getAppMethodListener(appMethodName) {
      return new Promise((resolve) => {
        try {
          window[`${appMethodName}`] = (res) => {
            let obj = {}
            obj[`${appMethodName}`] = res
            resolve(obj)
          }
        } catch (error) {
          resolve()
        }
      })
    },
    //下拉刷新
    onRefresh() {
      // this.getData(true)
    },
    h5shareFn() {
      var params = {}
      var shareParam = {
        title: '',
        image: "",
        // image:base64,
        path: "",
        miniProgramType: "0", // 正式0，开发1，体验2
      }
      shareParam = Object.assign(shareParam, this.h5ShareObj)
      params.type = "3";
      shareParam.image = shareParam.image || shareParam.appletsImage
      try {
        shareParam.miniProgramType = this.isTestEnvironment ? '2' : '0'
      } catch (e) {

      }
      params.params = Object.assign({}, shareParam, {
        url: "", //必填，不然报错
        multiple: "0.95",
        userName: "gh_7f5b57b6a76e",
        scene: "0", // 0 好友 1朋友圈
      });
      try {
        if (this.appTypeStr == "and") {
          App.onShareWeCharAndWeZoom(JSON.stringify(params))
        } else if (this.appTypeStr == "ios") {
          window.webkit.messageHandlers.wechatShare.postMessage(params);
        }
      } catch (e) {

      }
    },
    // 城市弹出窗口选择的数据
    choseCityPop(val, param, choseName) {
      this.cityShow = false
      this.cityTotal = choseName || this.cityTotal
      if (param) {
        this.choseItem = {
          ...this.choseItem,
          ...param
        }
        var {
          provinceId,
          cityId,
          areaId,
          venueId,
          provinceName,
          cityName,
          areaName,
          venueName
        } = this.choseItem
        this.provinceId = provinceId
        this.cityId = cityId
        this.areaId = areaId
        this.venueId = venueId || []
        this.provinceName = provinceName
        this.cityName = cityName
        this.areaName = areaName
        this.venueName = venueName
        // this.getStore({
        //     type: '4',
        //     parentId: provinceId,
        //     cityId: cityId
        // })
        this.hasSessin = false
        this.setCityAndStore()
        // this.getData()
        // this.getGroupCourse()
      }
    },

    //门店弹出窗口返回的数据
    async choseStorePop(val, param, choseName) {
      this.storeShow = false
      this.storeTotal = choseName || this.storeTotal
      if (param) {
        var {
          areaId,
          venueId,
          areaName,
          venueName
        } = param
        this.areaId = areaId
        this.venueId = venueId || []
        this.choseItem.venueId = venueId || []
        this.choseItem.areaId = areaId
        this.areaName = areaName
        this.venueName = venueName
        // this.hasSessin = false
        this.setCityAndStore()
        await this.getDateArr()
        this.getData()
      }
    },


    //统计数据开始-反馈意见

    //请求反馈数据
    onLoad() {
      // this.getData()
    },

    // 去详情页面
    goDetail(item, index) {

      this.messageList[index].isRead = 1
      this.isClickindex = index
      this.$forceUpdate()
      // this.setCityAndStore()
      // window.location.href = '/appWeb/coachingModule/workbench/suggestionDetail.html?id=' + item.id

    },
    setCityAndStore() {
      if (this.cityId) {
        let _province = this.cityList.find(x => {
          return x.children.some(y => y.id === this.cityId)
        })
        this.choseItem.cityId = this.cityId
        this.choseItem.provinceId = _province.id
        this.cityList.forEach((value, index) => {
          let _result = value.children.find(y => y.id === this.cityId)
          if (_result) {
            this.storeList = _result.children
          }
        })
      }
    },
    // ios返回监听
    iosBackListener() {
      window.addEventListener('pageshow', (event) => {
        if (event.persisted) {
          this.isClickindex = null
        }
      }, false)
    },
    // 去搜索页面
    searchClick() {
      // window.location.href = '/appWeb/coachingModule/workbench/searchPage.html?messageType=1'
    },
    dateToString(date) {
      var year = date.getFullYear();
      var month = (date.getMonth() + 1).toString();
      var day = (date.getDate()).toString();
      if (month.length == 1) {
        month = "0" + month;
      }
      if (day.length == 1) {
        day = "0" + day;
      }
      var dateTime = year + "-" + month + "-" + day;
      return dateTime;
    },
    // 滚动监听
    scorllListener() {
      // this.handleScroll()
      window.addEventListener("scroll", this.handleScroll, true);

      // $("#app").on('touchmove', (() => {
      //     this.handleScroll()
      // }));
    },
    // 滚动css变化
    handleScroll(e) {
      // var listContainH = document.getElementById('listContain').offsetHeight
      let top =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      let domTop = 0
      let _dom = document.getElementById('pageId')
      if (this.$refs.top) {
        domTop = domTop + this.$refs.top.clientHeight
      }

      if (_dom && top == 0) {
        top = _dom.scrollTop
      }
      if (top > domTop) {

        this.topFixed = ((this.appTypeStr === 'and' || this.appTypeStr === 'ios')) ? 'top-fixed top13' : 'top-fixed'
        let _fixech = this.$refs.fixedRef.clientHeight + 'px'
        this.mainContStyle = { 'padding-top': _fixech }
        // }, 10)
      } else {

        this.topFixed = ''
        this.mainContStyle = {}

      }
    },
    dateChange(data) {
      let _date = new Date(data)
      let m = _date.getMonth() + 1 < 10 ? '0' + (_date.getMonth() + 1) : _date.getMonth() + 1
      let d = _date.getDate()
      return m + '月' + d + '日'
    },
    ruinapp(val) {
      if (this.$route.query.title && this.$route.query.title.indexOf('拜月') && this.$route.query.title.indexOf('中秋瑜伽')) {
        this.countPoint('29', '203', '1314')
      }
      if (this.appTypeStr === 'and') {
        App.cruin("courseDetail", val);
        //这个是安卓操作系统
      }
      if (this.appTypeStr === 'ios') {
        window.webkit.messageHandlers.courseDetail.postMessage(val);
        //这个是ios操作系统
      }
      if (this.appTypeStr === 'mini') {
        wx.miniProgram.navigateTo({
          url: '/pages/leagueLecture/courseDetail/couseDetail?mouldId=' + val
        })
      }
    },

  },
  watch: {
    async cityId(val) {
      this.venueId = []
      this.choseItem.venueId = []
      this.choseItem.areaId = ""
      this.storeTotal = "全部门店"
      this.cityList = JSON.parse(JSON.stringify(this.initCityList))
      await this.getDateArr()
      this.getData()
    },
  },
  beforeDestroy() { //在组件生命周期结束的时候销毁。
    window.removeEventListener('scroll', this.handleScroll);
  },
};
</script>

<style lang="less" scoped>
.date-for-cont {
  flex: none;
  width: 2rem;
  text-align: center;
  padding-right: 1.285rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.date-for-cont:first-child {
  padding-left: 0.75rem;
}

.top-fixed {
  position: fixed;
  width: 100%;
  top: 0;
  background: #ffffff;
  /*padding: 0 0.75rem;*/
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);
  z-index: 10
}

.top13 {
  top: 13.3vw;
}

.vam {
  font-size: 0.81rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #6C727A;
  line-height: 1.25rem;
}

.top {
  overflow: hidden;
  /*position: absolute;*/
  z-index: 100;
  width: 100%;

  &.pdd {
    padding-top: calc(88px + constant(safe-area-inset-top));
    padding-top: calc(88px + env(safe-area-inset-top));
  }
}

.ios-bottom {
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom)
}

.main-cont {
  overflow: hidden;
  margin: 0 auto;
}

.bg-gray {
  background: #F9FAFB;
}

.bg-white {
  background: #fff;
}

.page-rooter-fff {
  min-height: 100vh;

  .c-img {
    width: 100%;
    position: relative;

    img {
      width: 100%;
      display: block;
    }

    .btn {
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;

      .btn-img {
        display: block;
        margin: 0 auto;
        width: 43.7333333vw !important;
      }
    }
  }
}
</style>
<style scoped>
[v-cloak] {
  display: none;
}

@font-face {
  font-family: 'bebasneuebold';
  src: url('/css/font/BebasNeue.otf');
}

.font-neue {
  font-family: 'bebasneuebold';
}

body {
  color: #3C454E;
  background: #F7F7F7;
}

::-webkit-scrollbar {
  display: none;
  /* Chrome Safari */
}

.content {
  background: #F9FAFB;
}

.header {
  z-index: 1999;
}

.wait-tip {
  width: 100%;
  height: 80px;
  background: #FFEADA;
  color: #FF6E00;
  padding: 0 32px;
  margin-top: 16px;
}

.city-select-box {
  height: 3rem
    /* 48/16 */
  ;
  width: 100%;
  padding-top: 1.0625rem;
  /* 17/16 */
  ;
  /*padding-left: 0.75rem*/
  /* 12/16 */
  ;
  color: #6C727A;
  font-size: 0.875rem
    /* 14/16 */
  ;
  display: flex;
  box-sizing: border-box;
  line-height: 0.875rem
    /* 14/16 */
  ;
}

.icon-down {
  display: inline-block;
  width: 0.75rem
    /* 12/16 */
  ;
  vertical-align: middle;
  margin-left: 0.25rem
    /* 4/16 */
  ;
  /*margin-right: 0.625rem*/
  /* 10/16 */
  ;
}

.city-box-border {
  border-left: 1px solid #DDDDDD;
  height: 1.0625rem
    /* 17/16 */
  ;
}

.time-select-box {
  height: 2.4375rem
    /* 39/16 */
  ;
  width: 100%;
  /*background: palevioletred;*/
  box-sizing: border-box;
  border-bottom: 1px solid #DDDDDD;
  display: flex;
}

.calendar-box {
  display: flex;
  align-items: center;
  position: relative;
}

.calendar-box.time-check:before {
  bottom: 0rem
    /* 0/16 */
  ;
}

.calendar-line {
  width: 0.5625rem
    /* 9/16 */
  ;
  height: 1.5rem
    /* 24/16 */
  ;
  background: linear-gradient(to right, rgba(247, 247, 247, 0), #ECEAEA);
}

.icon-calendar {
  width: 1.75rem
    /* 28/16 */
  ;
  margin-right: 0.4375rem
    /* 7/16 */
  ;
}

.time-chose-list {
  display: flex;
  font-size: 0.75rem
    /* 12/16 */
  ;
  color: #9AA1A9;
  padding-left: 0.75rem
    /* 12/16 */
  ;
  align-items: center;
}

.time-chose-list>div {
  margin-right: 2.625rem
    /* 42/16 */
  ;
  line-height: 2.375rem
    /* 38/16 */
  ;
}

.time-chose-list>.time-check {
  color: #242831;
  position: relative;
}

.time-check:before {
  content: '';
  display: inline-block;
  width: 0.9375rem
    /* 15/16 */
  ;
  height: 0.125rem
    /* 2/16 */
  ;
  position: absolute;
  background: #FFDE00;
  /*bottom: -0.6875rem  !* -11/16 *!;*/
  bottom: -0.0625rem
    /* -1/16 */
  ;
  left: 50%;
  transform: translateX(-50%);
}

.total-box {
  width: 100%;
  background: #F7F7F7;
  height: 11.375rem
    /* 182/16 */
  ;
  padding-top: 1.25rem
    /* 20/16 */
  ;
  box-sizing: border-box;
}

.total-list::-webkit-scrollbar {
  display: none;
  /* Chrome Safari */
}

.total-list {
  margin-top: 1.25rem
    /* 20/16 */
  ;
  display: flex;
  padding-left: 0.75rem
    /* 12/16 */
  ;
  width: 100%;
  overflow: scroll;
  box-sizing: border-box;
  height: 8.75rem
    /* 140/16 */
  ;
}

.total-list-box {
  /*width: 4000px;*/
  display: flex;
  /*flex-wrap: nowrap;*/
}





.chart-box {
  background: white;
  padding: 1.5rem
    /* 24/16 */
    0.75rem
    /* 12/16 */
  ;
}


.main {
  /* background: #fff; */
  overflow: hidden;
}

.content-center-cont {

  margin: 1.5rem 0.75rem 0.75rem 0.75rem;
  overflow: hidden;
}

.content-center-cont .title {
  height: 1.38rem;
  font-size: 0.88rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #242831;
  line-height: 1.38rem;
}

.content-center-cont .distant {
  height: 1.38rem;
  font-size: 0.75rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #6C727A;
  line-height: 1.38rem;
}

.content-center-cont .title-cont {
  margin-bottom: 0.75rem;
}

.content-center {
  width: 21.94rem;
  height: 6.75rem;
  justify-content: flex-start;
  background: #FFFFFF;
  border-radius: 0.38rem;
  margin-bottom: 0.75rem;
  overflow: hidden;
  position: relative;
}

.content-center .tag {
  position: absolute;
  top: 0;
  right: 0;
}

.content-center .tag1 img {
  width: 3.5rem;
  height: 1.25rem;
}

.content-center .tag2 img {
  width: 4.19rem;
  height: 1.25rem;
}

/*详情列表*/
.pull-title {
  margin-top: 1.5rem;
  /*margin-bottom: 0.25rem;*/
  height: 1rem;
  font-size: 0.88rem;
  font-family: PingFang SC;
  font-weight: bold;
  color: #3C454E;
  line-height: 1rem;
}

.pull-title-img {
  padding-left: 1rem;
  height: 1rem;
  width: 1rem;
}

.suggestion-message {
  /*height: 2.63rem;*/
  /*padding-top: 0.38rem;*/
  font-size: 0.88rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #232831;
  line-height: 1.31rem;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.suggestion-user {
  margin-top: 0.38rem;
  margin-bottom: 1.25rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-size: 0.75rem;
}

.suggestion-user .phone {
  color: #6C727A;
}

.suggestion-user .date {
  color: #9AA1A9;
}

.has-read .phone,
.has-read .suggestion-message,
.has-read .date {
  color: #BBBBBB;
}

/*.border-bottom {*/
/*	border-bottom: 0.06rem solid #EEEEEE;*/
/*}*/

.pt125 {
  margin-top: 1.25rem;
}

.userImg {
  width: 4.25rem;
  height: 4.25rem;
  border-radius: 2.13rem;
  margin-left: 0.75rem;
  margin-right: 0.75rem;
  /*float: left*/
}

.message-content {
  /*float: right;*/
  /*width: 15.44rem*/
}

.message-content .course-name {
  width: 15.44rem;
  height: 1.5rem;
  font-size: 1rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: bold;
  color: #242831;
  line-height: 1.5rem;
  margin-bottom: 0.25rem;
}

.nowrap {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.message-content .course-detail {
  overflow: hidden;
  height: 1.38rem;
  font-size: 0.88rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: bold;
  color: #3C454E;
  line-height: 1.38rem;
}

.message-content .price {
  height: 1.38rem;
  font-size: 0.88rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: bold;
  color: #ED5C42;
  line-height: 1.38rem;
}

.message-content .gan {
  width: 0.12rem;
  height: 0.8rem;
  background: #DDDDDD;
  /*margin: 0.15rem 0.5rem 0 0.5rem;*/
}

.message-content .course-teacher {
  height: 1.13rem;
  font-size: 0.69rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #6C727A;
  line-height: 1.13rem;
  display: flex;
  align-items: center;
}

.message-content .course-teacher .tips {
  color: #F03C18;
  font-size: 18px;
  border: 1px solid #F03C18;
  padding: 4px 9px;
  border-radius: 4px;
  line-height: 1.2;
  margin-left: 16px;
}


.mb75 {
  margin-bottom: 0.75rem;
}

.mt75 {
  margin-top: 0.75rem;
}

.empry-text {
  font-size: 0.88rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #242831;
  text-align: center;
  width: 100%;
  margin-top: 0.5rem;
}

.empty {
  text-align: center;
  margin-bottom: 3rem;
  margin-top: 6.25rem
    /* 100/16 */
  ;
}

.empty-img {
  width: 4.375rem
    /* 70/16 */
  ;
  display: inline-block;
}

.city-box-1 {
  height: 1.125rem
    /* 18/16 */
  ;
}

.van-pull-refresh {
  overflow: inherit;
}

.mask {
  background: rgba(0, 0, 0, 0);
  top: 5rem
    /* 80/16 */
  ;
}

.loading-box {
  background: rgba(0, 0, 0, 0.6);
  width: 3.125rem
    /* 50/16 */
  ;
  height: 3.125rem
    /* 50/16 */
  ;
  border-radius: 0.375rem
    /* 6/16 */
  ;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -5rem
    /* 80/16 */
  ;
}
</style>
<style scoped>
.all-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.flex,
.flex-between {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.flex-between {
  justify-content: space-between;
}

.flex-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.banner {
  /*background: #333;*/
  display: block;
  width: 100%;
  /*height: 10rem;*/
}

.date-cont {
  margin: .625rem auto 0;
  overflow-x: scroll;
  width: 23.44rem;
}

.date-cont .date-day {
  width: 2rem;
  height: 2rem;
  margin-top: 0.25rem;
  font-size: 1.25rem;
  font-family: BebasNeue-Bold, BebasNeue;
  font-weight: bold;
  color: #3C454E;
  line-height: 2.2rem;
}

.date-cont .date-icon {
  width: 1.375rem;
  margin: 0 0 .3125rem;
}

.date-cont .date-weak {
  text-align: center;
  width: 2rem;
  height: 1.13rem;
  font-size: 0.69rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #3C454E;
  line-height: 1.13rem;
}

.date-cont .active .date-day {
  background: #3C454E;
  text-align: center;
  border-radius: 0.25rem;
  font-size: 1.25rem;
  font-family: BebasNeue-Bold, BebasNeue;
  font-weight: bold;
  color: #fff;
}

.date-cont .active .date-weak {
  font-weight: bold;
}

.date-cont .grey .date-day {
  color: #BBBBBB;
}

.date-cont .grey .date-weak {
  color: #BBBBBB;
}

.my-flex-start {
  justify-content: flex-start;
}

.mask {
  background: rgba(0, 0, 0, 0);
  top: 5rem
    /* 80/16 */
  ;
}

.loading-box {
  background: rgba(0, 0, 0, 0.6);
  width: 3.125rem
    /* 50/16 */
  ;
  height: 3.125rem
    /* 50/16 */
  ;
  border-radius: 0.375rem
    /* 6/16 */
  ;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -5rem
    /* 80/16 */
  ;
}
</style>
