const routes = [
  { path: '/superdeer-activity/teen-camp-202201/index', name: 'teen-camp-202201', meta: { title: '寒假练体育 考试有底气', headerType: 5 }, component: () => import('../views/superdeer-activity/teen-camp-202201/index') },
  // 虎年flag-202201
  { path: '/superdeer-activity/flag-202201/page-start', name: 'flag-page-start', meta: { title: '虎鹿瘦喜，新年FLAG', headerType: 5, keepAlive: true }, component: () => import('../views/superdeer-activity/flag-202201/page-start') },
  { path: '/superdeer-activity/flag-202201/page-1', name: 'flag-202201', meta: { title: '虎鹿瘦喜，新年FLAG', headerType: 5, keepAlive: true }, component: () => import('../views/superdeer-activity/flag-202201/page-1') },
  { path: '/superdeer-activity/flag-202201/page-2', name: 'flag-202201', meta: { title: '虎鹿瘦喜，新年FLAG', headerType: 5, keepAlive: true }, component: () => import('../views/superdeer-activity/flag-202201/page-2') },
  { path: '/superdeer-activity/flag-202201/page-3', name: 'flag-202201', meta: { title: '虎鹿瘦喜，新年FLAG', headerType: 5, keepAlive: true }, component: () => import('../views/superdeer-activity/flag-202201/page-3') },
  { path: '/superdeer-activity/flag-202201/page-4', name: 'flag-202201', meta: { title: '虎鹿瘦喜，新年FLAG', headerType: 5 }, component: () => import('../views/superdeer-activity/flag-202201/page-4') },
  { path: '/superdeer-activity/flag-202201/page-5', name: 'flag-202201', meta: { title: '虎鹿瘦喜，新年FLAG', headerType: 5, keepAlive: true }, component: () => import('../views/superdeer-activity/flag-202201/page-5') },
  { path: '/superdeer-activity/flag-202201/page-6', name: 'flag-202201', meta: { title: '虎鹿瘦喜，新年FLAG', headerType: 5, keepAlive: true }, component: () => import('../views/superdeer-activity/flag-202201/page-6') },
  { path: '/superdeer-activity/flag-202201/page-end', name: 'flag-202201', meta: { title: '虎鹿瘦喜，新年FLAG', headerType: 5, keepAlive: true }, component: () => import('../views/superdeer-activity/flag-202201/page-end') },
  { path: '/superdeer-activity/flag-202201/page-success', name: 'flag-202201', meta: { title: '虎鹿瘦喜，新年FLAG', headerType: 5, keepAlive: true }, component: () => import('../views/superdeer-activity/flag-202201/page-success') },
  { path: '/superdeer-activity/flag-202201/page-data', name: 'flag-202201', meta: { title: '虎鹿瘦喜，新年FLAG', headerType: 4, keepAlive: true }, component: () => import('../views/superdeer-activity/flag-202201/page-data') },
  { path: '/superdeer-activity/flag-202201/page-share', name: 'flag-202201', meta: { title: '虎鹿瘦喜，新年FLAG', headerType: 5, keepAlive: true }, component: () => import('../views/superdeer-activity/flag-202201/page-share') },
  { path: '/swimming/gate-machine', name: 'swimming', meta: { title: '固定费用闸机', headerType: 2 }, component: () => import(/* webpackChunkName: "swimming" */ '../views/swimming/gate-machine') },
  { path: '/swimming/payee', name: 'swimming-payee', meta: { title: '门票收款码', headerType: 2 }, component: () => import(/* webpackChunkName: "swimming" */ '../views/swimming/payee') },
  { path: '/swimming/ticket', name: 'swimming-ticket', meta: { title: '游泳门票', headerType: 4 }, component: () => import(/* webpackChunkName: "swimming" */ '../views/swimming/ticket') },
  { path: '/gift-receive/voucher', name: 'gift-receive', meta: { title: '转赠详情', headerType: 4 }, component: () => import(/* webpackChunkName: "gift-receive" */ '../views/gift-receive/voucher') },

  { path: '/medal/medal-wall', name: 'medal', meta: { title: '', headerType: 4 }, component: () => import(/* webpackChunkName: "medal" */ '../views/medal/medal-wall') },
  { path: '/medal/new-medal', name: 'new-medal', meta: { title: '', headerType: 4 }, component: () => import(/* webpackChunkName: "medal" */ '../views/medal/new-medal') },
  { path: '/medal/rank', name: 'medal-rank', meta: { title: '运动达人排行榜' }, component: () => import(/* webpackChunkName: "medal" */ '../views/medal/rank') },
  { path: '/medal/group-course', name: 'group-course', meta: { title: '约课拿勋章', headerType: '2' }, component: () => import(/* webpackChunkName: "medal" */ '../views/medal/group-course') },
  { path: '/medal/pri-course', name: 'medal-pri-course', meta: { title: '约课拿勋章', headerType: '2' }, component: () => import(/* webpackChunkName: "medal" */ '../views/medal/pri-course') },
  { path: '/account-cancellation/agreement', name: 'accountCancellation-agreement', meta: { title: '账号注销', headerType: '2' }, component: () => import(/* webpackChunkName: "accountCancellation" */ '../views/account-cancellation/agreement') },
  { path: '/account-cancellation/confirm', name: 'accountCancellation-confirm', meta: { title: '身份验证', headerType: '2' }, component: () => import(/* webpackChunkName: "accountCancellation" */ '../views/account-cancellation/confirm') },
  { path: '/account-cancellation/fail', name: 'accountCancellation-fail', meta: { title: '账号注销', headerType: '2' }, component: () => import(/* webpackChunkName: "accountCancellation" */ '../views/account-cancellation/fail') },
  { path: '/account-cancellation/warning', name: 'accountCancellation-warning', meta: { title: '账号注销', headerType: '2' }, component: () => import(/* webpackChunkName: "accountCancellation" */ '../views/account-cancellation/warning') },

  { path: '/user-evaluate', name: 'evaluate', meta: { title: '服务评价', headerType: 2 }, component: () => import(/* webpackChunkName: "swimming" */ '../views/user-evaluate') },
  { path: '/employee-benefit', name: 'employee-benefit', meta: { title: '企业员工福利选超鹿', headerType: 4 }, component: () => import(/* webpackChunkName: "employee" */ '../views/employee-benefit') },

  { path: '/recommended-coach', name: 'recommended-coach', meta: { title: '榨脂教练推荐榜', headerType: 4 }, component: () => import(/* webpackChunkName: "report" */ '../views/recommended-coach') },
  { path: '/get-mini-voucher', name: 'get-mini-voucher', meta: { title: '领取小班课新人券', headerType: 2 }, component: () => import(/* webpackChunkName: "report" */ '../views/get-mini-voucher') },
  { path: '/get-swimming-voucher', name: 'get-swimming-voucher', meta: { title: '领取小班课新人券', headerType: 2 }, component: () => import(/* webpackChunkName: "report" */ '../views/get-swimming-voucher') },
  { path: '/mini-apply-reward', name: 'mini-apply-reward', meta: { title: '整期报名加赠奖励', headerType: 2 }, component: () => import(/* webpackChunkName: "pri-punching" */ '../views/mini-apply-reward') },
  { path: '/mini-apply-protocol', name: 'mini-apply-protocol', meta: { title: '超鹿运动小班课整期报名协议', headerType: 2 }, component: () => import(/* webpackChunkName: "pri-punching" */ '../views/mini-apply-protocol') },
  { path: '/coach-feeling-detail', name: 'coach-feeling-detail', meta: { title: '动态详情', headerType: 2 }, component: () => import(/* webpackChunkName: "coach-feeling-detail" */ '../views/coach-feeling-detail') },
  { "path": "/temp-activity/15th-mall", "name": "anniversary", "meta": { "title": "月中健身 福利大放送", "headerType": 4 }, component: () => import(/* webpackChunkName: "anniversary" */'../views/temp-activity/15th-mall') },
  { "path": "/temp-activity/15th-fission", "name": "15th-fission", "meta": { "title": "分享好友领神券", "headerType": 4 }, component: () => import(/* webpackChunkName: "anniversary" */'../views/temp-activity/15th-fission') },
  { "path": "/per-nice/index", "name": "per-nice", "meta": { "title": "店长帮你选", "headerType": 2 }, component: () => import(/* webpackChunkName: "per-nice" */'../views/per-nice/index') },
  { "path": "/per-nice/edit", "name": "per-nice-edit", "meta": { "title": "编辑意向", "headerType": 2 }, component: () => import(/* webpackChunkName: "per-nice" */'../views/per-nice/edit') },
  { "path": "/per-nice/history", "name": "per-nice-history", "meta": { "title": "历史推荐记录", "headerType": 2 }, component: () => import(/* webpackChunkName: "per-nice" */'../views/per-nice/history') },
  { "path": "/per-nice/detail", "name": "per-nice-detail", "meta": { "title": "私教体验课推荐教练", "headerType": 2 }, component: () => import(/* webpackChunkName: "per-nice" */'../views/per-nice/detail') },
  { path: '/superdeer-activity/mid-autumn-2024/intro', name: 'zq1', meta: { title: '中秋博饼来超鹿', headerType: 4 }, component: () => import(/* webpackChunkName: "sell" */ '../views/superdeer-activity/mid-autumn-2024/intro') },
  { path: '/superdeer-activity/mid-autumn-2024/index', name: 'zq2', meta: { title: '中秋博饼来超鹿', headerType: 4 }, component: () => import(/* webpackChunkName: "sell" */ '../views/superdeer-activity/mid-autumn-2024/index') },
  { path: '/superdeer-activity/double11-2024/exchange', name: 'double11', meta: { title: '运动币商城', headerType: 5 }, component: () => import(/* webpackChunkName: "sell" */ '../views/superdeer-activity/double11-2024/exchange') },
  { path: '/superdeer-activity/double11-2024/index', name: 'double11-2', meta: { title: '超鹿双11 运动大富翁', headerType: 4 }, component: () => import(/* webpackChunkName: "sell" */ '../views/superdeer-activity/double11-2024/index') },
  { path: '/superdeer-activity/double11-2024/deposit', name: 'double11-3', meta: { title: '超鹿双11定金场', headerType: 4 }, component: () => import(/* webpackChunkName: "sell" */ '../views/superdeer-activity/double11-2024/main/index') },
  { "path": "/temp-activity/mystery-customer", "name": "mystery-customer", "meta": { "title": "神秘顾客征集中", "headerType": 4 }, component: () => import(/* webpackChunkName: "anniversary" */'../views/temp-activity/mystery-customer') },

  { path: '/superdeer-activity/spring-awakening-2024/index', name: 'cx1', meta: { title: '春生万物 燃动超鹿', headerType: 4 }, component: () => import(/* webpackChunkName: "sell" */ '../views/superdeer-activity/spring-awakening-2024/index') },
  { path: '/superdeer-activity/spring-awakening-2024/last-pay', name: 'cx2', meta: { title: '春生万物 燃动超鹿', headerType: 4 }, component: () => import(/* webpackChunkName: "sell" */ '../views/superdeer-activity/spring-awakening-2024/last-pay') },
  { path: '/superdeer-activity/spring-awakening-2024/final-payment', name: 'cx3', meta: { title: '春生万物 燃动超鹿', headerType: 4 }, component: () => import(/* webpackChunkName: "sell" */ '../views/superdeer-activity/spring-awakening-2024/final-payment') },
  { path: '/superdeer-activity/spring-awakening-2024/interest-free', name: 'cx4', meta: { title: '春生万物 燃动超鹿', headerType: 4 }, component: () => import(/* webpackChunkName: "sell" */ '../views/superdeer-activity/spring-awakening-2024/interest-free') },
  { path: '/superdeer-activity/spring-awakening-2024/lottery', name: 'cx5', meta: { title: '春生万物 燃动超鹿', headerType: 4 }, component: () => import(/* webpackChunkName: "sell" */ '../views/superdeer-activity/spring-awakening-2024/lottery') },
  { path: '/superdeer-activity/report-2023/index', name: 'report', meta: { title: '超鹿年度报告', headerType: 4 }, component: () => import(/* webpackChunkName: "report" */ '../views/superdeer-activity/report-2023/index') },
  { path: '/superdeer-activity/coupon-exchange/index', name: 'coupon-exchange', meta: { title: '我的卡券', headerType: 5 }, component: () => import(/* webpackChunkName: "report" */ '../views/superdeer-activity/coupon-exchange/index') },
  { "path": "/group-photo/list", "name": "photo-list", "meta": { "title": "团课合照", "headerType": 2 }, component: () => import(/* webpackChunkName: "group-photo" */'../views/group-photo/list') },
  { "path": "/group-photo/photo", "name": "photo-detail", "meta": { "title": "团课合照详情", "headerType": 2 }, component: () => import(/* webpackChunkName: "group-photo" */'../views/group-photo/photo') },
  {
    "path": "/superdeer-activity/store-opening/index",
    "name": "superdeer-activity/store-opening/index",
    "meta": { "title": "","headerType": 4},
    component: () => import('../views/superdeer-activity/store-opening/index.vue')
  },
  {
    "path": "/refund/progress",
    "name": "refund",
    "meta": { "title": "退款进度", "headerType": 2},
    component: () => import('../views/refund/progress.vue')
  },
  { path: '/superdeer-activity/group-coach-vote/index', name: 'group-vote', meta: { title: '上团课 为心爱的教练打call', headerType: 4 }, component: () => import(/* webpackChunkName: "group-vote" */ '../views/superdeer-activity/group-coach-vote/index') },
]
module.exports = routes
