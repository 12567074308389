export const status = {
    'ACTIVE': {
        color: '#FF6E00',
        html: '<span style="color: #fff">生效中</span>',
        htmlSellRecord: '<span style="color: #FF6E00">生效中</span>',
    },
    'WAIT_ACTIVE': {
        color: '#267DFF',
        html: '<span style="color: #fff">待生效</span>',
        htmlSellRecord: '<span style="color: #267DFF">待生效</span>',
    },
    'SUSPEND': {
        color: '#242831',
        html: '<span style="color: #bbb">冻结中</span>',
        htmlSellRecord: '<span style="color:#242831">冻结中</span>',
    },
    'EXPIRED': {
        color: '#9AA1A9',
        html: '<span style="color: #9AA1A9">已过期</span>',
        htmlSellRecord: '<span style="color: #9AA1A9">已用完</span>',
    } ,
    'TRANSFERRED': {
        color: '#FFF040',
        html: '<span style="color: #fff">已转增</span>',
        htmlSellRecord: '<span style="color: #FFF040">已转增</span>',
    },
    'CANCEL': {
        color: '#9AA1A9',
        html: '<span style="color: #9AA1A9">已退款</span>',
        htmlSellRecord: '<span style="color: #9AA1A9">已退款</span>',
    },
    'AUTO_REFUND': {
        color: '#9AA1A9',
        html: '<span style="color: #9AA1A9">已退款</span>',
        htmlSellRecord: '<span style="color: #9AA1A9">已退款</span>',
    },
    'WAIT_OPEN': {
        color: '#267DFF',
        html: '<span style="color: #fff">进馆后生效</span>',
        htmlSellRecord: '<span style="color: #267DFF">进馆后生效</span>',
    },
    'WAIT_RECEIVE': {
        color: '#9AA1A9',
        html: '<span style="color: #9AA1A9">赠送中</span>',
        htmlSellRecord: '<span style="color: #9AA1A9">赠送中</span>',
    },
    'RECEIVED': {
        color: '#9AA1A9',
        html: '<span style="color: #9AA1A9">已过期</span>',
        htmlSellRecord: '<span style="color: #9AA1A9">已过期</span>',
    }
}
// 权益说明， 商城购买页和卡详情页
export const activityRule = `
开通自助健身卡，可享受以下权益：
(1) 非高峰时段自助免费
除高峰时段（19:00-21:00）外的其他时段，可享受自助免费。
(2) 高峰时段自助免费时长
赠送高峰时段 (19:00-21:00）的自助免费时长，超出部分以7折计费自助费用，具体使用规则可在我的-卡包查看。
(3) 团课抵扣券
赠送团课抵扣券，具体优惠券使用规则可在我的-优惠券查看。
(4) 根据购买的卡面，获得不同的私教抵扣券。
(5) 具体自助卡权益以卡面实际展示权益内容为准。
`
// 超鹿月卡
export const monthActivityRule = `超鹿会员购买并开通激活超鹿月卡，包括月卡、季卡、半年卡、年卡所有规格的权益卡
后（以下统称超鹿月卡），所享受权益和遵守规则如下：
（1）非高峰时段免费，即除高峰时段（19:00-21:00）外的其他时段，可享受免费自助锻炼；
（2）高峰时段免费自助时长，即在每日19:00-21:00的时段，可享受免费超鹿月卡赠送的自助锻炼时长（具体时长以账户显示为准），超出的时长则以7折计算自助费用；
（3）约课折扣优惠，卡生效期间，可享受全品类团课8.8折约课优惠，此折扣优惠不与余额、礼品卡、课包券、优惠券叠加使用，需按实付金额补齐差价。
（4）团课抵扣券：规格为半年卡、年卡的超鹿月卡，可获得团课抵扣券。
（5）私教抵扣券，根据不同的卡面，获得不同的私教抵扣券。

`
// vip卡
export const vipCardRule = `
1、激活时间：进入SVIP区将自动生效；
2、购买并激活SVIP自助卡后可享免费SVIP区进出权益。
`
export const monthCardRule = `在同意本协议前请务必审慎阅读、充分理解各条款内容，本协议对福州超体健康科技有限公司、福州超鹿运动健身服务有限公司、厦门超鹿健身服务有限公司、广州超鹿健身服务有限公司下各“超鹿运动”健身馆购买超鹿月卡的会员有效。
一、关于超鹿月卡
1、当用户成为“超鹿运动”的会员后，方可购买超鹿月卡并使用，超鹿月卡价格以购买时页面显示价格为准，购买成功后会员可在“我的-卡包”中查看超鹿月卡相关信息。超鹿月卡仅限会员本人使用。
2、超鹿月卡购买后将于会员自主激活或首次产生有效范围内门店的自助健身订单时生效，有效期限以购卡时体现的期限为准，即自生效之日起至最后一日的 23:59:59。
3、通过超鹿运动 app、微信小程序外的第三方平台购买后在超鹿运动 app 上兑换的超鹿月卡，自兑换时立即生效。
4、超鹿月卡区分城市使用，支持一个账户同时存在多张不同规格的超鹿月卡，即单次购买超鹿月卡不限制张数。
5、未激活的超鹿月卡可直接在 app 上操作转赠给他人一次，若受赠人 24 小时内未领取，超鹿月卡将原路返回赠送人账号，一旦受赠人领取超鹿月卡，该卡将无法再次转赠、无法退款。
6、在有效期内的超鹿月卡持有账号若切换另一台设备登录，该账号则从切换的次日起5天内无法在别的设备上登录。
二、停卡及退款规则
已激活或转赠的超鹿月卡原则上不可转赠，不可延期，不可退款，仅在下述情形下可申请办理停卡或退款：
1、停卡
会员出现不宜运动的伤病或怀孕等情形，并提供医院证明的；
2、退款
（1）即退款金额=超鹿月卡实付价格-购买时超鹿月卡【标准价】/超鹿月卡天数
*卡生效后已经过天数-已使用【团课券】面额价值*数量-已上免费团课【标准价】*数量-对应城市高峰期价格【福厦高峰期 25 元/小时、广州高峰期 32 元/小时】元*已使用自助时长（按分钟计算，不足一分钟按一分钟计算）
（2）如遇我方原因闭店，客户无法进店消费的情况，用户申请退款时，退款金额=实付金额 -（实付金额/超鹿月卡天数 * 卡生效后已经过天数）-已使用【团课券】面额价值。
（3）会员在购买超鹿月卡时获得的其他权益（包括但不限于赠送的课程抵用券、加赠天数卡等），如尚未使用的，将于退款时自动失效并被收回。
（4）通过自助健身卡升级的超鹿月卡在进行退款时，不计算该卡赠送的限定免费团课优惠券的价值。
三、超鹿月卡标准价、权益及自动续卡
1、超鹿月卡标准价
福州和厦门超鹿月卡【标准价】：周卡为 60 元、月卡为 168 元、季卡为 448 元、半年卡为 798 元、年卡为 1580 元。广州超鹿月卡【标准价】：周卡为88元、月卡为238元、季卡为638元、半年卡为1180元、年卡为1980元
2、超鹿月卡权益
用户需区分城市使用超鹿月卡，权益仅能在购买选定的城市使用，具体权益为：
（1）非高峰时段免费，即除高峰时段（19:00-21:00）外的其他时段，可享受免费自助锻炼；
（2）高峰时段免费自助时长，即在每日19:00-21:00的时段，可享受免费超鹿月卡赠送的自助锻炼时长（具体时长以账户显示为准），超出的时长则以7折计算自助费用；
（3）约课折扣优惠，卡生效期间，可享受全品类团课8.8折约课优惠，此折扣优惠不与余额、礼品卡、课包券、优惠券叠加使用，需按实付金额补齐差价。
（4）团课抵扣券：规格为半年卡、年卡的超鹿月卡，可获得团课抵扣券。
（5）私教抵扣券，根据不同的卡面，获得不同的私教抵扣券。
3、超鹿月卡自动续卡
会员有效期期满后会员可以进行续约；会员可选择连续包月服务，即会员有效期到期前 72 小时内系统进行自动续费，该服务可随时取消。具体规则请您查阅《超鹿连续包月服务协议》
四、其他
1、使用超鹿月卡权益期间，如后台检测到某一账号异常，为了保证会员的账户安全，我们将立刻冻结该账号，后续将有客服联系该账号持有会员了解情况，如存在恶意使用账号的情形（包括但不限于将账号借给他人使用或利用账号进行其他谋利行为等），我们将对账号进行封号处理并追究持有人法律责任。针对首次违规操作账号行为，我们将作封号60 天处理，二次及多次以上违规行为，将进行永久封号处理；
2、购买的安心付若未按期履約，将无法完成退押。
3、亲子账号不可用超鹿卡。
4、关于会员服务及场馆使用规则，详见《用户协议》及《入场规则》。
5、“超鹿运动”对于本协议约定的内容具有最终解释权，同时根据国家法律法规变化及运营需要，“超鹿运动”有权对相关内容进行修改，会员在使用本协议所涉的服务时，可及时查阅了解。
6、本协议的效力、解释、变更、执行及争议的解决等均适用中华人民共和国法律。因本合同产生的任何争议，双方应协商解决，协商不成的，应提交福州市鼓楼区人民法院管辖。`
export const scheduleActivityRule = `团课月卡权益使用规则
开通团课月卡可享受以下权益：
1. 团课券：福州、厦门团课月卡内包含：3张28元、3张38元、2张48元0门槛团课券；广州团课月卡内包含：2张28元、3张38元、2张48元、1张58元0门槛团课券；团课月卡内的团课券不支持转赠，支持高价券约低价课，低价券约高价课需现金补差价，高价券约低价课的差值不进行退还；支持团课约课选座时选择两个座位。
2. 折扣约课优惠：权益生效期内享受8折团课约课优惠，此优惠不与余额、礼品卡、课包券、优惠券叠加使用，需按实付金额补齐差价；延时宝不享受优惠折扣。
3. 赠送私教券&小班课券：开卡后即赠送1张50元私教券和1张39元小班课券，具体优惠券使用规则可在我的-优惠券查看。
4. 自助免费时长：购卡后即可享受全时段6小时的免费自助时长，有效期天数同团课月卡。

团课月卡购买及生效规则
1. 购卡时仅支持现金支付，不支持使用余额、礼品卡进行购买。
2. 需区分城市使用团课月卡，权益仅能在购买选定的城市使用。
3. 用户购卡后自动存入卡包，可自主选择激活生效时间，开卡当日即生效，为权益享受第一天；若从团课约课订单页购买则直接生效使用。
4. 团课月卡仅限本人使用，不可转赠，不可用于商业用途。
5. 用户不得以不正当、不诚信的方式获取/使用团课月卡权益，否则超鹿运动有权撤销用户付费团课月卡资格并有权回收用户已经获得的权益（包括追偿已使用部分）。
6. 如有其他疑问，可咨询在线客服或致电客服，客服电话：400-700-5678

`
export const coachActivityRule = `
超鹿会员购买并开通激活超鹿包月私教卡、包季度、包半年、包年等同类私教卡后（以下统称包月及同类私教卡），享受权益和须遵守规则如下：

1. 包月及同类私教卡约课权益：有效期内，会员每日可免费预约一节对应价格档位的私教课程。（部分教练或部分课程仅支持按次付费，不同价格档位课程可能需要补足差价，预约时请注意查看）
2. 会员使用包月私教卡，每次可免费预约一节私教课，该课程上完后，方可预约下一节；会员使用季卡、半年、年卡等，每次最多可同时免费预约三节课（每天限1节）。如果已经同时预约3节免费课程（含补差价后预约的课程），在上完就近的一节课程后，即可重新继续预约后面1节课，以此类推。也就是说，同时最多能看到的“未上免费课程”为3节。
3. 使用包月及同类私教卡约私教课，课前人脸识别签到规则：超鹿包月及同类私教卡仅限购买会员本人使用，上课需本人人脸识别签到，签到时间为课前30分钟至课后60分钟，未签到将视为旷课，影响后续约课权益。
4. 改签与退课权益与规则：会员约课后，可依据《私教退改签规则》进行退改签操作，未进行操作并缺席，属于无故旷课。《退改签规则》请见后文。
5. 会员使用约课权益约课后无故旷课，第一次给予短信提醒，第二次开始，将暂停当天私教免费预约权益，至次日0点后恢复。
6. 包月及同类卡其他重要权益：
    用户购卡激活后，权益有效期内，每30天提供以下三项权益
A、当日第二节课优惠：除每日可免费预约1节私教课外，当日第二节及更多私教课，可按次预约购买，并享受8.5折优惠。此优惠不与余额、礼品卡、课包券等其他优惠叠加使用。
B、赠送团课券：赠送4张28元团课券。具体优惠券使用规则可在我的-优惠券查看。
C、自助免费时长：可享15小时的全时段免费自助时长，有效期为30天。


《私教退改签规则》
改签规则
1.单笔订单仅限改签1次;
2.课前4小时可免费改签
3.课前4小时内至开课前:
    1)若用户无课程免费时长内的进馆记录，支持改签
    2)改签至当日其他空闲时段免费。
    3)改签至第二天及以后，若拥有免费退改签权益次数，则可免费改签(每周1次免费的改签、退课机会，改签退课合并计算，每周一0点重置);若超出免费退改签次数，收取10%的手续费;
4.开课后至当天24点前: 若用户无课程免费时长内的进馆记录支持改签。改签至当日其他空闲时段免费。改签至第二天及以后，收取20%的手续费。

退课退款规则
1.开课前4小时退课，可免费退款;
2.开课前4小时内至开课前退课，若拥有免费退改签权益次数，则可免费退课(每周1次免费的改签、退课机会，改签退课合并计算，每周一0点重置);若超出免费退改签次数，收取10%的手续费
3.开课以后，不支持退款;
4.开课以后，改签至其他时间。该笔订单不支持再退款;
5改签后再退课，订单所消耗的免费次数或支付的手续费不予退还。

以上规则有疑问，请致电400-700-5678

`
// 权益卡常见问题，卡详情页面  0 自助卡  1 团课卡  2 私教卡   3 超鹿月卡
export const FAQ = [
    `1、自助健身卡是否可以退款？
    自助健身卡目前不可转赠，不可退款，不可延期。

    2、每次仅限购买一张？
    同类型自助健身卡每个城市一次限购一张，到期不自动续费。

    3、自助健身卡生效前生成的订单计费标准？
    自助健身卡生效前生成的未支付的订单，按正常自助费用计费。只有在自助健身卡生效期间生成的订单才可享受相应的月卡权益。
    `,


    `1、团课月卡是否可以退款？
    团课月卡目前不可转赠，不可退款，不可延期。

    2、每次仅限购买一张？
    团课月卡可叠加购买，同一时间段可支持生效多张团课月卡。

    3、约课折扣优惠是否可叠加？
    团课月卡有效期内享受全品类团课8折约课优惠，此优惠不支持余额及礼品卡支付，不可与课包券及优惠券叠加使用，需按实际价格补齐差价。
    `,


    `1、超鹿包月私教，可约私教节数？
    月卡允许有效期内存在一节已约未上课程，季卡及以上允许有效期内同时存在三节已约未上课程。

    2、超鹿包月私教是否可补差价？
    可以通过补差价约更高级教练的课，同时可向下兼容，例如：299档位，可约240私教课。

    3、购买超鹿包月私教激活方式？
    超鹿包月私教，可自主激活。

    4、超鹿包月私教旷课受限？
    用户约课后如无故旷课，第一次短信提醒。第二次开始将取消当天约课的权益-用户权益，24:00后恢复约课权益。

    5、超鹿包月私教签到方式？
    超鹿包月私教的，预约每一节课程都需进行人脸签到，未签到将视为旷课。
    `,


    `1、超鹿月卡可以同时生效几张？
    超鹿月卡区分城市使用，支持一个账户同时生效多张超鹿月卡，由会员自主选择激活，到期后不自动续费。

    2、超鹿月卡是否可以退款？
    如会员无特殊原因，已激活或转赠的超鹿月卡不可转赠，不可延期，不可退款。

    3、购卡后账户上存在未支付订单的计费标准？
    购卡后，账户上只有存在当天未支付自助订单，可用该卡抵扣此部分订单，且抵扣时该卡开始生效；如果存在当天以前未支付的自助订单，则不可使用该卡进行抵扣。
    `,
    `1、SVIP自助卡是否可退款？
    SVIP自助卡目前不可退款，不可延期。

    2、SVIP自助卡能否抵扣普通自助区域费用？
    SVIP自助卡仅可抵扣SVIP区费用，普通自助区正常收费。

    3、SVIP自助卡生效前的计费标准？
    SVIP自助卡生效前进出SVIP区费用，按正常SVIP区费用计算。只有在SVIP自助卡生效期间的进出SVIP区费用才可享受相应的权益。
    `
]
export const protocol = `在同意本协议前请务必审慎阅读、充分理解各条款内容，本协议对福州超体健康科技有限公司、福州超鹿运动健身服务有限公司、厦门超鹿健身服务有限公司、广州超鹿健身服务有限公司下各“超鹿运动”健身馆购买自助健身卡的会员有效。
一、关于自助健身卡
1、当用户成为“超鹿运动”的会员后，方可购买团课月卡并使用，团课月卡价格以购买时页面显示价格为准，购买成功后会员可在“我的-卡包”中查看自助健身卡相关信息。自助健身卡仅限会员本人使用。
2、自助健身卡购买后将于会员自主激活或首次产生有效范围内门店的自助健身订单时生效，有效期限以购卡时体现的期限为准，即自生效之日起至最后一日的23:59:59。
  3、通过超鹿运动app、微信小程序外的第三方平台购买后在超鹿运动app上兑换的自助健身卡，自兑换时立即生效。
4、自助健身卡区分城市使用，一个账户内最多可同时存在月卡权益90天、季卡权益270天、半年卡权益183天及年卡权益365天，即单次可购买每个城市的自助健身月卡和季卡各三次、半年卡两次、年卡一次，此后需月卡剩余时间少于60天、季卡剩余时间少于180天、半年卡剩余时间少于366天、年卡全部使用完毕后，方可再次购买。
5、未激活的自助健身卡可直接在app上操作转赠给他人一次，若受赠人24小时内未领取，自助健身卡将原路返回赠送人账号，一旦受赠人领取自助健身卡，该卡将无法再次转赠、无法退款。
6、在有效期内的自助健身卡的账号在一台设备上登录后，该账号在5天内无法在别的设备上登录。
7、已激活或转赠的自助健身卡原则上不可转赠，不可延期，不可退款，仅在下述情形下可申请办理退款或停卡：
（1）会员出现不宜运动的伤病或怀孕等情形，并提供医院证明的；
退款及停卡规则如下：
（1）退款规则：退款金额=健身卡实付价格-购买时健身卡【标准价】/自助健身卡天数*健身卡生效后已经过天数。会员在购买自助健身卡时获得的其他权益（包括但不限于赠送的课程抵用券等），如尚未使用的，将于退款时自动失效并被收回。
2023年10月7日前购买自助健身卡标准价为：
福州和厦门自助健身卡【标准价】：月卡为198元、季卡为468元、半年卡为900元、年卡为1580元。
广州自助健身卡【标准价】：周卡为98元、月卡为238元、季卡为598元、半年卡为1060元、年卡为1780元。
2023年10月7日至2023年10月17日期间购买自助健身卡标准价为：
福州和厦门自助健身卡【标准价】：月卡为168元、季卡为468元、半年卡为850元、年卡为1580元。
广州自助健身卡【标准价】：周卡为68元、月卡为188元、季卡为538元、半年卡为990元、年卡为1780元。
自2023年10月18日起，自助健身卡标准价为：
福州和厦门自助健身卡【标准价】：周卡为60元、月卡为168元、季卡为448元、半年卡为798元、年卡为1580元。
广州自助健身卡【标准价】：周卡为68元、月卡为188元、季卡为498元、半年卡为928元、年卡为1680元。
（2）停卡规则：会员在满足停卡情形后，如停卡期限小于或等于6个月的，将为会员办理停卡，如停卡期限超过6个月，将按退款手续办理。一个健身卡权益期限内，用户仅可办理一次停卡。
8、如后台检测到某一账号异常，为了保证会员的账户安全，我们将立刻冻结该账号，后续将有客服联系该账号持有会员了解情况，如存在恶意使用账号的情形（包括但不限于将账号多次借给他人使用或利用账号进行其他谋利行为等），我们将对账号进行封号处理并追究持有人法律责任。针对首次违规操作账号行为，我们将作封号60天处理，二次及多次以上违规行为，将进行永久封号处理。
二、健身卡权益
1、健身卡生效后，会员可在所购健身卡城市所有超鹿运动场馆内自助健身区域享受非高峰时段免费、高峰时段免费自助锻炼时长，具体权益如下：
（1）非高峰时段，即除高峰时段（19:00-21:00）外的其他时段，可享受免费自助锻炼；
（2）高峰时段，即每日19:00-21:00，可享受免费自锻炼时长（具体时长以账户显示为准），超出的时长则以7折计算自助费用。
2、如购买时有赠送其他权益的，以相关权益使用规则为准。
三、关于会员服务及场馆使用规则，详见《用户协议》及《入场规则》。
四、本协议的效力、解释、变更、执行及争议的解决等均适用中华人民共和国法律。因本合同产生的任何争议，双方应协商解决，协商不成的，应提交福州市鼓楼区人民法院管辖。
`
export const scheduleProtocol = `在同意本协议前请务必审慎阅读、充分理解各条款内容，本协议对福州超体健康科技有限公司、福州超鹿运动健身服务有限公司、厦门超鹿健身服务有限公司、广州超鹿健身服务有限公司下各“超鹿运动”健身馆购买团课月卡的会员有效。
一、关于团课月卡
1、当用户成为“超鹿运动”的会员后，方可购买团课月卡并使用，团课月卡价格以购买时页面显示价格为准，购买成功后会员可在“我的-卡包”中查看团课月卡相关信息。团课月卡仅限会员本人使用。
2、团课月卡购买后将于会员自主激活或首次产生有效范围内门店的团课订单时生效，有效期限以购卡时体现的期限为准，即自生效之日起至最后一日的23:59:59。
3、通过超鹿运动app、微信小程序外的第三方平台购买后在超鹿运动app上兑换的团课月卡，自兑换时立即生效。
4、团课月卡区分城市使用，不支持跨城使用，且团课月卡所属全部权益仅能在购买时选定的城市使用；购买团课月卡赠送的团课券与私教券、小班课券及8折折扣卡不支持转赠，其中，团课券可支持双人团课约课使用。
5、团课月卡可叠加购买，同一时间段可支持生效多张团课月卡；
6、有效期内的团课月卡账号在一台设备上登录后，该账号在5天内无法在别的设备上登录。
7、团课月卡仅支持现金支付，不参与优惠及促销活动，不支持优惠券抵用，不支持余额、礼品卡等支付。
8、已激活的团课月卡原则上不可延期、不可退款，且不支持停卡、转卡，仅在下述情形下可申请办理退款：
（1）会员出现不宜运动的伤病或怀孕等情形，并提供医院证明的。
退款规则如下：
① 退款规则：退款金额=【团课月卡】实付价格-已使用的【团课券】面额价值-已使用的【私教/小班课券】面额价值-已使用的【自助健身时长】（通过购买团课月卡赠送8折折扣卡购买的团课不计入团课月卡退款）。
如：持有福州团课月卡用户，已使用【团课券】28元一张，已使用【满0-39元小班课优惠券】1张，已使用【3小时自助健身时长】，则退款金额：168-28-39-3/6*150=26元
② 在退款时尚未使用的【团课券】、【私教券】、【小班课券】、【自助健身时长】和【8折折扣卡】权益在退款时将自动失效并被收回。
③ 若可退金额等于或小于0时，则无法进行退款。
（2） 退卡特殊情形：如因错买其他城市所属团课月卡，且团课月卡所属权益均未使用的，则可向客服申请特殊退款，申请通过后将全额退款。
9、如后台检测到某一账号异常，为了保证会员的账户安全，我们将立刻冻结该账号，后续将有客服联系该账号持有会员了解情况，如存在恶意使用账号的情形（包括但不限于将账号多次借给他人使用或利用账号进行其他谋利行为等），我们将对该账号进行封号处理并追究持有人法律责任。针对首次违规操作账号行为，我们将作60天封号处理，二次及多次以上违规行为，将进行永久封号处理。
二、团课月卡权益
1、团课月卡生效后，会员可在所购团课月卡城市所有超鹿运动场馆内使用团课月卡，具体权益如下：
福州&厦门城市团课月卡权益
（1）团课券：购卡赠送3张28元团课券，3张38元团课券，2张48元团课券；
（2）约课折扣优惠：团课月卡有效期内享受全品类团课8折约课优惠，此优惠不支持余额及礼品卡支付，不可与课包券及优惠券叠加使用，需按实际价格补齐差价；
（3）自助健身时长：购卡赠送6小时全时段免费自助健身时长。
（4）课程抵扣券：团课月卡开卡即赠送1张50元私教券和1张39元小班课券，赠送的优惠券不支持转赠。
广州城市团课月卡权益
（1）团课券：购卡赠送3张38元团课券，5张48元团课券；
（2）约课折扣优惠：团课月卡有效期内享受全品类团课8折约课优惠，此优惠不支持余额及礼品卡支付，不可与课包券及优惠券叠加使用，需按实际价格补齐差价；
（3）自助健身时长：购卡赠送6小时全时段免费自助健身时长。
（4）课程抵扣券：团课月卡开卡即赠送1张50元私教券和1张39元小班课券，赠送的优惠券不支持转赠。
2、如购买时有赠送其他权益的，以相关权益使用规则为准。
三、关于会员服务及场馆使用规则，详见《用户协议》及《入场规则》。
四、本协议的效力、解释、变更、执行及争议的解决等均适用中华人民共和国法律。因本合同产生的任何争议，双方应协商解决，协商不成的，应提交福州市鼓楼区人民法院管辖。
若会员对本协议有任何疑问，可以通过APP在线联系客服或致电客服，客服电话：400-700-5678。
`
// 福厦团课月卡开卡页底部规则
export const scheduleRule = `开通团课月卡可享受以下权益：
1.团课券：3张28元、3张38元、2张48元0门槛团课券；团课月卡内的团课券不支持转赠，支持高价券约低价课，低价券约高价课需现金补差价，高价券约低价课的差值不进行退还。支持团课约课选座时选择两个座位。
2.折扣约课优惠：权益生效期内享受8折团课约课优惠，此优惠不与余额、礼品卡、课包券、优惠券叠加使用，需按实付金额补齐差价；延时宝不享受优惠折扣。
3.赠送私教券&小班课券：开卡后即赠送1张50元私教券和1张39元小班课券，具体优惠券使用规则可在我的-优惠券查看。
4.自助免费时长：购卡后即可享受全时段6小时的免费自助时长，有效期天数同团课月卡。

团课月卡购买及生效规则
1.购卡时仅支持现金支付，不支持使用余额、礼品卡进行购买。
2.需区分城市使用团课月卡，权益仅能在购买选定的城市使用。
3.用户购卡后自动存入卡包，可自主选择激活生效时间，开卡当日即生效，为权益享受第一天；若从团课约课订单页购买则直接生效使用。
4.团课月卡仅限本人使用，不可转赠，不可用于商业用途。
5.用户不得以不正当、不诚信的方式获取/使用团课月卡权益，否则超鹿运动有权撤销用户付费团课月卡资格并有权回收用户已经获得的权益（包括追偿已使用部分）。
6.如有其他疑问，可咨询在线客服或致电客服，客服电话：400-700-5678
`
// 广州团课月卡开卡页底部规则
export const scheduleRuleGz = `开通团课月卡可享受以下权益：
1.团课券：3张38元、5张48元0门槛团课券；团课月卡内的团课券不支持转赠，支持高价券约低价课，低价券约高价课需现金补差价，高价券约低价课的差值不进行退还；支持团课约课选座时选择2个座位。
2.折扣约课优惠：权益生效期内享受8折团课约课优惠，此优惠不与余额、礼品卡、课包券、优惠券叠加使用，需按实付金额补齐差价；延时宝不享受优惠折扣。
3.赠送私教券&小班课券：开卡后即赠送1张50元私教券和1张39元小班课券，具体优惠券使用规则可在我的-优惠券查看。
4.自助免费时长：购卡后即可享受全时段6小时的免费自助时长，有效期天数同团课月卡。

团课月卡购买及生效规则
1.购卡时仅支持现金支付，不支持使用余额、礼品卡进行购买。
2.需区分城市使用团课月卡，权益仅能在购买选定的城市使用。
3.用户购卡后自动存入卡包，可自主选择激活生效时间，开卡当日即生效，为权益享受第一天；若从团课约课订单页购买则直接生效使用。
4.团课月卡仅限本人使用，不可转赠，不可用于商业用途。
5.用户不得以不正当、不诚信的方式获取/使用团课月卡权益，否则超鹿运动有权撤销用户付费团课月卡资格并有权回收用户已经获得的权益（包括追偿已使用部分）。
6.如有其他疑问，可咨询在线客服或致电客服，客服电话：400-700-5678
`
export const autoRenewRule = `在同意本协议前请务必审慎阅读、充分理解各条款内容，本协议对福州超体健康科技有限公司、福州超鹿健身服务有限公司、厦门超鹿健身服务有限公司、广州超鹿健身服务有限公司下各“超鹿运动”健身馆购买超鹿月卡的会员有效。
一、服务说明
1.1 本服务是出于超鹿会员对于连续包月的需求，在超鹿会员已开通本服务的前提下，超鹿会员授权超鹿在会员权益有效期到期前 72小时内，自行或委托第三方支付渠道从超鹿会员账号绑定的支付宝或微信账户（以下统称 “账户 ”）中扣取下一个计费周期费用。具体扣划渠道由会员在开通本服务时选择。
1.2 连续包月具体指，基于(1.1)的前提，超鹿自行或委托第三方支付渠道通过上述账户收取超鹿会员下一计费周期费用，并将款项记入超鹿会员支付记录，同时相应延长超鹿会员对应权益有效期的扣费方式。超鹿会员需保证超鹿可以自行或委托第三方支付渠道从上述账户扣款成功，因上述账户中可扣款余额不足导致的续费失败，由超鹿会员自行承担责任（包括但不限于超鹿会员对应权益终止）。若您购买的连续包月产品为月卡，则下一计费周期费用指月卡费用，即计费周期内每次按月扣取；若您购买的连续包月产品为季卡，则下一计费周期费用指季卡费用，即计费周期内每次按季度扣取。以此类推。
1.3 超鹿会员选择第三方支付渠道享受本服务，将视为同意超鹿代理超鹿会员向第三方支付渠道发出扣款指令，并同意第三方支付渠道可以根据超鹿发出的扣款指令，在不验证会员账户密码、支付密码、短信校验码等信息的情况下从上述账户扣划下一个计费周期的费用。
1.4 超鹿会员在享受本服务时，应同时遵循《超鹿月卡服务协议》以及相关平台规则。超鹿会员选择使用本服务行为将视为同意接受本服务的服务条款以及超鹿针对本服务发出的各类公告、通知。
1.5 超鹿向超鹿会员提供的本服务仅限于延续超鹿会员相应权益使用，任何以恶意破解等非法手段将超鹿提供的本服务内容与超鹿会员相应权益分离的行为，均不属于本协议中约定的本服务。由此引起的一切法律后果由行为人负责，超鹿将依法追究行为人的法律责任。
1.6 超鹿会员在服务期内若有任何疑问可在APP内向客服咨询。
1.7 如因非可归因于超鹿的原因（如第三方系统故障、账户限额等）造成扣费失败，超鹿将按照“到期未续费”处理，停止向会员提供相应的服务，会员因自行产生可能承担的不利后果
二、有效期
2.1 除非超鹿另有通知、公告，本服务将长期有效。本协议自超鹿会员选择接受或使用本服务后生效，直至超鹿会员终止本服务或超鹿会员资格注销时终止。
三、 服务的退订
3.1 超鹿会员有权随时终止本服务。
3.2 因目前超鹿运动团操课程提前48小时开放预约，为保障会员能顺利进行课程预约，超鹿将在会员有效期到期前72小时内扣取下一个计费周期费用， 若超鹿会员不想参与下个计费周期的连续包月的，需在会员有效期到期的 72小时之前终止本服务。
3.3 服务退订路径
APP退订：
我的-自动续费管理-自动续费-卡详情右上角-关闭自动续费
微信退订：
①苹果手机：微信APP-我-钱包-支付设置-自动续费-超鹿运动自动续费-关闭服务
②安卓手机：微信APP-我-钱包-支付设置-自动续费-超鹿运动自动续费-关闭服务
支付宝退订：
①苹果手机：支付宝APP--我的--设置--支付设置--免密支付/自动扣款--超鹿运动--关闭服务
②安卓手机：支付宝APP--我的--设置--支付设置--免密支付/自动扣款--超鹿运动--关闭服务
3.4退订自动续费服务视为会员撤销给与超鹿的从第三方支付账户自动扣款的授权，但不影响超鹿在会员撤销成功前已完成的扣款。
四、阶梯价服务
4.1 目前超鹿连续包月采取阶梯价形式，服务在不退订的情况下，每月扣费额会根据扣费次数不同有一定价格调整（详细可见卡详情页面价格表）。
4.2 一旦退订再购买，扣费规则会重新按照第一次扣费开始计算。
4.3 连续包月阶梯价格有效期为一年，自开通之日起算，价格有效期内，如超鹿包月服务价格发生调整，如调整后的价格低于调整前的价格，则以调整后的价格为准；如调整后的价格高于调整前的价格，则仍以调整前的价格为准。价格有效期满后，连续包月价格以当时的价格政策为准，
五、退费的限制
5.1 超鹿基于超鹿会员开通本服务时选择的支付方式扣划费用，原则上不以任何方式对超鹿会员所支付的费用予以退还。如在服务期限内，会员依照《超鹿月卡服务协议》停卡或退卡的，依照超鹿服务协议相关约定处理。
5.2 如在扣费过程中出现差错，超鹿和超鹿会员应密切配合查明原因，各自承担己方过错造成的损失；若因双方各自存在不均等过错造成的损失，由双方按过错的程度承担相应责任；双方无法划分过错程度的，由双方均摊责任。
六、通知与查询
6.1 超鹿将在自动续费日期前五日，通过短信通知方式提请会员注意自动续费事项。本服务由超鹿会员自主选择是否取消，若超鹿会员未选择取消，则视为超鹿会员同意超鹿按照一定规则进行定期的扣款尝试，一旦扣款成功，超鹿将为超鹿会员开通下一个计费周期的包月服务。
6.2 超鹿可根据其业务开展或技术升级等情况变更或修改本协议的有关服务内容、规则及条款。如果超鹿会员不同意本协议的修改，可以取消已经获取的服务并停止使用；如果超鹿会员继续使用超鹿提供的服务，则视为超鹿会员已经接受本协议的全部修改。
七、协议终止
7.1出现下列情况之一的，超鹿有权立即终止本服务而无需承担任何责任：
（ 1 ）超鹿会员违反本协议的约定或会员违反超鹿服务协议而导致会员资格被取消的；
（ 2 ）超鹿会员违反超鹿关于超鹿会员服务的超鹿会员协议及各类公告、通知的；
（ 3 ）根据国家相关法律法规，超鹿认为向超鹿会员提供本服务存在重大风险的。
八、本协议的效力、解释、变更、执行及争议的解决等均适用中华人民共和国法律。因本合同产生的任何争议，双方应协商解决，协商不成的，应提交福州市鼓楼区人民法院管辖。`
export const fzZhimaRule = `1.超鹿会员安心付是超鹿&芝麻信用&花呗合作的会员卡，通过超鹿会员安心付连续开通12个月会员，前6个月低至138元/月，后6个月低至128元/月。（另支付宝官方优惠活动：12期再减50元 ，每期再减4.16元。）

2. 会员需履约完成6个月使用期后，可随时关闭安心付的自动续费服务；未满6个月使用期，则无法关闭安心付的自动续费服务，且无法退押；具体期限以各城市实际方案为准，您也可点击“立即购买”进入购买页面详细查看页面底部的《超鹿安心付规则》，如有疑问请联系客服。`
export const gzZhimaRule = `1.超鹿会员安心付是超鹿&芝麻信用&花呗合作的会品卡，通过超鹿会员安心付连续开通12个月会员，前3个月低至208元/月，(第4至5月)低至188元/月，(第6至9月)低至168元/月，(第10至12月)低至138元/月。（另支付宝官方优惠活动：12期再减50元 ，每期再减4.16元。）

2. 会员需履约完成6个月使用期后，可随时关闭安心付的自动续费服务；未满6个月使用期，则无法关闭安心付的自动续费服务，且无法退押；具体期限以各城市实际方案为准，您也可点击“立即购买”进入购买页面详细查看页面底部的《超鹿安心付规则》，如有疑问请联系客服。`
