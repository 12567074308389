<template>
  <div class="s-box">
    <div class="echart-box">
      <v-chart :options="radarOption" class="echart" />
      <div class="total">
        <p class="l1">{{ info.totalScore }}<span>分</span></p>
        <p class="l2">{{ info.totalScoreContent }}</p>
      </div>
    </div>
    <div v-if="info.percentage">
      <span class="line mt5">
        <img class="icon" src="https://img.chaolu.com.cn/ACT/teacher-detail/socre/i1.png" alt="">
        超越了{{ info.percentage }}%的教练
      </span>
      <br />
      <span class="line mt20" v-if="info.content">
        <img class="icon" src="https://img.chaolu.com.cn/ACT/teacher-detail/socre/i2.png" alt="">
        {{ info.content }}
      </span>
    </div>
  </div>
</template>

<script>
import VChart from 'vue-echarts';
import 'echarts/lib/chart/radar';
export default {
  name: 'radar',
  components: {
    'v-chart': VChart
  },
  props: {
    info: { type: Object }
  },
  data() {
    return {
      radarOption: {},
      sjson: {
        '专业证书': 'certificateScore',
        '用户评价': 'userEvaluationScore',
        '综合素质': 'comprehensiveScore',
        '会员服务': 'memberServiceScore',
        '授课经验': 'teachingExperienceScore'
      },
    }
  },
  watch: {
    info(n) {
      this.radarOption = this.getOption(n, this.sjson)
    }
  },
  methods: {
    getOption(data, json) {
      return {
        radar: {
          indicator: [
            { name: '专业证书', max: 5 },
            { name: '授课经验', max: 5 },
            { name: '会员服务', max: 5 },
            { name: '综合素质', max: 5 },
            { name: '用户评价', max: 5 },
          ],
          radius: '63%',
          center: ['50%', '55%'],
          nameGap: 4,
          name: {
            formatter: function (params) {
              return `{num|${data[json[params]]}}\n{font|${params}}`;
            },
            rich: {
              font: { fontSize: 11, color: '#000000', fontWeight: 400 },
              num: { fontSize: 15, color: '#FF6E00', align: 'center', lineHeight: 22, fontFamily: 'BebasNeueBold' }
            }
          },
          splitLine: {
            lineStyle:{
              color:'#b9c9ee',
               type: "dotted"
            }
          },
          splitArea: {
            show: false
          }
        },
        series: [{
          type: 'radar',
          data: [{ value: [data.certificateScore, data.teachingExperienceScore, data.memberServiceScore, data.comprehensiveScore, data.userEvaluationScore] }],
          lineStyle: { color: "transparent" },
          itemStyle: { borderColor: "#618AE9" },
          areaStyle: {
            opacity: 0.9,
            color: {
              type: 'linear', x: 0, y: 0, x2: 0, y2: 1,
              colorStops: [
                { offset: 0, color: '#00E7EF' },
                { offset: 1, color: '#1863D3' }],
              global: false
            }
          }
        }]
      }
    }
  }
}
</script>

<style lang="less" scoped>
.s-box {
  padding-bottom: 30px;

  .echart-box {
    width: 100%;
    position: relative;

    .echart {
      width: 100%;
      height: 420px;
    }

    .total {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 420px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding-top: 24px;
      color: #fff;

      .l1 {
        font-family: 'BebasNeueBold';
        font-size: 70px;

        span {
          font-size: 28px;
          margin-left: 6px;
        }
      }

      .l2 {
        font-size: 18px;
      }
    }
  }

  .line {
    font-size: 20px;
    color: #204474;
    padding: 12px 20px 12px 79px;
    border-radius: 8px;
    background-color: #ECF2FF;
    position: relative;
    display: inline-block;
    font-weight: 600;

    .icon {
      position: absolute;
      width: 66px;
      bottom: 0px;
      left: 6px;
    }
  }
}
</style>