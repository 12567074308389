<template>
  <div v-if="appTypeStr !== 'mini'" :class="['nav-bar', headerType === 4 ? 'fixed' : 'sticky']" :style="{ backgroundColor, backgroundImage: `url(${backgroundImage})` }">
    <div :style="{ height: barHeight ? barHeight + 'px' : '' }"></div>

    <div class="tool-bar row-between-center">
      <div class="left flex" @click="clickLeft">
        <div v-if="$slots.leftScroll && scrollStart" class="left-icon flex">
          <slot name="leftScroll" />
        </div>

        <div v-else-if="$slots.left" class="left-icon flex">
          <slot name="left" />
        </div>

        <van-icon v-else name="arrow-left" class="left-icon f44 flex" />
      </div>
      <div v-if="showRight" class="right flex" @click="clickRight">
        <slot v-if="$slots.rightScroll && scrollStart" name="rightScroll" />
        <slot v-else-if="$slots.right" name="right" />
        <img v-else class="right-icon flex" src="https://chaolu.oss-cn-hangzhou.aliyuncs.com/common/share-icon.png" />
      </div>
    </div>

    <header v-if="!['toutiao', 'mini'].includes(appTypeStr)" :class="['free-header row-center-center']" :style="{ opacity, backgroundColor }" ref="head" @touchmove.prevent>
      <p class="title f36 fw6" :style="{ color: fontColor }">{{ title }}</p>
    </header>
  </div>
</template>

<script>
import { getBarHeight, hideAppBackBtn, hideBar } from '@/lib/appMethod'

export default {
  components: {},
  data() {
    return {
      barHeight: getBarHeight(),
      headerType: 4,
      scrollStart: false,
      opacity: 0,
      backgroundColor: '',
    }
  },
  /**
   * @params title 头部标题名字
   *
   *
   */
  props: {
    title: {
      type: String,
      default: '',
    },
    fontColor: {
      type: String,
      default: '#000',
    },
    defaultBackgroundColor: {
      type: String,
      default: '#fff',
    },
    scrollBackgroundColor: {
      type: String,
      default: '#fff',
    },
    backgroundImage: {
      type: String,
    },
    showRight: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.headerType = this.$route.meta.headerType
    this.backgroundColor = this.headerType === 4 ? 'transparent' : this.defaultBackgroundColor
    this.opacity = this.headerType === 4 ? '0' : '1'
  },
  mounted() {
    this.bindHeaderScroll(window, 200)
    hideAppBackBtn()
    this.$nextTick(() => {
      hideBar()
    })
  },
  methods: {
    clickLeft() {
      this.$emit('click-left')
    },
    clickRight() {
      this.$emit('click-right')
    },
    bindHeaderScroll(dom, max = 100) {
      if (this.headerType !== 4) return
      if (isNaN(max) || max < 50) {
        return
      }

      this.scrollFunction = () => {
        const st = document.body.scrollTop || document.documentElement.scrollTop
        // console.log(st);
        if (st > max) {
          if (this.opacity != 1) {
            this.backgroundColor = this.scrollBackgroundColor
            this.opacity = 1
            this.scrollStart = true
          }
        } else {
          this.opacity = st / max
          this.backgroundColor = this.scrollBackgroundColor
          this.scrollStart = true
        }
        if (st <= 0) {
          this.opacity = 0
          this.scrollStart = false
          this.backgroundColor = 'transparent'
        }
      }
      dom.addEventListener('scroll', this.scrollFunction)
    },
  },
}
</script>

<style lang="less" scoped>
* {
  box-sizing: border-box;
}
.nav-bar {
  width: 100%;
  left: 0;
  top: 0;
  z-index: 999;
  background-size: cover;
  &.fixed {
    position: fixed;
  }
  &.sticky {
    position: sticky;
  }
}

.tool-bar {
  width: 100%;
  height: 100px;
  position: absolute;
  bottom: 0;
  padding: 0 32px;
  z-index: 1000;
  .right-icon {
    width: 46px;
  }
}
.free-header {
  position: relative;
  width: 100%;
  height: 100px;
  transform: translateZ(0);
  -webkit-transform: translateZ(0);
  padding-top: env(safe-area-inset-top);
  box-sizing: content-box !important;
}
</style>
