<template>
  <div></div>
</template>

<script>
import { universalPushVC, gotoAppPage } from '@/lib/appMethod'
import { getParam } from '@/lib/utils'

export default {
  mounted() {
    let teacherId = getParam().teacherId
    this.$axios.post(`/teacherDimension/app/jumpPage`, { teacherId }).then((res) => {
      if (res.data == 1) {
        gotoAppPage(1)
      } else {
        universalPushVC('51', teacherId, 1, 1)
      }
    })
  }
}
</script> 

<style lang="less" scoped></style>