<template>
  <div>
    <van-popup v-model="show" :safe-area-inset-bottom="true" :overlay="showCover" @close="closePopup" position="bottom" :close-on-click-overlay="false" :lock-scroll="true" :closeable="true">
      <div class="content">
        <div class="title">{{ payData.boxTitle ? payData.boxTitle : '确认订单' }}</div>
        <div class="name row-between-center">
          <div class="left">商品名称</div>
          <div class="left fw6">{{ payData.subject ? payData.subject : '' }}</div>
        </div>
        <div v-if="payData.useTips" class="goodsNum row-between-center">
          <div class="left">使用门店范围</div>
          <div class="left fw6">{{ payData.useTips }}</div>
        </div>
        <div v-if="payData.originalPriceStr" class="goodsNum row-between-center">
          <div class="left">原价</div>
          <div class="left fw6">¥{{ payData.originalPriceStr }}</div>
        </div>
        <div v-if="payData.totalAmount" class="goodsNum row-between-center">
          <div v-if="payData.firstPrice && !payData.extendsJson.giveUpFirstBuy" class="left">新客专享价</div>
          <div v-else class="left">限时售价</div>
          <div class="left fw6">¥{{ payData.totalAmount }}</div>
        </div>
        <!-- 特殊情况 前端写死  对应被升级卡ID：月卡3219 季卡3220 半年卡 3221 年卡 3222 升级卡ID： 月卡：3925 季卡3927 半年卡 3928 年卡 3929 -->
        <div v-if="specialCardMap[payData.mouldId]" class="c-container">
          <div class="coupon-item row-start-center">超值升级 <div class="label f20 fw6 row-center-center">HOT</div></div>
          <div class="row-between-start">
            <div>
              <p class="f28 fw6">{{ specialCardMap[payData.mouldId].name }}</p>
              <p class="f20 mt8">升级后：全天自助免费 不限高峰时段</p>
            </div>
            <div @click="isSpecialCard = !isSpecialCard" :class="['pay-checked row-end-center flex-auto', isSpecialCard ? 'active' : '']" style="padding: 1vw 8.8vw;">
              <p class="add-title f28 fw6">+¥{{ specialCardMap[payData.mouldId].cost }}</p>
            </div>
          </div>
        </div>

        <div class="c-container">
          <div class="coupon-item">优惠立减</div>
          <div class="coupon-item-tips">选择优惠券抵扣将以限时售价购买减免，不与礼品卡余额叠加抵扣，无法与其他优惠同享</div>
          <div @click="selectCoupon()" class="coupon row-between-center">
            <div class="left">优惠券</div>
            <div v-if="list.length && selectedDiscount">
              <span class="s2">-¥</span><span class="s3">{{ selectedDiscount }}</span>
            </div>
            <!-- 选了首购价不能选择优惠券-->
            <div v-else-if="payData.firstPrice && !payData.extendsJson.giveUpFirstBuy">暂无可用优惠券</div>
            <div v-else-if="list.length">
              <span class="s1">有{{ list.length }}张可用</span>
            </div>
            <div v-else>暂无优惠券</div>
          </div>
        </div>

        <div class="pay-type">选择支付方式</div>
        <!-- 支付方式 -->
        <template v-for="item in payTypeList" >
          <div v-if="item.useEnv.includes(appTypeStr)" :key="item.payType" @click="payType = item.payType" :class="['pay-checked', 'row-between-center', payType === item.payType ? 'active' : '']">
            <div class="left">
              <img :src="item.icon" alt="" />
              {{ item.name }}
            </div>
          </div>
        </template>

        <div @click="pay()" class="pay-btn row-center-center fw6">￥{{ submitMoney  }} 去支付</div>
      </div>
    </van-popup>
    <coupon :discount="selectedDiscount" :unavailableList="unavailableList" :couponId="selectedCouponId" :list="list" @confirm="confirmVoucher" :showCoupon.sync="showCoupon"></coupon>
  </div>
</template>
<script>
import userMixin from '@/mixin/userMixin'
import coupon from '@/components/pay-popup-card/coupon'
import { compareVersion } from "@/lib/utils"
import { silentAppPay, getAppVersion } from '@/lib/appMethod'
export default {
  mixins: [userMixin],
  props: {
    showCover: {
      type: Boolean,
      default: true,
    },
    showPayPopup: {
      type: Boolean,
      default: false,
    },
    payData: {
      type: Object,
      default() {
        return {}
      },
    },
    payUrl: {
      type: String,
      default: '',
    },
  },
  components: { coupon },
  data() {
    return {
      show: false,
      payType: 1, // 支付类型 默认1， 只在小程序内使用，默认只有微信
      showCoupon: false,
      // 鹿充券列表
      list: [],
      unavailableList: [],
      // 支付参数
      selectedDiscount: 0,
      selectedCouponId: '',
      // submitMoney: 0 // 最终支付金额
      version: '',

      specialCardMap: {
        '3219': {
          name: '30天不限时月卡',
          id: '3925',
          cost: 50
        },
        '3220': {
          name: '90天不限时季卡',
          id: '3927',
          cost: 130
        },
        '3221': {
          name: '183天不限时半年卡',
          id: '3928',
          cost: 200
        },
        '3222': {
          name: '365天不限时年卡',
          id: '3929',
          cost: 400
        },
      },
      isSpecialCard: false, // 广州特定的卡 有此选项 前端写死
    }
  },
  computed: {
    submitMoney() {
      let t = this.payData.totalAmount * 1

      // 广州特定的卡 有此选项 前端写死 ↓
      if (this.isSpecialCard) {
        t = t + this.specialCardMap[this.payData.mouldId].cost
      }
      // 广州特定的卡 有此选项 前端写死 ↑

      if (!this.selectedDiscount || !this.selectedCouponId) {
        return t
      }
      let p = t - this.selectedDiscount > 0 ? t - this.selectedDiscount : 0
      return p.toFixed(2)
    },
    payTypeList() {
      if (compareVersion(this.version, '4.35.10') < 0) {
        return [
          { payType: 1, name: '微信支付', icon: 'https://chaolucoachwebstatic.chaolu.com.cn/static/img/cash-coupon/wechatpay.png', useEnv: ['and', 'ios', 'mini'] },
          { payType: 2, name: '支付宝支付', icon: 'https://chaolucoachwebstatic.chaolu.com.cn/static/img/cash-coupon/alipay.png', useEnv: ['and', 'ios'] },
        ]
      } else {
        return [
          { payType: 1, name: '微信支付', icon: 'https://chaolucoachwebstatic.chaolu.com.cn/static/img/cash-coupon/wechatpay.png', useEnv: ['and', 'ios', 'mini'] },
          { payType: 2, name: '支付宝支付', icon: 'https://chaolucoachwebstatic.chaolu.com.cn/static/img/cash-coupon/alipay.png', useEnv: ['and', 'ios'] },
          // { payType: 3, name: '云闪付支付', icon: 'https://img.chaolu.com.cn/ACT/icon/unionpay2.png', useEnv: ['and', 'ios'] },
          // { payType: 4, name: '云闪付免密支付', icon: 'https://img.chaolu.com.cn/ACT/icon/unionpay1.png', useEnv: ['and', 'ios', 'mini'] },
        ]
      }
    }
  },
  async created() {
    this.version = await getAppVersion()
  },
  methods: {
    closePopup() {
      this.isSpecialCard = false
      this.showCoupon = false
      this.show = false
      this.$emit('update:showPayPopup', false)
    },
    selectCoupon() {
      if (this.payData.firstPrice && !this.payData.extendsJson.giveUpFirstBuy) {
        return false
      }
      this.showCoupon = true
    },
    getCashVoucher() {
      return this.$axios
        .post(
          `${this.baseURLApp}/voucher/equityCardVoucher`,
          {
            userId: this.userId,
            token: this.token,
            cardItemIds: [this.payData.mouldId],
          },
          false
        )
        .then((res) => {
          let data = (res.data.list && res.data.list[0]) ?? {}
          this.list = data.availableList || [] // 可用列表
          this.unavailableList = data.unavailableList || [] // 不可用列表

          return data
          // this.selectedDiscount = data.maxDiscount || 0
          // this.selectedCouponId = data.maxDiscountVoucherId || ''
          // this.selectedCouponTips = parseInt(data.expireDayTime/1000) || ''
        })
    },
    confirmVoucher(item) {
      if (item) {
        this.selectedDiscount = item.discount || 0
        this.selectedCouponId = item.id || ''
      }
    },
    pay() {
      let payParam = JSON.parse(JSON.stringify(this.payData)), url = this.payUrl

      // 广州特定的卡 有此选项 前端写死 ↑
      if (this.isSpecialCard) {
        payParam.mouldId = this.specialCardMap[payParam.mouldId].id
      }
      // 广州特定的卡 有此选项 前端写死 ↑

      payParam.totalAmount = this.submitMoney
      payParam.discountId = this.selectedCouponId
      payParam.extendsJson.discountId = this.selectedCouponId
      this.show = false
      this.$emit('payComplete', this.selectedCouponId || (!this.payData.extendsJson.giveUpFirstBuy && this.payData.firstPrice) ? 1 : 0)
      silentAppPay(this.payType, payParam, url)
    },
  },
  watch: {
    async showPayPopup(val) {
      // 显示隐藏
      console.log(this.payData)
      this.show = val
      if (val) {
        // 优惠券金额和id 如果从父级传进来就取父级的 没有就取可用优惠券列表返回的最大值
        const r = await this.getCashVoucher()
        if (this.payData.firstPrice && !this.payData.extendsJson.giveUpFirstBuy) {
          this.selectedDiscount = this.payData.selectedDiscount || 0
          this.selectedCouponId = this.payData.discountId || ''
        } else {
          this.selectedDiscount = r.maxDiscount
          this.selectedCouponId = r.maxDiscountVoucherId
        }
      } else {
        this.closePopup()
      }
    },
  },
}
</script>
<style lang="less" scoped>
.content {
  padding: 0 32px 32px;
  box-sizing: border-box;
  overflow-y: scroll;
}

.coupon-box {
  background: #f5f5f5;
  padding-bottom: 0;
  height: 826px;
}

.pay-btn {
  width: 686px;
  height: 96px;
  background: #ffde00;
  border-radius: 8px;
  color: #242831;
  font-size: 32px;
  margin-top: 72px;
  margin-bottom: 38px;
}
.coupon-item {
  color: #242831;
  font-size: 24px;
  font-weight: bold;
  padding: 32px 0 18px;
  border-top: 1px solid #e6e6e6;
  .label {
    padding: 0 8px;
    height: 30px;
    background: #ED5D44;
    border-radius: 4px;
    color: #fff;
    margin: 0 0 0 10px;
  }
}
.coupon-item-tips {
  color: #242831;
  font-size: 24px;
}
.coupon {
  height: 100px;
  box-sizing: border-box;
  //border-top: 1px solid #E6E6E6;
  border-bottom: 1px solid #e6e6e6;
  background: url('https://chaolucoachwebstatic.chaolu.com.cn/static/img/cash-coupon/arrow-right.png') no-repeat center right;
  background-size: 24px 24px;
  padding-right: 34px;
  margin-top: 12px;
  color: #9aa1a9;
  font-size: 24px;
  .coupon-price-red {
    color: #fb732e;
  }
  .s1 {
    font-weight: bold;
    color: #242831;
    font-size: 24px;
  }
  .s2 {
    font-family: BebasNeueBold;
    font-size: 32px;
    font-weight: bold;
    color: #f03c18;
    margin-right: 4px;
  }
  .s3 {
    font-family: BebasNeueBold;
    color: #f03c18;
    font-size: 40px;
    font-weight: bold;
  }
}
.coupon-title {
  font-weight: bold;
  color: #242831;
  font-size: 36px;
  padding-left: 56px;
  height: 144px;
  display: flex;
  align-items: center;
  background: url('https://chaolucoachwebstatic.chaolu.com.cn/static/img/cash-coupon/arrow-left.png') no-repeat center left;
  background-size: 40px 40px;
}

.coupon-list {
  height: calc(100% - 144px);
  overflow-y: scroll;
}

.coupon-list::-webkit-scrollbar {
  display: none;
}

.price {
  padding: 28px 0 32px;
}

.price > div .s1 {
  text-decoration: line-through;
  color: #6c727a;
  line-height: 28px;
  font-size: 24px;
  margin-right: 16px;
}

.price > div .s2 {
  font-family: BebasNeueBold;
  color: #242831;
  font-size: 24px;
  font-weight: bold;
}

.price > div .s3 {
  font-family: BebasNeueBold;
  color: #242831;
  font-size: 40px;
  font-weight: bold;
}

.left {
  font-size: 24px;
  color: #242831;
  line-height: 28px;
}

.title {
  color: #242831;
  font-size: 36px;
  font-weight: bold;
  padding: 48px 0;
}

.pay-type {
  color: #242831;
  font-size: 24px;
  font-weight: bold;
  padding: 32px 0 8px;
}
.pay-checked {
  background: url('https://chaolucoachwebstatic.chaolu.com.cn/static/img/cash-coupon/normal.png') no-repeat center right;
  background-size: 40px 40px;
  padding: 24px 0;

  &.active {
    background-image: url('https://chaolucoachwebstatic.chaolu.com.cn/static/img/cash-coupon/active.png');
  }

  div {
    display: flex;
    align-items: center;
  }

  img {
    width: 40px;
    height: 40px;
    margin-right: 16px;
  }
}

.coupon-box .item {
  background-color: white;
}

.coupon-box .item {
  margin-bottom: 32px;
}

.coupon-box .item-i {
  display: flex;
  justify-content: space-between;
  //align-items: center;
}

.coupon-box .item-l {
  width: 192px;
  height: 200px;
  background: url('https://chaolucoachwebstatic.chaolu.com.cn/static/img/cash-coupon/card-coupon-active.png');
  background-size: 100% 100%;
  color: white;
  font-size: 20px;
  flex-shrink: 0;
}

.coupon-box .item-l > div:first-child {
  font-family: BebasNeueBold;
  font-size: 80px;
}

.coupon-box .item-l > div:first-child span {
  font-size: 36px;
}

.coupon-box .item-r {
  flex-grow: 1;
  box-sizing: border-box;
  padding-left: 16px;
  position: relative;
}

.coupon-box .item-r .coupon-tabs {
  display: flex;
  flex-wrap: wrap;
  margin-top: 12px;
}

.coupon-box .item-r .coupon-tabs span {
  padding: 4px 8px;
  font-weight: bold;
  color: #fb732e;
  font-size: 18px;
  border: 1px solid #fb732e;
  border-radius: 4px;
  margin-bottom: 8px;
  margin-right: 8px;
}

.coupon-box .item-r .coupon-date {
  margin-top: 38px;
  font-size: 20px;
  line-height: 23px;
  color: #6c727a;
  position: absolute;
  left: 16px;
  bottom: 24px;
}

.coupon-box .item-r .coupon-name {
  line-height: 28px;
  font-weight: bold;
  font-size: 24px;
  padding-top: 24px;
}

.goodsNum {
  margin-top: 48px;
}

.coupon-box .item-r .arrow {
  position: absolute;
  right: 4px;
  bottom: 8px;
  width: 24px;
  height: 24px;
  transition: transform 0.5s;
  padding: 20px;
}

.coupon-box .arrow.rotate {
  transform: rotateZ(180deg);
}
.c-container {
  margin-top: 42px;
}
.add-title {
  color: #F03C18;
}
</style>
