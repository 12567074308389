import {
    appGetUser,
    appGetToken,
    appGetCityId,
    appGetLocalVenue,
    appGetLocal,
    appType,
    appGetUserId,
    appGetTeacherId,
    padGetTeacherId,
    appGetTeacherToken
} from '@/lib/appMethod';
import { mapState } from "vuex";
async function getAppParams2(promiseArr) {
    return await Promise.all(promiseArr);
}
export default {
    data() {
        return {
            // userId: this.$store.state.userId,
            // token: this.$store.state.token
        }
    },
    computed: {
        ...mapState(['userId','userData', 'token', 'companyUser', 'companyAdmin', 'local', 'cityId', 'venueId', 'teacherToken', 'teacherUserId', 'teacherId']),
    },
    methods: {
        $getUserIdByAPI(userData, token) {
            return new Promise((resolve, reject) => {
                let params = {
                    userData,
                    token,
                };
                if(!userData || !token) {
                    reject();
                    return false
                }
                this.$axios
                    .post(this.baseURLApp + '/app/decryptUserData/v1', params)
                    .then((res) => {
                        if (res.code == 1) {
                            resolve({ userId: res.data.id, token });
                        } else {
                            reject();
                        }
                    })
                    .catch(() => {
                        reject();
                    });
            });
        },
        async $getAllInfo(mlist = ['userId']) {
            if (!Array.isArray(mlist)) {
                return
            }
            const methodsJson = {
                userId: this.$getUserId,
                cityId: this.$getCityId,
                venueId: this.$getVenueId,
                local: this.$getLocal,
                teacherToken:this.$getTeacherToken,
                teacherId:this.$getTeacherId,
                teacherUserId:this.$getTeacherUserId
            }
            const dolist = [];
            for (let i = 0; i < mlist.length; i++) {
                dolist.push(methodsJson[mlist[i]] && methodsJson[mlist[i]]());
            }
            const final = await Promise.all(dolist);
            return Promise.resolve(final);
        },
        async $getCityId() {
            let cityId = this.cityId;
            if (!this.cityId) {
                if(this.appTypeStr === 'ios' || this.appTypeStr === 'and'){
                    try {
                        cityId = await appGetCityId();
                    } catch (error) {
                        cityId = this.$route.query.cityId;
                    }
                }else{
                    cityId = this.$route.query.cityId;
                }
            }
            this.$store.commit('setCityId', cityId)
            return Promise.resolve(this.cityId);
        },
        async $getVenueId() {
          let venueId = this.venueId;
          if (!this.venueId) {
              if(this.appTypeStr === 'ios' || this.appTypeStr === 'and'){
                  try {
                    venueId = await appGetLocalVenue();
                  } catch (error) {
                    venueId = this.$route.query.venueId;
                  }
              }else{
                venueId = this.$route.query.venueId;
              }
          }
          console.log('1212------------------1',venueId)
          this.$store.commit('setVenueId', venueId)
          return Promise.resolve(venueId);
      },
        async $getLocal() {
            console.log(this.local)
            if (!this.local) {
                let localJson = null;
                if (this.appTypeStr === 'ios' || this.appTypeStr === 'and') {
                    try {
                        const local = await appGetLocal();
                        if (local) {
                            const latAndlng = local.replace('lat-', '').replace('long-', '').replace(RegExp("Failed", "g"), '').split('|');
                            if (latAndlng && latAndlng.length > 1) {
                                localJson = { lat: latAndlng[0], lng: latAndlng[1] }
                            }
                        }
                    } catch (error) {
                        const lat = this.$route.query.latitude;
                        const lng = this.$route.query.longitude;
                        if (lat && lng) {
                            localJson = { lat, lng }
                        }
                    }
                } else {
                    const lat = this.$route.query.latitude;
                    const lng = this.$route.query.longitude;
                    if (lat && lng) {
                        localJson = { lat, lng }
                    }
                }
                this.$store.commit('setLocal', localJson)
            }
            return Promise.resolve(this.local);
        },
        async $getUserId() {
            if (!this.userId || !this.token) {
                let data_userid = '';
                let data_token = '';
                let data_user='';
                if (!this.appTypeStr) { this.appTypeStr = appType() }//hack
                if (this.appTypeStr === 'ios' || this.appTypeStr === 'and') {
                    try {
                        const appCommParams = await getAppParams2([
                            appGetUser(),
                            appGetToken(),
                        ]);
                        data_user=appCommParams[0];
                        const userJson = await this.$getUserIdByAPI(
                            appCommParams[0],
                            appCommParams[1]
                        );
                        data_userid = userJson.userId;
                        data_token = userJson.token;console.log(data_userid,data_token);
                    } catch (err) {
                        console.log(err, '不是APP环境环境错误');
                    }
                } else if (this.appTypeStr === 'mini') {//小程序
                    data_userid = this.$route.query.userId
                    data_token = this.$route.query.token;
                }
                if (process.env.NODE_ENV == "development" && !data_userid) {
                    // data_userid = '19552758';
                    data_userid = '29554099';
                    data_token = 'YhObbi9mYwTxQPTA4Qu'
                }

                if(process.env.VUE_APP_MODE=='wxg'){
                    data_token= "Y81gUH0eCed5qTsKFj7"
                    data_userid= '19552795'
                    // data_token= "hA85cR8u0W5EmR2dznJ"
                    // data_userid= '20216'
                    // this.$store.commit('setCityId', 3);
                }

                // eslint-disable-next-line camelcase
                if (data_userid && data_token) {
                    this.$store.dispatch('setUserId', data_userid);
                    this.$store.dispatch('setToken', data_token);
                    this.$store.dispatch('setUserData', data_user);
                }
            }
            return Promise.resolve({ userId: this.userId, token: this.token,userData:this.userData });
        },
        async $getCompanyUser() {
            return new Promise((resolve, reject) => {
                if (this.userId && this.token) {
                    if (this.companyUser) {
                        resolve(this.companyUser);
                    } else {
                        this.$axios.post(this.baseURL + '/coo/getMyMessage', { userId: this.userId, token: this.token }).then(res => {
                            // console.log(res);
                            if (res.code == '1') {
                                this.$store.commit('setCompanyUser', res.data);
                                resolve(res.data);
                            } else {
                                reject();
                            }
                        })
                    }
                } else {
                    reject();
                }
            })
        },
        $getCompanyAdmin(refresh) {
            return new Promise(async (resolve, reject) => {
                if (this.companyAdmin&&!refresh) {
                    resolve(this.companyAdmin);
                } else {
                    await this.$getCompanyUser();
                    if (this.userId && this.token && this.companyUser) {
                        this.$axios.post(this.baseURL + '/coo/getAdminMessage', { userId: this.userId, token: this.token, companyId: this.companyUser.companyId }).then(res => {
                            // console.log(res);
                            if (res.code == '1') {
                                this.$store.commit('setCompanyAdmin', res.data);
                                resolve(res.data);
                            } else {
                                reject();
                            }
                        })
                    } else {
                        reject();
                    }
                }
            })
        },
        async $getTeacherToken() {
            let teacherToken = null;
            if (!this.teacherToken) {
                try {
                    teacherToken = await appGetTeacherToken();
                    console.log('teachertoken::', teacherToken);
                    this.$store.commit('setTeacherToken', teacherToken)
                } catch (error) {

                }
            }
            return Promise.resolve(this.teacherToken);
        },
        async $getTeacherId() {
            let teacherId = null;
            if (!this.teacherId) {
                try {
                    teacherId = await appGetTeacherId() || await padGetTeacherId();
                    this.$store.commit('setTeacherId', teacherId)
                } catch (error) {}
            }
            return Promise.resolve(this.teacherId);
        },
        async $getTeacherUserId() {
            let teacherUserId = null;
            if (!this.teacherUserId) {
                try {
                    teacherUserId = await appGetUserId();
                    this.$store.commit('setTeacherUserId', teacherUserId)
                } catch (error) {

                }
            }
            return Promise.resolve(this.teacherUserId);
        }
    }
}
