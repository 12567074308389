<template>
  <div class="table-box" :style="{ height: 'calc(100vh - ' + listHeight + 'px)' }">
    <div class="table-title">
      <span class="time">
        {{ name }}
      </span>
      <span class="arrange1">
        <span>绑定</span><span>（人）</span>
      </span>
      <span class="arrange1">
        <span>进馆</span><span>（人）</span>
      </span>
      <span class="arrange2" v-for="i in listJson">
        <span @click="tipsShow = true">{{ i.title }}<van-icon name="warning-o" /></span><span v-if="i.unit">({{ i.unit
        }})</span>
      </span>
    </div>
    <div class="table-body iphonex-pb" ref="bodyRef">
      <van-pull-refresh v-model="refreshing" @refresh="refreshFun" pulling-text="继续往下拉" loosing-text="该放手了，我要刷新啦..."
        loading-text="拼命加载中" :pull-distance="100">
        <div class="table-item" v-for="(i, key) in list" :key="key">
          <span class="time ellipsis blue" v-if="active == 0 && menuIndex == 1"
            @click="goTeamAndUser('user', i.userId)">{{ i.userName }}</span>
          <span class="time ellipsis blue" v-else-if="active == 0 && menuIndex == 2"
            @click="goTeamAndUser('team', i.saleTeamId)">{{ i.saleTeamName }}</span>
          <span class="time" v-else>{{ i.date }}</span>
          <span class="arrange1">{{ i.saleTeamId == -1 ? '-' : i.memberNum }}</span>
          <span class="arrange1">{{ i.saleTeamId == -1 ? '-' : i.indoorNum }}</span>
          <span class="arrange2" v-for="i2 in listJson" v-if="list.length > 0">{{ i.saleData[i2.param] }}/{{
            i.writeOffData[i2.param] }}/{{ i.refundData[i2.param] }}</span>
        </div>
        <div class="empty" ref="emptyRef" v-if="list.length == 0">
          <img src="https://img.chaolu.com.cn/ACT/mini-team/my-team/empty.png" alt="">
          <p>暂无数据</p>
        </div>
      </van-pull-refresh>
    </div>
    <van-popup v-model="tipsShow" round closeable>
      <div class="pop">
        销售数量 / 核销数量 / 核销退款数量
      </div>
    </van-popup>
  </div>
</template>

<script>
export default {
  data() {
    return {
      refreshing: false,
      data: {},
      list: [],
      emptyHeight: 0,
      tipsShow: false,
      listJson: [
        { title: '超鹿月卡', unit: '月卡', param: 'superDeerCardMonthNum' },
        { title: '超鹿月卡', unit: '季卡', param: 'superDeerCardQuarterNum' },
        { title: '超鹿月卡', unit: '半年卡', param: 'superDeerCardHalfYearNum' },
        { title: '超鹿月卡', unit: '年卡', param: 'superDeerCardYearNum' },
        { title: '私教体验课', unit: '19.9元', param: 'perNineteenNum' },
        { title: '私教体验课', unit: '99元', param: 'perNinetynineNum' },
        { title: '团课钩子', param: 'groupHookNum' },
        { title: '私教课包', param: 'perPackageNum' },
        { title: '包月私教', param: 'perCardNum' },
        { title: '礼品卡', param: 'giftNum' },
        { title: '团课产品', param: 'groupProductNum' },
      ],
    }
  },
  props: ['menuIndex', 'active', 'listHeight', 'getD'],
  computed: {
    name() {
      return this.active == 0 ? this.menuIndex == 1 ? '队员' : '团队' : '日期'
    }
  },
  watch: {
    active() {
      this.list = []
    }
  },
  methods: {
    getData(d) {
      d ? this.data = d : ''
      this.active == 0 ? this.getTeamUser() : this.getNewAdd()
    },
    refreshFun() {
      this.$emit('refresh')
      this.getData()
    },
    // 我的团队
    getTeamUser() {
      this.$axios.post(this.baseURLApp + "/userExpansionBing/query/getMyTeam", this.data)
        .then((res) => {
          this.list = res.data
          this.refreshing = false
          this.$emit('teamList', res.data)
        })
    },
    // 每日新增
    getNewAdd() {
      this.$axios.post(this.baseURLApp + "/userExpansionBing/query/getDaily", this.data)
        .then((res) => {
          this.list = res.data
          this.refreshing = false
        })
    },
    // 跳转
    goTeamAndUser(type, id) {
      this.$emit('goTeamAndUser', { type, id })
    }
  }
}
</script>

<style lang="less" scoped>
.table-box {
  overflow: scroll;


  .table-title,
  .table-body .table-item {
    display: flex;
    align-items: center;
    font-size: 24px;
    width: 1850px;

    >span {
      text-align: center;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .time {
      width: 176px;
      position: sticky;
      left: 0;
      padding: 0px 10px;
      z-index: 10;
    }

    .arrange1 {
      width: 124px;
    }

    .arrange2 {
      width: 174px;
    }
  }

  .table-title {
    font-weight: 500;
    position: sticky;
    top: 0px;
    z-index: 10;

    >span {
      height: 124px;
      background-color: #F6FAFF;
    }

    .arrange1,
    .arrange2 {
      display: flex;
      flex-direction: column;

      >span:nth-child(2) {
        font-size: 20px;
        font-weight: 400;
        margin-top: 4px;
      }
    }
  }

  .table-body {

    .table-item {
      >span {
        height: 88px;
        background-color: #fff;
      }

      &:nth-of-type(odd) {
        >span {
          background-color: #F9FAFB;
        }
      }
    }
  }

  .blue {
    color: #267DFF !important;
  }
}
.pop{
  font-size: 28px;
  width: 600px;
  padding: 90px 0px 50px;
  text-align: center;
}

/deep/ .van-pull-refresh {
  overflow: visible;
}

.empty {
  padding-top: 100px;
  text-align: center;
  color: #6C727A;
  font-size: 26px;
  font-weight: 400;
  box-sizing: border-box;
  min-height: 40vh;

  >img {
    width: 240px;
    height: 240px;
    margin-bottom: 20px;
  }
}
</style>