<template>
  <van-popup class="com-city-venue" v-model="popShow" position="bottom" safe-area-inset-bottom>
    <div class="pop-title">
      选择门店
      <van-icon name="cross" class="pop-close" @click="popShow = false" />
    </div>
    <div class="cvbody">
      <div class="left">
        <div class="city-model" :class="{ active: chooseCityId == item.id }" v-for="item in cityList" :key="item.id"
          @click="setLeft(item.id)">{{ item.label }}</div>
      </div>
      <div class="right">
        <div class="venue-model" :class="{ active: chooseVenueId == item.id }" v-for="item in venueList" :key="item.id"
          @click="setRight(item)" v-if="justOpen?item.isOpen:true">
          {{ item.label }}
          <div v-if="!item.isOpen" class="tag f20 row-center-center">未开业</div>
        </div>
      </div>
    </div>
    <div class="tips">{{ label }}</div>
    <div class="sub" :class="{ disabled: !chooseVenueId }" @click="sure">{{ subStr }}</div>
  </van-popup>
</template>

<script>
export default {
  props: {
    cityId: {
      type: [Number, String],
      default: 3,
    },
    cityAndVenue: {
      type: Array,
    },
    subStr: {
      type: String,
      default: '确 认',
    },
    label: {
      type: String,
      default: '*请选择方便前往兑换的门店，到店找教练核销兑换。',
    },
    disableCityId: {
      type: Array,
      default() {
        return []
      },
    },
    justOpen:{
      type:Boolean,
      default:false
    }
  },
  data: function () {
    return {
      popShow: false,
      cityList: [],
      venueList: [],
      chooseCityId: this.cityId,
      chooseVenueId: undefined,
    }
  },
  watch: {
    cityId(v) {
      this.chooseCityId = v
      this.chooseVenueId = undefined
      this.filterVenue()
    },
    cityAndVenue(v) {
      this.chooseCityId = v[0]
      this.chooseVenueId = v[1]
      this.filterVenue()
    }
  },
  methods: {
    setLeft(id) {
      if (this.chooseCityId == id) {
        return
      }
      this.chooseCityId = id
      this.filterVenue()
    },
    filterVenue() {
      this.venueList = this.base?.filter((d) => {
        return d.cid == this.chooseCityId
      })
    },
    setRight(item) {
      if (this.chooseVenueId == item.id) {
        this.chooseVenueId = undefined
        this.venueItem = null
        return
      }
      this.chooseVenueId = item.id
      this.venueItem = item
      if (!this.chooseCityId) {
        this.setLeft(item.cid)
      }
    },
    getCityAndVenue() {
      return this.$axios.post(this.baseURLApp + '/app/sys/area/lessionh5/top').then((res) => {
        let treeList = res.data || []
        this.treeList = treeList
        this.removeNodeInTree(treeList)
        const { left, right } = this.filterData(treeList)
        this.cityList = left
        this.base = right
        this.venueList = JSON.parse(JSON.stringify(right)) // isOpen 0待开业
      })
    },
    filterData(data) {
      const left = []
      const right = []
      function mapMenu(data, cid) {
        data.forEach((item) => {
          if (item.type == 3) {
            //city
            left.push({ id: item.id, label: item.label })
            cid = item.id
          } else if (item.type == 5) {
            //store
            right.push({ id: item.venueId, cid, label: item.label, isOpen: item.isOpen })
          }
          if (item.children && item.children.length) {
            mapMenu(item.children, cid)
          }
        })
      }
      mapMenu(data)
      return {
        left,
        right,
      }
    },
    removeNodeInTree(treeList) {
      if (!treeList || !treeList.length || !this.disableCityId || !this.disableCityId.length) {
        return false
      }
      for (let i = 0; i < treeList.length; i++) {
        if (this.disableCityId.includes(treeList[i].id)) {
          treeList.splice(i, 1)
          this.removeNodeInTree(treeList)
        } else {
          this.removeNodeInTree(treeList[i].children)
        }
      }
    },
    sure() {
      if (this.chooseVenueId) {
        this.$emit('setVenue', this.venueItem)
        this.popShow = false
      }
    },
  },
  created() {
    this.getCityAndVenue().then(() => {
      if (this.cityId) {
        this.filterVenue()
      }
    })
  },
}
</script>

<style lang="less" scoped>
.pop-title {
  height: 97px;
  line-height: 97px;
  position: relative;
  text-align: center;
  font-weight: bold;
  border-bottom: 1px solid #eee;

  .pop-close {
    position: absolute;
    right: 32px;
    top: 32px;
  }
}

.cvbody {
  margin-top: 35px;
  height: 687px;
  overflow: hidden;
  display: flex;
  font-size: 24px;
  padding: 0 32px;
  color: #242831;
  border-bottom: 1px solid #eee;

  .left {
    width: 212px;
    height: 100%;
    overflow: auto;
    flex-shrink: 0;

    .city-model {
      width: 100%;
      margin-bottom: 20px;
      background: #f5f5f5;
      line-height: 76px;
      text-align: center;
      border-radius: 6px;
      position: relative;
    }
  }

  .right {
    height: 100%;
    overflow: auto;
    flex: 1;
    margin-left: 30px;

    .venue-model {
      line-height: 92px;
      background: #f5f5f5;
      text-align: center;
      margin-bottom: 20px;
      border-radius: 6px;
      position: relative;

      .tag {
        width: 80px;
        height: 32px;
        background: rgba(0, 0, 0, 0.06);
        border-radius: 0px 8px 0px 8px;
        position: absolute;
        right: 0;
        top: 0;
      }
    }
  }

  .active::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    border: 2px solid #232831;
    border-radius: 8px;
  }
}

.tips {
  color: #68413c;
  font-size: 22px;
  text-align: center;
  margin: 28px 0 20px;
}

.sub {
  border-radius: 8px;
  line-height: 96px;
  color: #242831;
  font-size: 32px;
  background: #ffde00;
  text-align: center;
  font-weight: bold;
  margin: 0 32px 26px;
}

.disabled {
  // background: #ccc3c3;
  opacity: 0.5;
  pointer-events: none;
}</style>
