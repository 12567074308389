<template>
    <div class="page-questionnaire iphonex-pb">
        <HeadeBar title="运动需求问卷" left-arrow @click-left="back">
        </HeadeBar>
        <div class="c-banner">
            <div class="c1">已有<span class="BebasNeueBold num">{{ num }}</span>名团课新会员选择了定制化推荐</div>
            <div class="c2">预计60秒完成问卷，系统将为您定制化推荐课程</div>
            <img src="https://img.chaolu.com.cn/ACT/questionnaire/deer.png" class="logo" />
        </div>
        <div class="c-body">
            <form @submit.prevent="submit" action="">
                <template v-for="item,index in quesionList">
                    <div class="c-title">{{ index+1 }}. {{ item.questionContent }}<span class="must" v-show="!check(index)">（请选择）</span>
                    </div>
                    <div class="c-base" :class="index==2||index==3?'item-big':''">
                        <div class="c-item" :class="{ 'c-active': item.answerId === citem.answerId}"
                            v-for="(citem, cindex) in item.answerList" @click="item.answerId = citem.answerId">{{ citem.answerContent
                            }}
                        </div>
                    </div>
                </template>

                <div class="c-submit iphonex-pb">
                    <input class="c-button" type="submit" value="提交">
                </div>
            </form>
        </div>
        <van-popup v-model="showBack" position="center" class="pop-get">
            <div class="pop-get-title">
                退出确认
            </div>
            <div class="pop-get-tips">
                不想填写运动需求？看看大家上什么课！<br />为你推荐适合大部分新人的团课课程~
            </div>
            <div class="subline">
                <div class="sub pop-sub1" @click="newAppBack">退出页面</div>
                <div class="sub pop-sub2" @click="toRecommend">立即前往</div>
            </div>

        </van-popup>
    </div>
</template>

<script>
import { newAppBack, initBack } from '@/lib/appMethod';
import HeadeBar from '@/components/app/headBar';
import CommonShare from '@/components/commonShare';
import userMixin from '@/mixin/userMixin';
import wx from 'weixin-js-sdk'

export default {
    mixins: [userMixin],
    components: {
        HeadeBar
    },
    data() {
        return {
            targetList: ['减脂', '塑形', '提高健康水平', '释放压力', '静心'],
            vibesList: ['充满活力', '令人兴奋', '激情四射', '开心社交', '舒缓轻松'],
            difficultyList: ['简单易上手', '有技巧能跟上', '动作组合丰富'],
            strengthList: ['轻松运动', '微出汗', '欢畅爆汗', '有明显出汗及肌肉感受', '自我突破 充满挑战'],
            typetList: ['力量训练', '搏击格斗', '街舞舞蹈', '瑜伽/普拉提', '高强度间歇'],

            formData: {
                target: '',
                vibes: '',
                difficulty: '', strength: '', type2: '',
            },
            showCheck: false,
            showBack: false,
            canClick: true,
            showShare: false,
            otherCityActive: false,
            shareParams: {
                title: '成为超鹿教练，让生命更澎拜',
                miniImage: 'https://img.chaolu.com.cn/ACT/coach-will-survey/mini.png',
                path: `/pages/webView/index?webUrl=${window.location.origin}/#/coach-will-survey`,
                miniProgramType: window.location.origin.indexOf('web.chaolu.com') > -1 ? '0' : '2', // 正式0，开发1，体验2
                url: 'url',
                multiple: "0.95",
                userName: "gh_7f5b57b6a76e",
            },
            quesionList: [],
            has: false,
            num: 0
        }
    },
    methods: {
        newAppBack,
        back(){
            if(this.$route.query.rewrite){
                this.toRecommend();
            }else{
                this.showBack = true
            }
        },
        submit() {
            // this.$router.push({ path: '/group-questionnaire/recommend' }); return;
            if (!this.canClick) {
                this.$toast('您已提交过了');
                return;
            }
            this.showCheck = true;
            if (this.check()) {
              
                const ps = {
                    cityId:this.cityId,
                    venueId:this.venueId||1,
                    userId:this.userId,
                    requestList:this.quesionList
                }
                console.log(ps);
                this.$toast.loading({
                    duration: 0,
                    forbidClick: true,
                    loadingType: 'spinner',
                })
                this.$axios
                    .post(this.baseURLApp + "/a/recommend/group/newcomer/question/insert", {
                        ...ps,
                        userId: this.userId,
                        token: this.token
                    })
                    .then((res) => {
                        this.toRecommend();
                    }).catch(() => {

                    }).then(() => {
                        this.$toast.clear()
                    })
            } else {
                this.$toast('请完善资料');
            }
        },
        check(index) {
            if (!this.showCheck) {
                return true
            }
            if (index!==undefined) {
                if (!this.quesionList[index].answerId) {
                    return false
                }
            } else {
                for (let i in this.quesionList) {
                    if (!this.quesionList[i].answerId) {
                        return false
                    }
                }
            }
            return true;
        },
        toRecommend(){
            this.$router.replace({ path: '/group-questionnaire/recommend',query:this.$route.query });
        },
        checkHas() {
            this.$axios
                .post(this.baseURLApp + "/a/recommend/group/newcomer/question/isComplete", {
                    cityId: this.cityId,
                    userId: this.userId
                })
                .then((res) => {
                    if (res.data) {
                        this.toRecommend();
                    }
                })
        }
    },
    mounted() {

    },
    async created() {
        initBack();
        await this.$getAllInfo(['userId', 'cityId', 'venueId', 'local']);
        if (!this.$route.query.rewrite) {
            this.checkHas();
        }
        this.$axios
            .post(this.baseURLApp + "/a/recommend/group/newcomer/question/getQuestionConfig", {
                cityId:'3',
                userId: this.userId
            })
            .then((res) => {
                this.num = res.data.recommendNum || 0;
                this.quesionList = (res.data.quesionList || []).map(d=>{
                    return {...d,answerId:undefined}
                })
            })
        // const uu = encodeURIComponent(`${window.location.origin}/#/coach-will-survey`)
        // this.shareParams.path = `/pages/webView/index?webUrl=${uu}`
        // wx.miniProgram.postMessage({
        //     data: {
        //         type: 'share',
        //         shareImage: this.shareParams.miniImage,
        //         shareTitle: this.shareParams.title,
        //         shareUrl: this.shareParams.path,
        //     },
        // })
    }
}
</script>

<style lang="less" scoped>
.page-questionnaire {
    background: #fff;
    // position: fixed;
    ////height: 100vh;
    // top: 0;
    // bottom: 0;
    // left: 0;
    // right: 0;
    // overflow: auto;
    // -webkit-overflow-scrolling: touch;

    div {
        box-sizing: border-box;
    }

    img {
        vertical-align: top;
        width: 100%;
    }

    .share-logo {
        width: 40px;
        height: 40px;
    }

    .c-banner {
        margin: 38px 32px 0;
        height: 134px;
        background: linear-gradient(90deg, #FFECAC 0%, #FFDCA5 100%);
        border-radius: 10px;
        padding: 30px 0 0 20px;
        position: relative;

        .c1 {
            font-weight: 600;
            font-size: 26px;
            color: #000000;

            .num {
                font-weight: normal;
                font-size: 38px;
                color: #F03C18;
                margin: 0 4px;
            }
        }

        .c2 {
            margin-top: 6px;
            font-size: 20px;
            color: #222222;
        }

        .logo {
            width: 160px;
            height: 160px;
            position: absolute;
            right: 0;
            bottom: 0;
        }
    }

    .c-body {
        width: 686px;
        margin: 0 auto;
        padding-bottom: 200px;

        .c-title {
            color: #242831;
            font-size: 28px;
            font-weight: bold;
            margin-bottom: 39px;
            margin-top: 70px;
            line-height: 1;
            display: flex;
            align-items: center;

            .must {
                color: #ED5C42;
                font-size: 24px;
                margin-left: 17px;
            }

            .c-multiple {
                width: 100px;
                height: 40px;
                background: #F5F5F5;
                margin-left: 16px;
                border-radius: 20px;

                span {
                    font-weight: bold;
                    color: #6C727A;
                    text-align: center;
                    display: block;
                    font-size: 24px;
                    transform: scale(0.9);
                    transform-origin: 50% 50%;
                    line-height: 42px;
                }
            }
        }

        .c-active {
            font-weight: bold;

            &::after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                border: 2px solid #232831;
                border-radius: 8px;
                z-index: 0;
            }
        }

        .c-base {
            display: flex;
            flex-wrap: wrap;

            .c-item {
                height: 76px;
                line-height: 1.4;
                display: flex;
                align-items: center;
                border-radius: 8px;
                background: #F5F5F5;
                font-size: 24px;
                color: #242831;
                margin-bottom: 24px;
                padding: 0 30px;
                position: relative;
                width: 212px;
                justify-content: center;
                margin-right: 25px;

                &:nth-child(3n+3) {
                    margin-right: 0;
                }

                .ip {
                    text-align: center;
                }

                &:nth-last-child(1) {
                    margin-bottom: 0;
                }

                &:nth-last-child(2) {
                    margin-bottom: 0;
                }

                &:nth-last-child(3) {
                    margin-bottom: 0;
                }
            }

            .big {
                width: 330px;
                margin-right: 0;
                margin-right: 26px;

                &:last-of-type {
                    margin-right: 0;
                }
            }
        }
        .item-big{
            justify-content: space-between;
            .c-item{
                width:330px ;
                margin-right: 0px;
                margin-bottom: 24px !important;
                &:nth-child(2),&:nth-child(3){
                    margin-bottom: 0px !important;
                }
            }
        }
    }

    .c-submit {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        background: #fff;
        z-index: 999;
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        padding-left: 32px;
        padding-right: 32px;

        .c-button {
            width: 100%;
            text-align: center;
            margin: 20px 0;
            background: #FFDE00;
            line-height: 96px;
            color: #242831;
            font-size: 32px;
            border: none;
            font-weight: bold;
            border-radius: 8px;
        }
    }

    .pop-get {
        border-radius: 12px;
        width: 590px;
        height: 402px;
        background: #fff;
        text-align: center;

        .pop-get-title {
            color: #242831;
            font-weight: bold;
            font-size: 36px;
            line-height: 1;
            margin-top: 50px;
            margin-bottom: 34px;
        }

        .pop-get-tips {
            color: #3C454E;
            font-size: 24px;
            line-height: 40px;
        }


        .subline {
            margin: 60px 40px 0;
            line-height: 96px;
            display: flex;
            justify-content: space-between;

            .sub {
                width: 244px;
                border-radius: 8px;
                height: 96px;
                font-size: 32px;
                text-align: center;
            }

            .pop-sub1 {
                border: 1px solid #D9D9D9;
            }

            .pop-sub2 {
                font-weight: 600;
                background: #FFDE00;
                color: #242831;
            }
        }
    }
}
</style>