const routes = [
  {
    "path": "/venue-course",
    "name": "selfGroupClass",
    "meta": { "title": "", "headerType": 2 },
    component: () => import(/* webpackChunkName: "selfGroupClass" */'../views/venueCourse.vue')
  },
  {
    "path": "/selfGroupClass",
    "name": "selfGroupClass2",
    "meta": { "title": "", "headerType": 2 },
    component: () => import(/* webpackChunkName: "selfGroupClass" */'../views/selfGroupClass.vue')
  },
  {
    "path": "/self-group-class",
    "name": "selfGroupClass1",
    "meta": { "title": "", "headerType": 2 },
    component: () => import(/* webpackChunkName: "selfGroupClass" */'../views/self-group-class.vue')
  },
  {
    "path": "/emptyPage",
    "name": "emptyPage",
    "meta": { "title": "超鹿运动", "headerType": 2 },
    component: () => import(/* webpackChunkName: "emptyPage" */'../views/emptyPage.vue')
  },
  { "path": "/venue-guide", "name": "venue-guide", "meta": { "title": "门店指引", "headerType": 4 }, component: () => import(/* webpackChunkName: "mid-autumn2021" */'../views/venue-guide/index') },
  { "path": "/license-agreement", "name": "license-agreement", "meta": { "title": "授权协议", "headerType": 2 }, component: () => import(/* webpackChunkName: "license-agreement" */'../views/coach/license-agreement.vue') },
  { "path": "/case-guide", "name": "case-guide", "meta": { "title": "案例制作指南", "headerType": 4 }, component: () => import(/* webpackChunkName: "case-guide" */'../views/coach/case-guide.vue') },
  { "path": "/course-buy", "name": "course-buy", "meta": { "title": "课包购买", "headerType": 2 }, component: () => import(/* webpackChunkName: "course-buy" */'../views/course-buy.vue') },
  { "path": "/course-buy-bymall", "name": "course-buy-bymall", "meta": { "title": "课包购买", "headerType": 2 }, component: () => import(/* webpackChunkName: "course-buy" */'../views/course-buy-bymall.vue') },
  { "path": "/official-account", "name": "official-account", "meta": { "title": "超鹿运动", "headerType": 2 }, component: () => import(/* webpackChunkName: "official" */'../views/official-account.vue') },
  { "path": "/common-article", "name": "common-article", "meta": { "title": "", "headerType": 2 }, component: () => import(/* webpackChunkName: "common-article" */'../views/common-article') },
  { "path": "/month-card-venue", "name": "month-card-venue", "meta": { "title": "权益生效门店", "headerType": 2 }, component: () => import(/* webpackChunkName: "month-card-venue" */'../views/month-card-venue/index') },

  { "path": "/myGift/invalid", "name": "myGift/invalid", "meta": { "title": "已失效礼品卡", "headerType": 5 }, component: () => import(/* webpackChunkName: "my" */'../views/myInvalidGift') },
  { "path": "/myGift/index", "name": "myGift/index", "meta": { "title": "我的卡包", "headerType": 5, "keepAlive": true }, component: () => import(/* webpackChunkName: "my" */'../views/myGift') },
  { "path": "/myGift/detail", "name": "myGift/detail", "meta": { "title": "礼品卡", "headerType": 5 }, component: () => import(/* webpackChunkName: "my" */'../views/myGiftDetail') },
  { "path": "/pointsMall/index", "name": "pointsMall/index", "meta": { "title": "鹿分商城", "headerType": 5, "keepAlive": true }, component: () => import(/* webpackChunkName: "pointsMall" */'../views/pointsMall') },
  { "path": "/pointsMall/detail", "name": "pointsMall/detail", "meta": { "title": "商品详情", "headerType": 5 }, component: () => import(/* webpackChunkName: "pointsMall" */'../views/pointsMallDetail') },
  { "path": "/ordinary_004001", "name": "ordinary_004001", "meta": { "title": "", "headerType": 4 }, component: () => import(/* webpackChunkName: "double11" */'../views/ordinary_004001') },
  { "path": "/ordinary_004002", "name": "ordinary_004002", "meta": { "title": "", "headerType": 4 }, component: () => import(/* webpackChunkName: "double11" */'../views/ordinary_004002') },
  { "path": "/ordinary_004004", "name": "ordinary_004004", "meta": { "title": "", "headerType": 4 }, component: () => import(/* webpackChunkName: "double11" */'../views/ordinary_004004') },
  { "path": "/ordinary_004005", "name": "ordinary_004005", "meta": { "title": "", "headerType": 4 }, component: () => import(/* webpackChunkName: "double11" */'../views/ordinary_004005') },

  { "path": "/superdeer-activity/pri-incentive-2021", "name": "pri-incentive-2021", "meta": { "title": "教练激励", "headerType": 2 }, component: () => import(/* webpackChunkName: "double12" */'../views/superdeer-activity/pri-incentive-2021') },
  { "path": "/superdeer-activity/pri-incentive-2021/list", "name": "pri-punching", "meta": { "title": "我的红包", "headerType": 2 }, component: () => import(/* webpackChunkName: "double12" */'../views/superdeer-activity/pri-incentive-2021/list') },


  { "path": "/enterprise/index", "name": "enterprise", "meta": { "title": "企业用户首页", "headerType": 2 }, component: () => import(/* webpackChunkName: "enterprise" */'../views/enterprise/index') },
  { "path": "/enterprise/admin", "name": "enterprise/admin", "meta": { "title": "企业账户管理", "headerType": 5, "keepAlive": true }, component: () => import(/* webpackChunkName: "enterprise" */'../views/enterprise/admin') },
  { "path": "/enterprise/staff-list", "name": "enterprise/staff-list", "meta": { "title": "选择参与人", "headerType": 5 }, component: () => import(/* webpackChunkName: "enterprise" */'../views/enterprise/staffList') },
  { "path": "/company-cooperation/index", "name": "company-cooperation", "meta": { "title": "", "headerType": 4 }, component: () => import(/* webpackChunkName: "company-cooperation" */'../views/company-cooperation/index') },
  { "path": "/company-cooperation/records", "name": "company-cooperation-records", "meta": { "title": "我的运动记录", "headerType": 4 }, component: () => import(/* webpackChunkName: "company-cooperation" */'../views/company-cooperation/records') },

  { "path": "/equipment-board", "name": "equipment-board", "meta": { "title": "设备报修看板" }, component: () => import(/* webpackChunkName: "equipment-board" */'../views/equipment-board') },
  // 旧训记
  {
    path: '/training-records/summary',
    name: 'training-records-summary',
    meta: { title: '私教训练报告', headerType: 4 },
    component: () => import(/* webpackChunkName: "training-records" */ '../views/training-records/summary'),
  },
  {
    path: '/training-records/list',
    name: 'training-records-list',
    meta: { title: '训练记录', headerType: 2 },
    component: () => import(/* webpackChunkName: "training-records" */ '../views/training-records/list'),
  },
  {
    path: '/training-records/statistics',
    name: 'training-records-statistics',
    meta: { title: '训练统计', headerType: 2 },
    component: () => import(/* webpackChunkName: "training-records" */ '../views/training-records/statistics'),
  },
  {
    path: '/training-records/detail',
    name: 'training-records-detail',
    meta: { title: '私教训练报告', headerType: 4 },
    component: () => import(/* webpackChunkName: "training-records" */ '../views/training-records/detail'),
  },
  {
    path: '/mini-records/index',
    name: 'mini-records1',
    meta: { title: '小班课训练报告', headerType: 4 },
    component: () => import(/* webpackChunkName: "mini-records" */ '../views/mini-records/index'),
  },
  {
    path: '/mini-records/share',
    name: 'mini-records2',
    meta: { title: '', headerType: 4 },
    component: () => import(/* webpackChunkName: "mini-records" */ '../views/mini-records/share'),
  },
  {
    path: '/mini-records/list',
    name: 'mini-records3',
    meta: { title: '小班课训练报告', headerType: 2 },
    component: () => import(/* webpackChunkName: "mini-records" */ '../views/mini-records/list'),
  },
  // 新训记
  { path: '/training-record/plan', name: 'training-record-plan', meta: { title: '训练计划', headerType: 5 }, component: () => import(/* webpackChunkName: "training-record" */ '../views/training-record/plan') },
  { path: '/training-record/template', name: 'training-record-template', meta: { title: '训练模板', headerType: 2 }, component: () => import(/* webpackChunkName: "training-record" */ '../views/training-record/template') },
  { path: '/training-record/motion', name: 'training-record-motion', meta: { title: '动作详情', headerType: 5 }, component: () => import(/* webpackChunkName: "training-record" */ '../views/training-record/motion') },
  { path: '/training-record/super-group', name: 'training-record-super-group', meta: { title: '超级组', headerType: 5 }, component: () => import(/* webpackChunkName: "training-record" */ '../views/training-record/super-group') },
  { path: '/training-record/course-motions', name: 'training-record-course-motions', meta: { title: '', headerType: 2 }, component: () => import(/* webpackChunkName: "training-record" */ '../views/training-record/course-motions') },
  { path: '/training-record/introduction', name: 'training-record-introduction', meta: { title: '训记功能介绍', headerType: 2 }, component: () => import(/* webpackChunkName: "training-record" */ '../views/training-record/introduction') },
  { path: '/training-record/introduction2', name: 'training-record-introduction2', meta: { title: '训记使用教程', headerType: 2 }, component: () => import(/* webpackChunkName: "training-record" */ '../views/training-record/introduction2') },
  { path: '/training-record/vip', name: 'training-record-vip', meta: { title: '', headerType: 5 }, component: () => import(/* webpackChunkName: "training-record" */ '../views/training-record/vip') },
  { path: '/training-record/mall', name: 'training-record-mall', meta: { title: '训记VIP商城', headerType: 5 }, component: () => import(/* webpackChunkName: "training-record" */ '../views/training-record/mall') },
  { path: '/training-record/vip-use-list', name: 'training-record-vip-use-list', meta: { title: '训记VIP开通记录', headerType: 5 }, component: () => import(/* webpackChunkName: "training-record" */ '../views/training-record/vip-use-list') },
  { path: '/training-record/article', name: 'training-record-article', meta: { title: '', headerType: 2 }, component: () => import(/* webpackChunkName: "training-record" */ '../views/training-record/article') },
  { path: '/training-record/community-qrcode', name: 'training-record-community-qrcode', meta: { title: '训记用户内测', headerType: 2 }, component: () => import(/* webpackChunkName: "training-record" */ '../views/training-record/community-qrcode') },
  { path: '/wx-scan/xunji-keyword', name: 'training-record-xunji-keyword', meta: { title: '关键词', headerType: 2 }, component: () => import(/* webpackChunkName: "training-record" */ '../views/wx-scan/xunji-keyword') },
  { path: '/training-record/first-training-award', name: 'training-record-first-training-award', meta: { title: '超鹿训记 首练得礼包', headerType: 4 }, component: () => import(/* webpackChunkName: "training-record" */ '../views/training-record/first-training-award') },

  { "path": "/start-page", "name": "start-page", "meta": { "title": "", "headerType": 4 }, component: () => import(/* webpackChunkName: "course-buy" */'../views/start-page.vue') },
  { "path": "/coach-mall", "name": "coach-mall", "meta": { "title": "教练商城", "headerType": 4 }, component: () => import(/* webpackChunkName: "course-buy" */'../views/coach-mall.vue') },
  { "path": "/normal-coach-mall", "name": "normal-coach-mall", "meta": { "title": "超鹿会员商城", "headerType": 2 }, component: () => import(/* webpackChunkName: "course-buy" */'../views/normal-coach-mall.vue') },
  // 用户拓展 24-2-28
  // { "path": "/user-expansion", "name": "user-expansion", "meta": { "title": "
  {
    "path": "/user-expansion/list",
    "name": "user-expansion",
    "meta": { "title": "用户拓展CRM", "headerType": 2 },
    component: () => import(/* webpackChunkName: "course-buy" */'../views/user-expansion/list.vue')
  },
  {
    "path": "/user-expansion/data",
    "name": "user-expansion-data",
    "meta": { "title": "", "headerType": 4 },
    component: () => import(/* webpackChunkName: "course-buy" */'../views/user-expansion/data.vue')
  },
  // 活动营销商城通用模板
  { "path": "/superdeer-activity/marketing", "name": "activity-marketing", "meta": { "title": "", "headerType": 4 }, component: () => import(/* webpackChunkName: "activity-marketing" */'../views/superdeer-activity/marketing/index') },
  // 2023.10.31 兼容方案 后面全部改成此方案
  { "path": "/superdeer-activity/marketing/index/:id", "name": "activity-marketing-id", "meta": { "title": "", "headerType": 4 }, component: () => import(/* webpackChunkName: "activity-marketing" */'../views/superdeer-activity/marketing/index') },
  // 活动营销推广页模板
  { "path": "/superdeer-activity/marketing/leaflet", "name": "activity-marketing-leaflet", "meta": { "title": "超鹿运动", "headerType": 2 }, component: () => import(/* webpackChunkName: "activity-marketing" */'../views/superdeer-activity/marketing/leaflet') },
  { "path": "/superdeer-activity/marketing/shoppingcart-settlement", "name": "shoppingcart-settlement", "meta": { "title": "组合购买", "headerType": 2 }, component: () => import(/* webpackChunkName: "activity-marketing" */'../views/superdeer-activity/marketing/shoppingcart-settlement') },

  { "path": "/mini-team/ranking-list", "name": "mini-team", "meta": { "title": "燃脂塑形小班课·战队榜", "headerType": 5 }, component: () => import(/* webpackChunkName: "mini-team" */'../views/mini-team/ranking-list.vue') },
  { "path": "/mini-team/team-detail", "name": "mini-team-detail", "meta": { "title": "战队详情", "headerType": 4 }, component: () => import(/* webpackChunkName: "mini-team" */'../views/mini-team/team-detail.vue') },
  { "path": "/mini-team/my-team", "name": "mini-team-myteam", "meta": { "title": "我的战队", "headerType": 4 }, component: () => import(/* webpackChunkName: "mini-team" */'../views/mini-team/my-team.vue') },
  // 谈单系统
  { "path": "/talk-order/questionnaire", "name": "talk-order", "meta": { "title": "日常情况评估", "headerType": 2 }, component: () => import(/* webpackChunkName: "talk-order" */'../views/talk-order/questionnaire') },
  { "path": "/talk-order/case", "name": "talk-order-case", "meta": { "title": "运动方案", "headerType": 2 }, component: () => import(/* webpackChunkName: "talk-order" */'../views/talk-order/case') },
  { "path": "/talk-order/list", "name": "talk-order-list", "meta": { "title": "运动方案", "headerType": 2 }, component: () => import(/* webpackChunkName: "talk-order" */'../views/talk-order/list') },
  // 会员案例
  { "path": "/user-case", "name": "user-case", "meta": { "title": "会员案例", "headerType": 2 }, component: () => import(/* webpackChunkName: "user-case" */'../views/user-case/user-case') },
  { "path": "/user-case-detail", "name": "user-case-deatail", "meta": { "title": "案例详情", "headerType": 2 }, component: () => import(/* webpackChunkName: "user-case" */'../views/user-case/user-case-detail') },
]
module.exports = routes
