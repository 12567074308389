<template>
  <div>
    <!-- 沉浸式头部 -->
    <nav-bar :header-title="AI.activityName" backColor="#fff" :show-back="true" :header-back="newAppBack"
      @setBarHeight="setBarHeight" />
    <!-- 预告 -->
    <div v-if="AI.activityTrailer" class="marketing-preview col-start-center">
      <img :src="item" v-for="item in AI.activityTrailer.imgList" :key="item" style="width: 100%" alt="" />
      <van-count-down v-if="AI.activityTrailer.previewTime" style="opacity: 0" @finish="previewFinish"
        :time="AI.activityTrailer.previewTime" />
    </div>
    <!-- 主会场 -->
    <div v-else class="marketing-template-2023 col-start-center"
      :style="`background-color: ${AI.shopStyle.backgroundColor};`">
      <div class="background">
        <!-- 主视觉 -->
        <img :src="AI.shopStyle.headerMainImg" class="head-img" />
        <div class="top-button">
          <!-- 分享按钮 -->
          <template v-if="AI.shopStyle.shareStatus">
            <template v-if="AI.shopStyle.shareIconImg">
              <img class="icon" :src="AI.shopStyle.shareIconImg" @click="showShare" alt="" />
            </template>
            <template v-else>
              <div class="button f24" :style="`background: ${AI.shopStyle.shareIconColor}`" @click="showShare">分享</div>
            </template>
          </template>
          <!-- 规则按钮图标 -->
          <template v-if="AI.shopStyle.ruleStatus">
            <template v-if="AI.shopStyle.ruleIconImg">
              <img class="icon" :src="AI.shopStyle.ruleIconImg" @click="openRule(true)" alt="" />
            </template>
            <template v-else>
              <div class="button f24" :style="`background: ${AI.shopStyle.ruleIconColor}`" @click="openRule(true)">规则
              </div>
            </template>
          </template>
        </div>
        <!-- 右上角的悬浮按钮 -->
        <img v-if="AI.shopStyle.floatStatus" @click="toPath(AI.shopStyle.floatLink)" :src="AI.shopStyle.floatImg" alt=""
          class="float-icon" />
      </div>

      <!-- 倒计时 -->
      <count-down v-if="AI.shopStyle.headerCountdown" :timeObj="AI" :status="activityStatus"
        @timeFinish="timeFinish"></count-down>

      <!-- 带货人 信息 -->
      <div class="invite-block row-start-center f22"
        v-if="AI.shopStyle.inviterStatus && (inviteUserInfo.shareTeacherId || inviteUserInfo.shareUserId)">
        <div class="invite-head"><img :src="`${inviteUserInfo.shareUserHeadImg}`" alt="" /></div>
        <div class="invite-name ellipsis" :style="`color: ${AI.shopStyle.subfieldNameColor};`">
          <span>{{ inviteUserInfo.shareTitle }}</span> 邀请你参与{{ AI.activityName }}
        </div>
      </div>

      <!-- banner -->
      <div class="banner-block row-between-center" v-for="(banner, index) in AI.bannerList" :key="index">
        <img @click="toPath(item.url)" :src="item.imgUrl" alt=""
          :class="['small-banner', banner.bannerList.length > 1 ? '' : 'flex-auto']" v-for="item in banner.bannerList"
          :key="item.imgUrl" />
      </div>

      <!-- 秒杀区 -->
      <div class="kill-block"></div>
      <!-- 分区标题图 -->
      <img v-if="AI.shopStyle.partitionTitleImg" :src="AI.shopStyle.partitionTitleImg" alt="" class="nav-title" />
      <!-- 商品导航 -->
      <van-sticky v-if="AI.partitions.subfieldsInfos && AI.partitions.subfieldsInfos.length > 1" style="width: 100%"
        :offset-top="barHeight">
        <div class="nav" ref="nav" :style="`background-color: ${AI.shopStyle.backgroundColor};`">
          <div class="nav-top row-between-center flex-wrap">
            <div
              :class="['tm', 'col-center-start', AI.partitions.subfieldsInfos.length % 3 === 0 || (AI.partitions.subfieldsInfos.length === 5 && index > 1) ? 'small' : '', tabIndex === index ? 'active' : '']"
              :style="`background: linear-gradient(180deg, ${tabIndex === index ? AI.shopStyle.clickSubfieldMenuStyle.bgTopColor : AI.shopStyle.defaultSubfieldMenuStyle.bgTopColor} 0%, ${tabIndex === index ? AI.shopStyle.clickSubfieldMenuStyle.bgBottomColor : AI.shopStyle.defaultSubfieldMenuStyle.bgBottomColor
                } 100%);
                  color: ${tabIndex === index ? AI.shopStyle.clickSubfieldMenuStyle.textColor : AI.shopStyle.defaultSubfieldMenuStyle.textColor}`"
              v-for="(item, index) in AI.partitions.subfieldsInfos" :key="index" @click="setTabIndex(index)">
              <div class="fw6 f28">{{ item.menuName }}</div>
              <div class="ii f24">{{ item.light }}</div>
            </div>
          </div>
        </div>
      </van-sticky>

      <div class="template-content col-start-center">
        <div v-if="AI.partitions.subfieldsInfos && AI.partitions.subfieldsInfos.length > 1"
          class="title-line row-between-center f20" :style="`color: ${AI.shopStyle.subfieldNameColor};`">
          <p class="subfield-name fy f36">{{ curGoods.subfieldName }}</p>
          <div class="row-start-center" @click="openRule(false)">
            <span class="ss">规则</span>
            <span class="f22"><van-icon name="arrow" /></span>
          </div>
        </div>

        <!-- 分栏 菜单  -->
        <van-sticky v-if="curGoods.showGroupMenu && curGoods.groupInfos.length > 1" :offset-top="barHeight + navHeight">
          <div class="group-bar row-start-center" :style="`background-color: ${AI.shopStyle.backgroundColor};`">
            <template v-for="(item, index) in curGoods.groupInfos">
              <div v-show="item.show"
                :class="['item', 'row-center-center', 'f28', 'fw6', curGoods.groupInfos.length > 5 ? 'flex-none' : 'flex-auto', groupIndex == index ? 'active' : '']"
                @click="toGroup(`group${item.id}`, index)" :ref="`item${item.id}`" :key="index">
                {{ item.groupMenuName }}
              </div>
            </template>
          </div>
        </van-sticky>

        <div class="good-block">
          <!-- 分组上部广告位 -->
          <div class="ad-group col-start-center"
            v-if="curGoods.promotionalInfo.status && curGoods.promotionalInfo.topAds">
            <img :src="item.imgUrl" @click="toAppPath(item.url)" v-for="item in curGoods.promotionalInfo.topAds"
              :key="item.imgUrl" alt="" class="ad-item" />
          </div>
          <template v-for="group in curGoods.groupInfos">
            <div :key="group.id" :ref="`group${group.id}`">
              <div v-if="group.goodsInfos.length && group.show"
                :class="['good-group', 'flex-wrap', curGoods.groupInfos.length > 1 && group.groupName ? 'has-title' : '', group.onlyImgMode ? 'only-img row-start-start' : 'row-between-start']"
                :key="group.id">
                <!-- 分组的标题 -->
                <div v-if="curGoods.groupInfos.length > 1" class="group-name f30 fw6 row-start-center"
                  :style="`background-image: url(${AI.shopStyle.groupTitleImg});`">{{ group.groupName }}</div>
                <!-- 一行一个 -->
                <template v-if="group.listStyle === '1' && !group.onlyImgMode">
                  <style-one-card v-show="showWithCrow(good)" :cardObj="good" :shopStyle="AI.shopStyle"
                    :status="goodsStatusMap[good.id]" :priceStatus="goodsPriceStatusMap[good.id]"
                    :activityStatus="activityStatus" v-for="good in group.goodsInfos" :key="good.id"
                    @buyCard="buyCard(good)"></style-one-card>
                </template>
                <template v-if="group.listStyle === '1' && group.onlyImgMode">
                  <img v-show="showWithCrow(good)" class="one-img"
                    :src="goodsStatusMap[good.id] === 'NOT_STARTED' ? good.cardImgIneffective : good.cardImgEffective"
                    v-for="good in group.goodsInfos" :key="good.id" @click="buyCard(good)" alt="" />
                </template>

                <!-- 一行俩 -->
                <template v-if="group.listStyle === '2' && !group.onlyImgMode">
                  <style-two-card v-show="showWithCrow(good)" :cardObj="good" :shopStyle="AI.shopStyle"
                    :status="goodsStatusMap[good.id]" :priceStatus="goodsPriceStatusMap[good.id]"
                    :activityStatus="activityStatus" v-for="good in group.goodsInfos" :key="good.id"
                    @buyCard="buyCard(good)"></style-two-card>
                </template>
                <template v-if="group.listStyle === '2' && group.onlyImgMode">
                  <img v-show="showWithCrow(good)" class="two-img flex-none"
                    :src="goodsStatusMap[good.id] === 'NOT_STARTED' ? good.cardImgIneffective : good.cardImgEffective"
                    v-for="good in group.goodsInfos" :key="good.id" @click="buyCard(good)" alt="" />
                </template>
                <!-- 一行仨 -->
                <template v-if="group.listStyle === '3' && !group.onlyImgMode">
                  <style-three-card v-show="showWithCrow(good)" :cardObj="good" :shopStyle="AI.shopStyle"
                    :status="goodsStatusMap[good.id]" :priceStatus="goodsPriceStatusMap[good.id]"
                    :activityStatus="activityStatus" v-for="good in group.goodsInfos" :key="good.id"
                    @buyCard="buyCard(good)"></style-three-card>
                </template>
                <template v-if="group.listStyle === '3' && group.onlyImgMode">
                  <img v-show="showWithCrow(good)" class="three-img flex-none"
                    :src="goodsStatusMap[good.id] === 'NOT_STARTED' ? good.cardImgIneffective : good.cardImgEffective"
                    v-for="good in group.goodsInfos" :key="good.id" @click="buyCard(good)" alt="" />
                </template>
              </div>
            </div>
          </template>

          <!-- 分组下部广告位 -->
          <div class="ad-group col-start-center"
            v-if="curGoods.promotionalInfo.status && curGoods.promotionalInfo.bottomAds">
            <img :src="item.imgUrl" @click="toAppPath(item.url)" v-for="item in curGoods.promotionalInfo.bottomAds"
              :key="item.imgUrl" alt="" class="ad-item" />
          </div>
        </div>

        <div class="end f22">已经滑到底部啦</div>
      </div>
      <!-- 规则 弹窗 -->
      <van-popup v-model="isRuleShow" position="bottom" safe-area-inset-bottom class="pop-rule">
        <div class="pop-rule-title f32 fw6"
          :style="`background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, ${hexToRgba(AI.shopStyle.backgroundColor, 0.5)} 100%);`">
          活动规则
          <van-icon name="cross" class="pop-rule-close f28" color="#fff" @click="isRuleShow = false" />
        </div>
        <div class="pop-rule-content f26">
          {{ curRule }}
        </div>
      </van-popup>

      <template v-if="inviteUserInfo.shareTitle">
        <!-- 新人评价完推荐、签到推荐 的商品 -->
        <sign-in-recommended v-if="isSignInRecommended" :inviteUserInfo="inviteUserInfo" @buyCard="buyCard"
          @close="recommendedClose"></sign-in-recommended>
        <!-- 别人推荐的商品 -->
        <recommended-goods v-else :inviteUserInfo="inviteUserInfo" @buyCard="buyCard"
          @close="recommendedClose"></recommended-goods>
      </template>

      <!-- 规格选择 弹窗 -->
      <van-popup v-model="isItemSelectShow" @click-overlay="close" position="bottom" safe-area-inset-bottom
        class="pop-item">
        <div class="buy-title f32 fw6">
          确认下单
          <van-icon name="cross" class="buy-close f36" @click="close" />
        </div>
        <template v-if="isItemSelectShow">
          <div class="cbox">
            <div class="f28 fw6">购买商品</div>
            <div class="goods-line">
              <div class="goods-img">
                <img :src="buyGoods.goodsImg" />
              </div>
              <div class="goods-info">
                <div class="goods-name f34 fw6">{{ buyGoods.goodsName }}</div>
                <div class="time f22" v-if="buyGoods.hint">{{ buyGoods.hint }}</div>
                <div class="labels row-start-center" v-if="buyGoods.productTags.length">
                  <div class="label row-center-center f20 fw6" v-for="(item, index) in buyGoods.productTags"
                    :key="index">{{ item }}</div>
                </div>
                <div class="price-line row-start-end">
                  <span class="dl f22 fw6">¥</span>
                  <span class="price f32 fw6">{{ itemIndex === undefined ? buyGoods.salePrice :
                    buyGoods.itemInfos[itemIndex].salePrice }}</span>
                  <span class="ori-price f20">¥{{ itemIndex === undefined ? buyGoods.originPrice :
                    buyGoods.itemInfos[itemIndex].originPrice }}</span>
                </div>
              </div>
            </div>
            <template v-if="itemList.length > 1">
              <div class="f28 fw6">{{ buyGoods.goodsItemExp }}</div>
              <div class="buy-tips f24">{{ buyGoods.notice }}</div>
              <div class="city-list">
                <div v-for="(item, index) in itemList" :key="index"
                  :class="['city-model', 'row-between-center', 'fw6', itemIndex === index ? 'active' : '', itemStatusMap[item.id] === 'CAN_BUY' ? '' : 'disabled']"
                  @click="chooseItem(index, itemStatusMap[item.id], item)">
                  <p>
                    {{ item.itemName }}&nbsp;&nbsp;<span v-if="itemPriceStatusMap[item.id].showAfterVoucher"
                      class="red f22 fw6">券后价</span> <span class="red f24 fw6">¥</span><span class="red">{{
                        itemPriceStatusMap[item.id].discountPrice === undefined ? item.salePrice :
                          itemPriceStatusMap[item.id].discountPrice }}</span>
                  </p>
                  <div v-if="itemStatusMap[item.id] === 'CAN_BUY'" class="checkbox"></div>
                  <img v-else src="https://img.chaolu.com.cn/ACT/marketing/soldout.png" alt="" class="has-buy" />
                </div>
              </div>
            </template>
            <add-address v-if="buyGoods.goodsType === 22" @change="addressChange" color="#FFDE00"></add-address>
          </div>
          <div
            :class="['next', 'fw6', itemIndex !== undefined || (buyGoods.goodsType === 22 && buyGoods.itemInfos.length === 1) ? '' : 'grey']"
            @click="whetherSingleVenue">
            立即购买 {{ itemIndex === undefined ? '' : `￥${(itemPriceStatusMap[buyGoods.itemInfos[itemIndex].id] &&
              itemPriceStatusMap[buyGoods.itemInfos[itemIndex].id].discountPrice !== undefined) ?
              itemPriceStatusMap[buyGoods.itemInfos[itemIndex].id].discountPrice :
              buyGoods.itemInfos[itemIndex].salePrice}` }}
          </div>
        </template>
      </van-popup>
      <!-- 规格选择 确认 弹窗 -->
      <van-popup v-model="isItemConfirmShow" position="center" class="pop-sure">
        <template v-if="isItemConfirmShow">
          <div class="sure-title f36 fw6">提示</div>

          <div v-if="['4123', '4124', '4125'].includes(buyItem.productId)" class="sure-content f28">
            您当前购买的{{ buyGoods.subfieldName || curGoods.subfieldName }}为
            <span>{{ buyItem.itemName || buyItem.productName }}</span>
            ，请在1.29-2.4内激活卡片使用，有效期7天，到期未激活即算失效，不可提现，不可退款，不可转赠，请谨慎下单！
          </div>
          <div v-else class="sure-content f28">
            您当前购买的{{ buyGoods.subfieldName || curGoods.subfieldName }}为
            <span>{{ buyItem.itemName || buyItem.productName }}</span>
            <span v-show="buyItem.extendInfo.perOnlyVenue">（仅限{{ perVenueName }}使用）</span>
            ，请确认后再付款！
          </div>

          <p v-show="isZhimaxianxiang" class="red sure-tip f24">注：本次购买的超鹿月卡将立即生效</p>
          <div class="sure-sub f32 fw6" @click="checkHasExistCard">我知道了</div>
        </template>
      </van-popup>

      <!-- 连续包月提示窗 -->
      <van-popup v-model="isContinuousShow" :overlay="true" style="background-color: transparent"
        :close-on-click-overlay="false">
        <div v-if="isContinuousShow" class="pay-box big no-bg">
          <img class="auto-img" :src="buyItem.extendInfo.autoRenewPriceImgUrl" alt="" />
          <div class="pay-share-btn row-between-center">
            <div class="row-center-center grey-btn" @click="isContinuousShow = false">取消支付</div>
            <div class="row-center-center fw6" @click="
              isRenewPayTypeShow = true
            isContinuousShow = false
              ">
              开通并购买
            </div>
          </div>
        </div>
      </van-popup>
      <!-- 组合购买弹窗 -->
      <van-popup v-model="isCombinedBuyShow" :overlay="true" style="background-color: transparent"
        :close-on-click-overlay="false">
        <div v-if="isCombinedBuyShow" class="error-box">
          <h4 class="f36 fw6">确认购买</h4>
          <div class="f26 mt32 pd16">
            您购买的<span>{{ buyItem.productName }}</span>包含<span>【{{ buyItem.extendInfo.packageContainsGoodsName
              }}】</span>，是否确认购买？
          </div>
          <div class="pay-share-btn row-between-center">
            <div class="row-center-center grey-btn" @click="isCombinedBuyShow = false">取消</div>
            <div class="row-center-center fw6" @click="payAction">继续购买
            </div>
          </div>
        </div>
      </van-popup>

      <!-- 连续包月选择支付方式弹窗 -->
      <van-popup v-model="isRenewPayTypeShow" :safe-area-inset-bottom="true" :overlay="true" position="bottom"
        :close-on-click-overlay="false" :lock-scroll="true" :closeable="true">
        <div class="renew-paytype-box">
          <div class="pay-type f24 fw6">选择支付方式</div>
          <!--支付宝支付-->
          <!-- <div v-if="appTypeStr !== 'mini'" @click="renewPayType = 2" class="alipay row-between-center" :class="{ 'active': renewPayType === 2 }">
          <div class="row-start-center left"><img src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/cash-coupon/alipay.png" alt="">支付宝支付
          </div>
        </div> -->
          <div @click="renewPayType = 1" class="wechat row-between-center" :class="{ active: renewPayType === 1 }">
            <div class="row-start-center left">
              <img src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/cash-coupon/wechatpay.png" alt="" />
              微信支付
            </div>
          </div>
          <div @click="checkHasExistCard" class="pay-btn row-center-center f32 fw6">确定</div>
        </div>
      </van-popup>
      <payPopupCard :showPayPopup.sync="showPayPopup" :pay-data="payData" :pay-url="payUrl"></payPopupCard>
      <!-- 已有生效中的卡  却要买连续包月和芝麻先享 的提示 -->
      <van-popup v-model="isHasActivedCardShow" :overlay="true" style="background-color: transparent"
        :close-on-click-overlay="false">
        <div class="error-box">
          <h4 class="f36 fw6">提示</h4>
          <p class="f28" v-html="hasActivedTip"></p>
          <div class="pay-share-btn row-between-center">
            <div class="row-center-center grey-btn" @click="close">取消</div>
            <div class="row-center-center fw6" @click="payAction">继续购买</div>
          </div>
        </div>
      </van-popup>

      <!-- 海报 组件 -->
      <common-share :value="isShareShow" :shareParams="shareParams" @close="isShareShow = false"
        @share-success="isShareShow = false" :shareItem="['posterfriend', 'postermessage', 'save']"
        :miniSharePoster="appTypeStr === 'mini'">
        <template slot="createDom">
          <!-- <div class="canvascss">
            <img :src="AI.shareImg" class="canvascss_bg" />
            <div class="canvascss_left">
              <img :src="userDetail.headImg" />
            </div>
            <div class="canvascss_name f26 fw6 ellipsis" :style="`color: ${AI.nickNameHex};`">
              {{ userDetail.nickName }}
            </div>
            <div class="canvascss_right">
              <img v-if="userDetail.qrCode" :src="`data:image/png;base64,${userDetail.qrCode}`" />
            </div>
          </div> -->
          <div class="canvascss" v-if="shopId >= 10120">
            <img :src="AI.shareImg" class="canvascss_bg" />
            <div class="canvascss_code">
              <img v-if="userDetail.qrCode" :src="`data:image/png;base64,${userDetail.qrCode}`" />
              <div class="canvascss_code_text">扫码领取福利</div>
            </div>
            <div class="canvascss_info">
              <img :src="userDetail.headImg" class="canvascss_head" />
              <div class="canvascss_full">
                <div class="canvascss_name ellipsis-2" :style="`color: ${AI.nickNameHex};`">
                  {{ userDetail.nickName }}
                </div>
              </div>
            </div>
          </div>
          <div class="canvascss2" v-else>
            <img :src="AI.shareImg" class="canvascss_bg" />
            <div class="canvascss_left">
              <img :src="userDetail.headImg" />
            </div>
            <div class="canvascss_name f26 fw6 ellipsis" :style="`color: ${AI.nickNameHex};`">
              {{ userDetail.nickName }}
            </div>
            <div class="canvascss_right">
              <img v-if="userDetail.qrCode" :src="`data:image/png;base64,${userDetail.qrCode}`" />
            </div>

          </div>
        </template>
      </common-share>

      <!-- 门店选择 -->
      <equity-venue :current-city-id="buyItem.extendInfo && buyItem.extendInfo.applyCityIds"
        :popShow.sync="venueSelectShow" :can-use-venue="buyItem.extendInfo && buyItem.extendInfo.applyVenueIds"
        @popfun="choseStorePop" :chose-item="{ areaId: '', venueId: [] }"> </equity-venue>

      <!-- 升级提示 -->
      <van-popup v-model="isUpgradeShow" closeable :overlay="true" style="background-color: transparent; width: 84vw">
        <div class="error-box">
          <h4 class="f36 fw6">更新提示</h4>
          <p class="f28">发现新版本，新功能需要升级至最新版本。</p>
          <div @click="upgrade" class="btn f32 fw6 row-center-center">去升级</div>
        </div>
      </van-popup>
    </div>
  </div>
</template>
<script>
import { newAppBack, initBack, appPay, appPaySuccess, appOpenWeb, silentAppPay, upgrade, getAppVersion } from '@/lib/appMethod'
import { compareVersion, getRedString } from '@/lib/utils'

import navBar from '@/components/nav-bar/nav-bar'
import commonShare from '@/components/commonShare/newShare'
import styleOneCard from './components/style-one-card'
import styleTwoCard from './components/style-two-card'
import styleThreeCard from './components/style-three-card'
import countDown from './components/count-down'
import recommendedGoods from './components/recommended-goods'
import signInRecommended from './components/sign-in-recommended'
import payPopupCard from '@/components/pay-popup-card'
import addAddress from './components/address'
import equityVenue from '@/components/cl-city-venue/equity-venue'

import userMixin from '@/mixin/userMixin'
import headerMixin from '@/mixin/headerMixin'

import jump from './jump'

import wx from 'weixin-js-sdk'

const miniPath = `/pages/webView/index?webUrl=${window.location.origin}/#/superdeer-activity/marketing`
const webPath = `${window.location.origin}/#/superdeer-activity/marketing`

export default {
  components: {
    navBar,
    commonShare,
    styleOneCard,
    styleTwoCard,
    styleThreeCard,
    countDown,
    recommendedGoods,
    signInRecommended,
    payPopupCard,
    addAddress,
    equityVenue,
  },
  mixins: [userMixin, headerMixin],
  data() {
    return {
      shopId: '', // 活动id
      AI: {
        // 活动总信息
        activityTrailer: {}, // 预告页
        partitions: {},
        shopStyle: {},
      },

      barHeight: 0,
      navHeight: 0,

      tabIndex: 0, // 分栏的下标
      groupIndex: 0, // 分组的下标
      shareParams: {
        title: '月中健身 福利大放送',
        miniImage: 'https://img.chaolu.com.cn/ACT/temp-activity/student-mall/main_mini.png',
        path: '',
        miniProgramType: window.location.origin.indexOf('web.chaolu.com') > -1 ? '0' : '2', // 正式0，开发1，体验2
        url: 'url',
        multiple: '0.95',
        userName: 'gh_7f5b57b6a76e',
      },
      userDetail: {}, //分享的用户信息
      inviteUserInfo: {}, // 邀请人信息

      isShareShow: false,
      isRuleShow: false,
      isItemSelectShow: false, // 规格选择弹窗
      address: {}, // 实体卡情况下 收货地址
      isItemConfirmShow: false, // 规格确认弹窗
      itemIndex: 0, // 规格下标
      venueSelectShow: false, // 门店的弹窗
      isContinuousShow: false, // 连续包月弹窗
      isZhimaxianxiang: false, // 是否芝麻先享
      isRenewPayTypeShow: false, // 连续包月支付弹窗
      isCombinedBuyShow: false, // 组合购买提示弹窗
      renewPayType: 1,
      isUpgradeShow: false, // 升级提示

      buyGoods: {}, // 当前购买的商品 包含多个规格
      buyItem: {}, // 当前购买的规格
      showPayPopup: false, // 权益卡选择优惠券弹窗
      payData: {}, // 支付参数
      payUrl: '', // 支付回跳链接

      activityStatus: '', // 活动状态
      goodsStatusMap: {}, // 商品对应状态
      goodsPriceStatusMap: {}, // 商品对应的价格状态
      itemStatusMap: {}, // 当前商品下多规格状态
      itemPriceStatusMap: {}, // 当前商品下多规格的价格状态
      curRule: '', // 当前规则 可能是总规则 可能是菜单规则

      topArr: [], // 若存在分组 各个分组的offset集合
      isClickScroll: false,

      isHasActivedCardShow: false, // 已有生效中的卡
      hasActivedTip: '', //
      userExpansionSourceId: '',//获客来源--用拓crm
    }
  },
  computed: {
    // 当前菜单下的商品 tab
    curGoods() {
      let c = this.AI.partitions.subfieldsInfos ? this.AI.partitions.subfieldsInfos[this.tabIndex] : { groupInfos: [], promotionalInfo: {} }
      // 处理 卡片上 标签问题 逗号隔开的字符改为 数组
      c.groupInfos.map((group) => group.goodsInfos.map((good) => (good.productTags = Array.isArray(good.productTags) ? good.productTags : good.productTags ? good.productTags.split(',') : [])))
      return c
    },
    // 多规格情况下 规格的list
    itemList() {
      return this.buyGoods.itemInfos ? this.buyGoods.itemInfos : []
    },
    // 根据人群、所在城市 判断是否显示
    showWithCrow() {
      return (good) => {
        // 没有showWithCrow或者有的情况下状态可购买时显示
        return !good.showWithCrow || (good.showWithCrow && this.goodsStatusMap[good.id] === 'CAN_BUY')
      }
    },
  },
  async created() {
    initBack()
    await this.$getAllInfo(['userId', 'cityId'])

    const q = this.$route.query
    this.countPoint('405', q.id, '40039', this.userId)
    // 存在推荐商品 需要强制登录
    if (q.goodsId && !this.userId) {
      this.$store.commit('openLoginPopup', true)
      return
    }

    // 若是教练邀请 获取教练id
    this.inviteTeacherId = q.teacherId || q.inviteTeacherId
    this.inviteUserId = q.inviteUserId
    this.userExpansionSourceId = q.userExpansionSourceId

    // 来源
    this.from = q.from
    this.queryCityId = q.shopCityId || this.cityId // 区别于mixin里的cityId
    // 本次商城的id
    this.shopId = q.id

    // 分享的链接
    this.shareParams.path = `${miniPath}&id=${this.shopId}`

    // 推荐弹窗  签到或评价会弹的
    this.isSignInRecommended = q.reason ? true : false

    // 获取活动总信息
    const r = await this.getActivity()
    if (r === '0') return

    // 海报的二维码
    if (this.userId) this.initQr()

    // 获取绑定关系
    this.getBindInfo()

    // 小程序卡片分享
    if (this.AI.shopStyle.shareStatus) {
      this.miniShare()
    }

    // 支付成功回调 小程序购买成功提示
    appPaySuccess().then(() => {
      this.paySuccessCallback()
    })
  },
  mounted() {
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible' && !this.AI.activityTrailer) {
        setTimeout(() => {
          this.getGoodStatus()
        }, 500)
      }
    })
  },
  methods: {
    newAppBack,
    upgrade,
    previewFinish() {
      this.getActivity()
    },
    setBarHeight(h) {
      this.barHeight = h || 0
    },
    setTabIndex(v) {
      this.tabIndex = v
      this.getGoodStatus()
      // 是否有滚动
      this.setScroll()
    },
    toPath(path) {
      const p = path.split('?')[0]
      let p1 = path.split('?')[1] ? `&${path.split('?')[1]}` : ''

      if (this.inviteTeacherId) {
        p1 = `${p1}&inviteTeacherId=${this.inviteTeacherId}`
        path = `${path}&inviteTeacherId=${this.inviteTeacherId}`
      }
      if (this.inviteUserId) {
        p1 = `${p1}&inviteUserId=${this.inviteUserId}`
        path = `${path}&inviteUserId=${this.inviteUserId}`
      }

      const hasDomain = p.indexOf('http') > -1
      if (this.appTypeStr === 'mini') {
        wx.miniProgram.navigateTo({
          url: `/pages/webView/index?webUrl=${hasDomain ? `${p}${p1}` : window.location.origin + '/#' + `${p}${p1}`}`,
        })
      }
      if (this.appTypeStr === 'and') {
        App.intentToActivity(
          JSON.stringify({
            className: '.ui.AppWebViewLaunchStandardActivity',
            data: [
              {
                key: 'url',
                value: path,
              },
              {
                key: 'removeTitle',
                value: '1',
              },
            ],
          })
        )
      }
      if (this.appTypeStr === 'ios') {
        hasDomain ? appOpenWeb('', path) : this.$router.push(p)
      }
    },
    toAppPath(obj) {
      if (!obj) return
      if (obj.type === '21') {
        if (this.inviteTeacherId) obj.extendJson.url = `${obj.extendJson.url}&inviteTeacherId=${this.inviteTeacherId}`
        if (this.inviteUserId) obj.extendJson.url = `${obj.extendJson.url}&inviteUserId=${this.inviteUserId}`
      }
      jump(obj)
    },
    async openItemSelect() {
      await this.getItemStatus()

      this.itemIndex = this.buyGoods.itemInfos.length > 1 ? undefined : 0
      this.isItemSelectShow = true
    },

    chooseItem(i, status, item) {
      if (status !== 'CAN_BUY') return
      this.itemIndex = i
      this.buyItem = item
    },

    checkCanBuy() {
      this.$toast.loading({
        duration: 0,
        forbidClick: true,
        loadingType: 'spinner',
      })
      return this.$axios
        .post(this.baseURLApp + '/activityShopOrder/checkBuy', {
          goodsId: this.buyGoods.id,
          goodsItemId: this.buyItem.id,
          shopId: this.shopId,
          userId: this.userId,
        })
        .then((r) => {
          this.$toast.clear()
          return r.data
        })
        .catch(() => {
          this.$toast.clear()
          return 0
        })
    },
    async buyCard(item) {
      // 点击购买 基础校验
      // 未登录
      if (!this.userId) {
        this.$store.commit('openLoginPopup', true)
        return
      }
      // item可能来自推荐弹窗组件item.isRecommended:true
      // 推荐的商品可能不是当前分组下的商品 所以goodsStatusMap无法使用
      this.buyGoods = item
      this.buyItem = item.itemInfos[this.itemIndex]
      this.buyItem.extendInfo = this.buyItem.extendInfo ? this.buyItem.extendInfo : {}

      if (this.activityStatus != 'in') {
        this.$toast('活动还没开始哦~')
        return
      }

      // 低版本 校验 （芝麻先享、自动续费）
      const version = await getAppVersion()
      if (['ALI_SAFE_PAY', 'AUTO_RENEW'].includes(this.buyItem.extendInfo.payType) && compareVersion(version, '4.34.10') < 0) {
        this.isUpgradeShow = true
        return
      }
      // 低版本 校验 （vip自助卡）
      if (this.buyGoods.goodsType === 27 && compareVersion(version, '4.51.10') < 0) {
        this.isUpgradeShow = true
        return
      }

      // 如果由推荐商品的弹窗触发的 必然是能购买的
      if (!item.isRecommended && this.goodsStatusMap[item.id] !== 'CAN_BUY') {
        this.$toast('不符合购买条件')
        return
      }

      // 支付前校验
      const res = await this.checkCanBuy()
      if (!res) {
        this.$toast('无法购买')
        return
      }

      this.whetherMoreItem()
    },
    whetherMoreItem() {
      // 是否多规格
      if (this.buyGoods.itemInfos.length > 1 || [22].includes(this.buyGoods.goodsType)) {
        this.openItemSelect()
        return
      }
      this.whetherSingleVenue()
    },
    whetherSingleVenue() {
      // 是否单门店
      if (this.buyItem.extendInfo.perOnlyVenue) {
        this.venueSelectShow = true
        return
      }
      this.perUseVenueId = ''
      this.perVenueName = ''
      this.buyWhat()
    },
    async buyWhat() {
      // 实体卡 目前只接了邮寄的方式 到店领取的没弄
      if (this.buyGoods.goodsType === 22 && !this.address.userAddressId) {
        this.$toast('请填写邮寄地址')
        return
      }

      if (this.itemIndex === undefined) return

      // 芝麻先享 小程序内 特殊情况
      // if (this.buyItem.extendInfo.payType === 'ALI_SAFE_PAY' && this.appTypeStr === 'mini') {
      //   this.checkHasExistCard()
      //   return
      // }

      // 连续包月
      if (this.buyItem.extendInfo.payType === 'AUTO_RENEW') {
        this.isContinuousShow = true
        return
      }
      // 芝麻先享
      if (this.buyItem.extendInfo.payType === 'ALI_SAFE_PAY') {
        this.isZhimaxianxiang = true
      }

      // 权益卡
      if ([24, 25].includes(this.buyGoods.goodsType) || this.buyItem.extendInfo.payType === 'ALI_SAFE_PAY') {
        this.isItemConfirmShow = true
        return
      }
      if ([30].includes(this.buyGoods.goodsType)) {
        this.isCombinedBuyShow = true
        return
      }

      this.payAction()
    },

    async payAction() {

      const payData = this.getPayParams()
      // 如果是教练分享单个商品的情况 支付完回到页面还是只能看到单商品的弹窗 因此回调链接加上goodsId
      const goodsId = this.buyGoods.isRecommended ? this.buyGoods.id : ''
      const url = `${this.shareParams.path}&userId=1&goodsId=${goodsId}&shopCityId=${this.queryCityId}`

      // 判断是否芝麻先享 或者 周期付
      switch (this.buyItem.extendInfo.payType) {
        case 'ALI_SAFE_PAY': // 芝麻先享
          payData.tradeType = 'MERCHANT_CARD' // 按照app的方式
          if (this.appTypeStr === 'mini') { // 小程序环境 跳转待支付页 复制芝麻口令
            const goodsId = this.buyGoods.isRecommended ? this.buyGoods.id : ''
            const url = `${this.shareParams.path}&userId=1&goodsId=${goodsId}&shopCityId=${this.queryCityId}`
            const redirectUri = encodeURIComponent(url)

            wx.miniProgram.navigateTo({
              url: `/pages/webViewPay/index?payData=${JSON.stringify(payData)}&redirect_uri=${redirectUri}`,
            })
          } else {
            silentAppPay(2, payData, this.shareParams.path)
          }
          return
        case 'AUTO_RENEW': // app周期付
          payData.tradeType = this.appTypeStr === 'mini' ? 'APPLET_WITH_CYCLE' : 'APP_WITH_CYCLE'
          silentAppPay(this.renewPayType, payData, this.shareParams.path)
          this.close()
          return
      }

      if (this.appTypeStr === 'mini') {
        payData.wxPayType = 'applet'
      } else {
        appPaySuccess().then(() => {
          this.paySuccessCallback()
        })
      }

      // 弹个选择优惠券的窗
      if (this.buyGoods.canUseVoucher) {
        this.showPayPopup = true
        this.payData = payData
        this.payUrl = url
      } else {
        appPay(payData, url)
      }

      this.close()
    },
    getPayParams() {
      return {
        subject: this.buyItem.productName,
        extendsJson: {
          appVersion: '2.1.11',
          shopId: this.shopId,
          activityShopGoodsId: this.buyGoods.id + '',
          activityShopGoodsItemId: this.buyItem.id,
          from: this.from,
          userAddressId: this.address.userAddressId,
          perUseVenueId: this.perUseVenueId,
          // inviteUserId: this.inviteId,
        },
        totalAmount: this.buyItem.salePrice + '',
        venueId: '31',
        goodsNum: [12, 22].includes(this.buyGoods.goodsType) ? '1' : undefined,
        isApp: '1',
        type: this.buyGoods.goodsType, // 礼品卡12  课包10 实体卡22
        userId: this.userId,
        token: this.token,
        extendsType: '4',
        mouldId: this.buyItem.productId,
        whetherPrivacy: 0,
        originalPriceStr: '',
        title: this.appTypeStr === 'mini' ? '商品名称' : this.buyItem.productName,
      }
    },
    checkHasExistCard() {
      if (['ALI_SAFE_PAY', 'AUTO_RENEW'].includes(this.buyItem.extendInfo.payType)) {
        const param = {
          equityCardItemId: this.buyItem.productId,
          userId: this.userId,
          token: this.token,
        }
        this.$axios.post(`${this.baseURLApp}/equityCard/check/autoRenueBuyRemind`, param, false).then((res) => {
          // 有提示 说明有正在使用的卡
          if (res.data.remind && !this.isHasActivedCardShow) {
            // 如果是芝麻先享 不能买
            // if (this.buyItem.extendInfo.payType === 'ALI_SAFE_PAY') {
            //   this.$toast('您已存在自动续费的权益卡，无法再次开通')
            //   return
            // }
            // 替换文字颜色 展示弹窗
            this.hasActivedTip = res.data.remind.content
            res.data.remind.keys.map((r) => {
              this.hasActivedTip = getRedString(this.hasActivedTip, r)
            })
            this.isHasActivedCardShow = true
          } else {
            this.payAction()
          }
        })
      } else {
        this.payAction()
      }
    },
    async timeFinish() {
      if (this.activityStatus == 'in') {
        this.activityStatus = 'end'
        this.getGoodStatus()
      } else {
        await this.getActivity()
      }
    },
    getBindInfo() {
      this.$axios
        .post(this.baseURLApp + '/userDistribute/bindUser', {
          hasWx: true,
          activityNo: this.shopId, // shopId就是老接口里的activityNo
          userId: this.userId,
          saleTeacherId: this.inviteTeacherId,
          saleUserId: this.inviteUserId,
        })
        .then((res) => {
          const d = res.data
          this.inviteUserInfo = d || {}
          this.inviteTeacherId = d && d.shareTeacherId

          // 2024.04.08 用户拓展的需求 荣仁要求改的 🔽
          this.$axios
            .post(this.baseURLApp + '/userExpansionBing/scanCode', {
              activityId: this.shopId,
              saleTeacherId: this.inviteTeacherId,
              saleUserId: this.inviteUserId,
              userId: this.userId,
              userExpansionSourceId: this.userExpansionSourceId
            }).then((res) => {})
          // 2024.04.08 用户拓展的需求 荣仁要求改的 🔼
        })
    },

    getActivity() {
      return this.$axios
        .post(`${this.baseURLApp}/activityShopWeb/getShop`, {
          cityId: this.queryCityId,
          shopId: this.shopId,
          userId: this.userId,
        })
        .then((res) => {
          // res.data.partitions.subfieldsInfos.map((sub) => {
          //   if (sub.groupInfos.length > 1) {
          //     sub.groupInfos.map((group) => {
          //       let canShow = false
          //       group.goodsInfos.map((goods) => {
          //         if (!goods.saleCity || (goods.saleCity && goods.saleCity.includes(this.cityId))) {
          //           canShow = true
          //         }
          //       })
          //       this.$set(group, 'canShow', canShow)
          //     })
          //   }
          // })

          // 活动总信息
          this.AI = res.data
          if (!this.AI.activityTrailer) {
            // 设置活动状态
            this.activityStatus = this.AI.diffStartTime > 0 ? 'notin' : this.AI.diffEndTime > 0 ? 'in' : 'end'
            // 设置页面标题
            document.title = this.AI.activityName
            // 设置小程序卡片分享内容
            this.shareParams.title = this.AI.wxShareContent
            this.shareParams.miniImage = this.AI.wxShareImg || ''
            // 兜底
            if (!this.AI.shopStyle.groupTitleImg) this.AI.shopStyle.groupTitleImg = 'https://img.chaolu.com.cn/ACT/anniversary-2023/coverbg.png'
            // 预加载海报图
            if (this.AI.shareImg) {
              let img = new Image()
              img.src = this.AI.shareImg
              img.onload = () => { }
            }

            this.setTabIndex(0)
            // 获取滚动元素高度
            this.getHeight()
          }
        })
        .catch((res) => {
          this.$toast(res.msg)
          setTimeout(() => {
            this.newAppBack()
          }, 2000)
          return res.code
        })
    },

    // 获取当前菜单tab下商品的id集合 单独获取商品的购买状态 结果用id匹配 {34: 'CAN_BUY'}
    getGoodStatus() {
      const idArr = []
      this.curGoods.groupInfos.map((group) => {
        group.goodsInfos.map((goods) => {
          idArr.push(goods.id)
        })
      })
      const params = {
        goodsId: idArr,
        shopId: this.shopId,
        userId: this.userId,
      }
      // 获取按钮状态
      this.$axios.post(`${this.baseURLApp}/activityShopWeb/getGoodsStatus`, params).then((res) => {
        this.goodsStatusMap = res.data.goodsStatusMap
        this.$toast.clear()

        // 判断 分栏底下的商品 是否因为人群关系 全部隐藏 此时 需要隐藏整个分栏
        this.curGoods.groupInfos.map((group) => {
          let show = false
          group.goodsInfos.map((goods) => {
            if (!goods.showWithCrow || (goods.showWithCrow && this.goodsStatusMap[goods.id] === 'CAN_BUY')) show = true
          })

          this.$set(group, 'show', show)
        })
      })

      // 获取价格的状态
      this.$axios.post(`${this.baseURLApp}/activityShopWeb/getGoodMaxDiscount`, params).then((res) => {
        this.goodsPriceStatusMap = res.data.goodsDiscountMap
      })
    },

    // 获取当前购买商品的规格id集合 结果用id匹配 {34: 'CAN_BUY'}
    getItemStatus() {
      this.$toast.loading({
        duration: 0,
        forbidClick: true,
        loadingType: 'spinner',
      })
      const idArr = this.buyGoods.itemInfos.map((item) => item.id)

      const params = {
        goodItems: idArr,
        goodsId: this.buyGoods.id,
        shopId: this.shopId,
        userId: this.userId,
      }
      // 规格价格
      this.$axios.post(`${this.baseURLApp}/activityShopWeb/getGoodItemMaxDiscount`, params).then((res) => {
        this.itemPriceStatusMap = res.data.goodsDiscountMap
      })
      // 规格按钮状态
      return this.$axios.post(`${this.baseURLApp}/activityShopWeb/getGoodsItemStatus`, params).then((res) => {
        this.itemStatusMap = res.data.goodsStatusMap
        this.$toast.clear()
      })
    },

    paySuccessCallback() {
      this.$toast('支付成功')
      setTimeout(() => {
        this.perUseVenueId = ''
        this.perVenueName = ''
        this.getGoodStatus()
      }, 500)
    },
    showShare() {
      if (!this.userId) {
        this.$store.commit('openLoginPopup', true)
        return
      }

      this.isShareShow = true
    },

    initQr() {
      this.$axios
        .post(`${this.baseURLApp}/qrCode/createOrdinaryCode`, {
          token: this.token,
          userId: this.userId,
          width: 240,
          height: 240,
          webUrl: 'https://web.chaolu.com.cn/#/ordinary_004001',
          scene: JSON.stringify({
            source: `activity_${this.shopId}`,
            id: this.shopId,
            webUrl: webPath,
          }),
        })
        .then((res) => {
          this.userDetail = res.data
        })
    },
    miniShare() {
      wx.miniProgram.postMessage({
        data: {
          type: 'share',
          shareImage: this.shareParams.miniImage,
          shareTitle: this.shareParams.title,
          shareUrl: this.shareParams.path,
        },
      })
    },
    openRule(isTotalRule) {
      this.curRule = isTotalRule ? this.AI.shopStyle.ruleContent : this.curGoods.saleRule
      this.isRuleShow = true
    },
    close() {
      this.isItemConfirmShow = false
      this.isRenewPayTypeShow = false
      this.isItemSelectShow = false
      this.itemIndex = 0
      this.isZhimaxianxiang = false
      this.isHasActivedCardShow = false
      this.isCombinedBuyShow = false
    },
    getHeight() {
      this.$nextTick(() => {
        this.navHeight = this.$refs.nav ? this.$refs.nav.clientHeight : 0
      })
    },
    toGroup(el, index) {
      this.groupIndex = index
      const ref = this.$refs[el]

      this.isClickScroll = true
      setTimeout(() => {
        this.isClickScroll = false
      }, 1000)
      window.scrollTo({
        left: 0,
        top: ref[0].offsetTop - (this.barHeight + this.navHeight + 45) - 10,
        behavior: 'smooth',
      })
    },
    setScroll() {
      this.topArr = []
      setTimeout(() => {
        // 计算各个group的offsetTop
        this.curGoods.groupInfos.map((group) => {
          let top = this.$refs[`group${group.id}`][0].offsetTop - (this.barHeight + this.navHeight + 45) - 10
          this.topArr.push(top)
        })

        if (this.curGoods.showGroupMenu && this.curGoods.groupInfos.length > 1) {
          window.addEventListener('scroll', this.handleScroll, true)
        } else {
          window.removeEventListener('scroll', this.handleScroll, true)
        }
      }, 2000)
    },
    handleScroll(e) {
      if (e.target.nodeName !== '#document' || this.isClickScroll) return
      let top = document.documentElement.scrollTop || document.body.scrollTop
      for (let i = 0; i < this.topArr.length; i++) {
        if (top < this.topArr[i + 1]) {
          this.groupIndex = i
          break
        } else {
          this.groupIndex = this.topArr.length - 1
        }
      }
    },
    hexToRgba(hex, opacity) {
      if (!hex) return ''
      return `rgba(${parseInt('0x' + hex.slice(1, 3))}, ${parseInt('0x' + hex.slice(3, 5))}, ${parseInt('0x' + hex.slice(5, 7))}, ${opacity})`
    },
    recommendedClose() {
      this.getGoodStatus()
    },
    addressChange(obj) {
      this.address = obj
    },
    //门店弹出窗口返回的数据
    choseStorePop(val, param) {
      console.log(val, param)
      this.venueSelectShow = false
      if (param) {
        this.perUseVenueId = param.venueId[0]
        this.perVenueName = param.venueName
        this.buyWhat()
      }
    },
  },
}
</script>
<style lang="less" scoped>
.marketing-preview {
  min-height: 100vh;
}

.marketing-template-2023 {
  min-height: 100vh;
  background: #fb7a37;
  padding-bottom: 80px;

  div {
    box-sizing: border-box;
  }

  img {
    width: 100%;
  }

  .template-content {
    width: 100%;
  }

  .background {
    position: relative;
    width: 100%;

    .head-img {
      display: block;
      min-height: 30px;
    }

    .top-button {
      position: absolute;
      right: 0;
      top: 238px;
      width: 88px;
      z-index: 99;

      .icon {
        width: 88px;
        height: 88px;
        object-fit: contain;
        margin-bottom: 32px;
      }

      .button {
        line-height: 56px;
        background: rgba(36, 40, 49, 0.3);
        border-radius: 40px 0 0 40px;
        color: #fff;
        width: 100%;
        text-align: center;
        margin-bottom: 32px;
        padding-left: 6px;
      }
    }

    .float-icon {
      position: absolute;
      right: 0;
      bottom: 24px;
      width: 144px;
      z-index: 99;
    }
  }

  .invite-block {
    width: 686px;
    height: 104px;
    margin: 20px 32px 0;
    background: url(https://img.chaolu.com.cn/ACT/temp-activity/student-mall/main_bg_invite.png);
    background-size: 100% 100%;
    padding: 0 36px;
    color: #eeeeee;

    .invite-head {
      width: 64px;
      height: 64px;
      border-radius: 50%;
      overflow: hidden;
      background: #f5f5f5;
      flex-shrink: 0;
      margin-right: 20px;

      img {
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .banner-block {
    width: 100%;
    margin: 30px 0 0;
    padding: 0 32px;

    .small-banner {
      width: 333px;
    }
  }

  .goods-title {
    width: 427px;
    display: block;
    margin: 70px auto 12px;
  }

  .nav-title {
    width: 686px;
    margin: 70px 0 34px;
  }

  .nav {
    width: 100%;
    position: relative;
    padding: 16px 32px 8px;

    .tm {
      max-width: 335px;
      min-width: 333px;
      flex: 1;
      margin-bottom: 16px;
      background: linear-gradient(180deg, #ffffff 0%, #ffe9e3 100%);
      border-radius: 12px;
      height: 100px;
      padding-left: 26px;
      color: #383e43;
      position: relative;
      overflow: hidden;

      &.small {
        max-width: 218px;
        min-width: 216px;
      }

      &.active {
        color: #9b270c;
        background: linear-gradient(180deg, #fffed8 0%, #ffe293 100%);
      }

      .ii {
        transform: scale(0.8);
        transform-origin: 0 50%;
        white-space: nowrap;
      }
    }
  }

  .title-line {
    width: 100%;
    color: #fff;
    padding: 20px 32px 28px;

    .arrow {
      width: 21px;
    }

    .ss {
      margin-right: 4px;
    }
  }

  .end {
    text-align: center;
    color: #fff;
    position: relative;
    margin-top: 40px;

    &::after,
    &::before {
      content: '';
      vertical-align: middle;
      display: inline-block;
      width: 60px;
      height: 1px;
      background: #fff;
    }

    &::after {
      margin: -2px 0 0 29px;
    }

    &::before {
      margin: -2px 29px 0 0;
    }
  }

  .group-bar {
    width: 750px;
    padding: 18px 32px 18px;
    border-radius: 20px;
    background: rgba(255, 255, 255, 0.2);
    margin: -8px 0 36px;
    overflow: auto;
    -webkit-overflow-scrolling: touch;

    .item {
      width: 20%;
      color: #fff;
      border-radius: 20px;
      padding: 12px 0;

      &.active {
        background: linear-gradient(0deg, #ffffff 0%, #ffe9e3 100%);
        color: #f9624e;
      }
    }
  }

  .good-block {
    width: 100%;
    padding: 0 32px;

    .ad-group {
      .ad-item {
        width: 100%;
        margin: 0 0 32px;
      }
    }

    .good-group {
      border-radius: 20px;
      background: rgba(255, 255, 255, 0.2);
      padding: 24px;
      margin-bottom: 40px;
      min-height: 280px;
      position: relative;

      &.has-title {
        padding: 74px 24px 24px;
      }

      &.only-img {
        background: none;
        padding-left: 0;
        padding-right: 0;
      }

      .two-img {
        width: 50%;
      }

      .three-img {
        width: 33.33%;
      }
    }

    .group-name {
      position: absolute;
      top: -10px;
      left: 0;
      height: 60px;
      width: 50%;
      background-size: 100% 100%;
      color: #68413c;
      padding: 0 0 0 24px;
      overflow: hidden;
    }
  }

  .pop-rule {
    border-radius: 24px 24px 0 0;
    color: #242831;

    .pop-rule-title {
      height: 140px;
      text-align: center;
      line-height: 130px;
      position: relative;
      background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(246, 103, 67, 0.3) 100%);

      .pop-rule-close {
        position: absolute;
        right: 32px;
        top: 38px;
        width: 44px;
        height: 44px;
        line-height: 44px;
        border-radius: 50%;
        background: rgba(39, 40, 49, 0.2);
      }
    }

    .pop-rule-content {
      padding: 0 52px;
      max-height: 600px;
      overflow: auto;
      padding-bottom: 60px;
      white-space: pre-line;
    }
  }

  .pop-item {
    border-radius: 24px 24px 0 0;
    color: #242831;

    .buy-title {
      border-bottom: 1px solid #eeeeee;
      height: 97px;
      line-height: 97px;
      padding: 0 32px;
      text-align: center;
      position: relative;
    }

    .buy-close {
      position: absolute;
      right: 32px;
      top: 50%;
      transform: translateY(-50%);
    }

    .cbox {
      padding: 37px 32px 0;
      border-bottom: 1px solid #eee;
    }

    .buy-tips {
      color: #9aa1a9;
      margin: 4px 0 22px;
    }

    .goods-line {
      display: flex;
      margin: 22px 0 60px;

      .goods-img {
        width: 256px;

        img {
          width: 100%;
          border-radius: 16px;
          display: block;
        }
      }

      .goods-info {
        flex: 1;
        margin-left: 30px;

        .goods-name {
          color: #242831;
        }

        .time {
          color: #242831;
          margin: 12px 0 20px;
          line-height: 1;
        }

        .labels {
          margin: 0 0 15px;

          .label {
            height: 30px;
            background: linear-gradient(90deg, #ffe8b5 0%, #ffd7c3 100%);
            padding: 0 13px;
            margin: 0 12px 0 0;
            color: #b12e1d;
            border-radius: 4px;
          }
        }

        .price-line {
          margin-top: 8px;
          line-height: 1;

          .dl {
            color: #ee4832;
            margin-right: 4px;
          }

          .price {
            color: #ee4832;
            margin-right: 9px;
            position: relative;
            top: 2px;
          }

          .ori-price {
            text-decoration: line-through;
            color: #6c727a;
          }
        }
      }
    }

    .city-list {
      padding: 0 0 32px;

      .city-model {
        margin-top: 24px;
        height: 128px;
        background: #f5f5f5;
        border-radius: 8px;
        padding: 0 32px;

        &.disabled {
          color: #9aa1a9;
        }

        &.active {
          border: 4px solid #242831;

          .checkbox {
            width: 48px;
            height: 48px;
            background: url(https://chaolucoachwebstatic.chaolu.com.cn/static/img/spring2023/check.png);
            background-size: 100% 100%;
            border: none;
            margin: 0;
          }
        }

        .checkbox {
          width: 32px;
          height: 32px;
          border: 1px solid #ddd;
          border-radius: 50%;
          margin-right: 8px;
        }

        .has-buy {
          width: 102px;
        }
      }
    }

    .next {
      line-height: 96px;
      background: #ffde00;
      border-radius: 8px;
      margin: 34px 32px 0;
      text-align: center;
      margin-bottom: 34px;

      &.grey {
        filter: grayscale(1);
      }
    }
  }

  .pop-sure {
    width: 590px;
    border-radius: 12px;
    padding: 50px 40px;
    box-sizing: border-box;

    .sure-title {
      color: #242831;
      text-align: center;
    }

    .sure-content {
      color: #3c454e;
      line-height: 1.6;
      margin: 36px 0 48px;

      span {
        color: #f03c18;
      }
    }

    .sure-tip {
      margin: 0 0 12px;
    }

    .sure-sub {
      border-radius: 8px;
      background: #ffde00;
      color: #000;
      line-height: 96px;
      text-align: center;
    }
  }

  .renew-paytype-box {
    padding: 0 32px 32px;
    box-sizing: border-box;

    .pay-btn {
      width: 686px;
      height: 96px;
      background: #ffde00;
      border-radius: 8px;
      color: #242831;
      margin: 72px 0 38px;
    }

    .pay-type {
      color: #242831;
      padding: 32px 0 60px;
    }

    .wechat,
    .alipay {
      background: url('https://chaolucoachwebstatic.chaolu.com.cn/static/img/cash-coupon/normal.png') no-repeat center right;
      background-size: 40px 40px;
      padding: 24px 0;

      &.active {
        background-image: url('https://chaolucoachwebstatic.chaolu.com.cn/static/img/cash-coupon/active.png');
      }

      img {
        width: 40px;
        height: 40px;
        margin-right: 16px;
      }
    }
  }

  .pay-box {
    width: 622px;
    box-sizing: border-box;
    margin: 0 auto;
    background: white;
    border-radius: 16px;
    overflow: hidden;
    padding: 0 40px 40px;

    .auto-img {
      width: 100%;
      padding: 40px 32px 0;
      box-sizing: border-box;
    }
  }

  .pay-share-btn {
    div {
      width: 100%;
      height: 96px;
      border-radius: 8px;
      border: 1px solid #ffde00;
      box-sizing: border-box;
      background: #ffde00;
    }

    .grey-btn {
      width: 206px;
      flex: none;
      margin: 0 24px 0 0;
      background-color: #fff;
      border: 1px solid #cccccc;
    }

    height: 96px;
    margin: 48px auto 0;
    color: #242831;
  }

  .error-box {
    width: 630px;
    background: #ffffff;
    border-radius: 12px;
    margin: 0 auto;
    padding: 0 40px 48px;

    h4 {
      text-align: center;
      padding-top: 40px;
    }

    p {
      margin: 32px auto 56px;
      width: 510px;
      color: #3c454e;
      line-height: 44px;
    }

    .btn {
      margin: 0 auto;
      width: 510px;
      height: 96px;
      background: #ffde00;
      border-radius: 8px;
    }

    span {
      color: #f03c18;
    }
  }

  .canvascss {
    width: 300PX;
    height: 539PX;
    margin: 0 auto;
    box-sizing: border-box;

    .canvascss_bg {
      width: 100%;
      height: 100%;
    }

    .canvascss_code {
      position: absolute;
      left: 60PX;
      bottom: 7PX;
      width: 66PX;
      height: 71PX;
      background: #fff;
      z-index: 99;
      border-radius: 5PX;
      overflow: hidden;
      border: 1PX solid #E1E1E1;

      img {
        width: 100%;
        height: 65PX;
        position: relative;
        top: -2PX;
      }

      .canvascss_code_text {
        position: absolute;
        bottom: 4PX;
        left: 0;
        right: 0;
        text-align: center;
        line-height: 1;
        font-size: 8PX;
        color: #000000;
      }
    }

    .canvascss_info {
      position: absolute;
      left: 133PX;
      bottom: 26PX;
      padding: 3PX;
      width: 108PX;
      height: 51PX;
      overflow: hidden;
      align-items: center;
      display: flex;

      .canvascss_head {
        width: 45PX;
        height: 45PX;
        border-radius: 7PX;
        margin-right: 6PX;
      }

      .canvascss_full {
        font-weight: bold;
        font-size: 11PX;
        color: #000000;
        flex: 1;

        .canvascss_name {
          line-height: 1.3;
        }
      }
    }
  }

  .canvascss2 {
    width: 300PX;
    height: 533PX;
    margin: 0 auto;
    box-sizing: border-box;

    .canvascss_bg {
      width: 100%;
      height: 100%;
    }

    .canvascss_left {
      position: absolute;
      left: 13PX;
      bottom: 15PX;
      z-index: 1;
      width: 50PX;
      height: 50PX;
      border-radius: 50%;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .canvascss_name {
      position: absolute;
      left: 75PX;
      bottom: 55PX;
      z-index: 1;
      color: #fff;
      width: 126PX;
    }

    .canvascss_right {
      position: absolute;
      right: 17PX;
      bottom: 14PX;
      width: 62PX;
      height: 62PX;
      overflow: hidden;
      //background: url(https://img.chaolu.com.cn/ACT/anniversary-2023/border.png);
      background-size: 100% 100%;
      padding: 6PX;

      img {
        border-radius: 4PX;
        width: 100%;
        height: 100%;
      }
    }
  }

  .red {
    color: #ee4832;
  }
}

/deep/ .red {
  color: #ff6e00;
}
</style>
