<template>
    <van-popup class="com-city-venue" v-model="popShow" position="bottom" safe-area-inset-bottom>
        <div class="pop-title">
            请选择
            <van-icon name="cross" class="pop-close" @click="popShow = false" />
        </div>
        <div class="cvbody">
            <div class="left">
                <div class="city-model" :class="{ active: chooseLeftId == item.id }" v-for="item in list" :key="item.id"
                    @click="setLeft(item.id)">{{ item.label }}</div>
            </div>
            <div class="right">
                <div class="venue-model" :class="{ active: chooseRightId == item.id }" v-for="item in rightData"
                    :key="item.id" @click="setRight(item)">{{ item.label }}</div>
            </div>
        </div>
        <div class="sub" :class="{ disabled: !chooseRightId }" @click="sure">确认</div>
    </van-popup>
</template>

<script>
export default {
    props: {
        rightId: {
            type: [Number, String]
        },
        list: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            popShow: false,
            chooseLeftId: undefined,
            chooseRightId: this.rightId
        };
    },
    watch: {
        list: {
            handler(v) {

                this.getLeft();
            },
            immediate: true
        },
        rightId(v) {    
            this.chooseRightId=v;            console.log(1111111144,v)
            this.getLeft();
        }
    },
    computed: {
        rightData() {
            console.log(this.chooseLeftId)
            return (this.list || []).find((d) => {
                return d.id == this.chooseLeftId
            })?.children || []
        },

    },
    methods: {
        getLeft() {
            const v = this.list;console.log(this.chooseRightId,v)
            for (let i = 0; i < v.length; i++) {
                const model = (v[i].children || []).find(d => {

                    return d.id == this.chooseRightId
                })
                if (model) {
                    
                    this.chooseLeftId = v[i].id;
                    break;
                }
            }
        },
        setLeft(id) {
            if (this.chooseLeftId == id) { return }
            this.chooseLeftId = id;
        },
        setRight(item) {
            if (this.chooseRightId == item.id) {
                this.chooseRightId = undefined;
                this.dataItem = null;
                return;
            }
            this.chooseRightId = item.id;
            this.dataItem = item;
        },
        sure() {
            if (this.chooseRightId) {
                this.$emit('confirm', this.dataItem);
                this.popShow = false;
            }
        }
    }
};
</script>

<style lang="less" scoped>
.com-city-venue {}

.pop-title {
    height: 97px;
    line-height: 97px;
    position: relative;
    text-align: center;
    font-weight: bold;
    border-bottom: 1px solid #eee;

    .pop-close {
        position: absolute;
        right: 32px;
        top: 32px;
    }
}

.cvbody {
    margin-top: 35px;
    height: 687px;
    overflow: hidden;
    display: flex;
    font-size: 24px;
    padding: 0 32px;
    color: #242831;
    border-bottom: 1px solid #eee;

    .left {
        width: 212px;
        height: 100%;
        overflow: auto;
        flex-shrink: 0;

        .city-model {
            width: 100%;
            margin-bottom: 20px;
            background: #f5f5f5;
            line-height: 76px;
            text-align: center;
            border-radius: 6px;
            position: relative;
        }
    }

    .right {
        height: 100%;
        overflow: auto;
        flex: 1;
        margin-left: 30px;

        .venue-model {
            line-height: 92px;
            background: #f5f5f5;
            text-align: center;
            margin-bottom: 20px;
            border-radius: 6px;
            position: relative;
        }
    }

    .active::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        border: 2px solid #232831;
        border-radius: 8px;
    }
}

.tips {
    color: #68413C;
    font-size: 22px;
    text-align: center;
    margin: 28px 0 20px;
}

.sub {
    border-radius: 8px;
    line-height: 96px;
    color: #242831;
    font-size: 32px;
    background: #FFDE00;
    text-align: center;
    font-weight: bold;
    margin: 26px 32px 26px;
}

.disabled {
    // background: #ccc3c3;
    opacity: 0.5;
    pointer-events: none;
}
</style>
