<template>
    <div class="page-pricoach-ranking" @scroll="sc">
        <headeBar left-arrow :color="header_color" :title="header_title" :background="header_background"
            :opacity="header_opacity" :placeholder="false" @click-left="newAppBack" />
        <div class="headbg">
            <div class="rs">
                <div class="sec1" @click="wxInvite">分享</div>
                <div class="sec2" @click="showRule = true">规则</div>
            </div>
        </div>
        <div class="con">
            <div class="searchbar">
                <div class="arealine">
                    <div class="tab">
                        <div class="tab-model" :class="{ active: tabIndex == key }" @click="setTab(key)"
                            v-for="item, key in searchJson">{{ item.name }}</div>
                    </div>
                    <div class="select" @click="openSelect" v-if="selectName">
                        {{ selectName }}
                        <van-icon name="play" class="down" />
                    </div>
                </div>
                <!-- <div class="filterline">
                    <div class="fmodel">可约时段<van-icon name="arrow-down" class="di" /></div>
                    <div class="fmodel">价格<van-icon name="arrow-down" class="di" /></div>
                    <div class="fmodel">性别<van-icon name="arrow-down" class="di" /></div>
                    <div class="fmodel">私教课程<van-icon name="arrow-down" class="di" /></div>
                </div> -->
            </div>
            <div v-if="hasdata" class="sbox">
                <template v-if="teacherList.length">
                    <div class="teacher-list">
                        <div class="bg" v-for="(item, index) in teacherList" @click="toTeacher(item)">
                            <div class="teacher-model">
                                <div class="v1">
                                    <div class="img">
                                        <img alt="" :src="item.pic" />
                                        <div class="rbg" :class="index>2?'before':''">

                                            <template v-if="index > 2">
                                                {{ index + 1 }}
                                            </template>
                                            <img alt=""
                                                :src="'https://img.chaolu.com.cn/ACT/teacher-detail/ranking/p' + (index + 1) + '.png'"
                                                v-else />
                                        </div>
                                    </div>

                                    <div class="pub_onefull ellipsis">
                                        <div class="l1 ">
                                            <span class="name ellipsis">{{ item.name }}</span>
                                            <span class="pt">PT{{ item.rating }}</span>
                                            <span class="score">{{ item.totalScore || 0 }}</span>
                                            <span class="unit">分</span>
                                        </div>
                                        <div class="l2">
                                            <img class="z" alt="" v-for="citem in item.certificateImageList" :src="citem" />
                                            <div class="znum"><span class="val">{{ item.certificateLabelNum }}</span>个证书
                                            </div>
                                        </div>
                                        <div class="l3">
                                            <div class="fl">
                                                <span class="label" v-for="citem in item.labelNameList">{{ citem }}</span>
                                            </div>
                                            
                                            <div class="sub" @click.stop="toTeacherAppointment(item)">约课</div>
                                        </div>
                                        <div class="l4">
                                            <span class="dis">{{ distance(item.distant) }}</span>
                                            <span class="store">{{ item.venueName }}</span>
                                            <!-- <span class="time">5小时内可约</span> -->
                                        </div>
                                    </div>
                                </div>
                                <div class="v2" v-if="item.description">
                                    <div class="ellipsis">{{ item.description }}</div>
                                </div>
                            </div>
                        </div>


                    </div>
                    <div class="bt">
                        <template v-if="teacherList.length < searchJson[tabIndex].len">暂时没有更多了~</template>
                        <template v-else>
                            榜单仅统计前{{ searchJson[tabIndex].len }}名教练
                        </template>
                    </div>
                </template>
                <van-loading class="loading" vertical v-else>加载中...</van-loading>
            </div>
            <div class="nonedata" v-else>
                <img class="noneimg" src="https://img.chaolu.com.cn/ACT/teacher-detail/ranking/empty.png" alt />
                <div class="ntitle">当前{{ searchJson[tabIndex].name }}暂无入榜教练</div>
                <template v-if="tabIndex == 1">
                    <div class="ntip">近期新开门店不会计入排行榜哦~<br>可查看最近的入榜门店-{{ nearStore.venueName }}</div>
                    <div class="nsee" @click="seeOtherVenue">去查看</div>
                </template>

            </div>
            <!-- <img src="https://img.chaolu.com.cn/ACT/teacher-detail/ranking/recommend.png" alt />
            <div class="teacher-list">

                <div class="teacher-model" v-for="(item, index) in recommendList" @click="toTeacher(item)">
                    <div class="v1">
                        <div class="img">
                            <img alt="" :src="item.headImg" />

                        </div>

                        <div class="pub_onefull ellipsis">
                            <div class="l1 ">
                                <span class="name ellipsis">{{ item.teacherName }}飞得更高孤寡孤寡孤寡刚发刚发搭嘎代发更大更</span>
                                <span class="pt">PT4</span>
                                <span class="score">4.9</span>
                                <span class="unit">分</span>
                            </div>
                            <div class="l2">
                                <img class="z" alt=""
                                    src="https://img.chaolu.com.cn/ACT/teacher-detail/ranking/gjz.png" />
                                <img class="z" alt=""
                                    src="https://img.chaolu.com.cn/ACT/teacher-detail/ranking/pxs.png" />
                                <img class="z" alt=""
                                    src="https://img.chaolu.com.cn/ACT/teacher-detail/ranking/clkg.png" />
                                <div class="znum"><span class="val">8</span>个证书</div>
                            </div>
                            <div class="l3">
                                <span class="label">减脂</span>
                                <span class="label">减脂</span>
                                <span class="label">减脂</span>
                                <div class="sub" @click.stop="toTeacherAppointment(item)">约课</div>
                            </div>
                            <div class="l4">
                                <span class="dis">825m</span>
                                <span class="store">中庚青年广场店</span>
                                <span class="time">5小时内可约</span>
                            </div>
                        </div>
                    </div>
                    <div class="v2">
                        一位柔韧、力量、爆发、低体脂率——样样都不想落下的教练~
                    </div>
                </div>

            </div>
            <div class="nomore">暂时没有更多了~</div> -->
        </div>

        <img class="totop" src="https://img.chaolu.com.cn/ACT/teacher-detail/ranking/top.png" alt v-show="showTop"
            @click="toTop" />

        <van-overlay :show="isShareWx" :z-index="1500" @click="isShareWx = false">
            <div class="tip-box" @click.stop>
                <img src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/anniversary2021/share-mini.png"
                    class="tip-img" />
            </div>
        </van-overlay>

        <van-popup v-model="showRule" position="bottom" safe-area-inset-bottom class="pop">
            <div class="pop-title">
                评分说明
                <van-icon name="cross" class="pop-close" color="#fff" @click="showRule = false" />
            </div>
            <div class="pop-rule-box">
                <div class="rule-title">
                    Q1：什么是超鹿私教分？
                </div>
                <div class="rule-content">
                    超鹿私教分是由多个维度对私教教练进行多维度量化评
                    估，综合得分越高，代表综合表现越好，供会员进行教
                    练选择参考，主要评估维度包含 专业水平、授课经验、
                    会员服务、用户评价、综合素质。

                </div>
                <div class="rule-title tt">
                    Q2：为什么有的教练没有教练分评分？
                </div>
                <div class="rule-content">
                    超鹿私教分是由综合性长期评分得出，为保证评分绝对
                    公正、公平、客观，给到会员尽可能中肯的评分体系参
                    考，部分新入职超鹿运动的教练和评分统计数据不足的
                    教练不进行展现。

                </div>
            </div>

        </van-popup>


        <van-popup v-model="showSelect" position="bottom" safe-area-inset-bottom class="popSelect">
            <div class="buy-title">
                请选择
                <van-icon name="cross" class="buy-close" color="#9AA1A9" @click="showSelect = false" />
            </div>
            <div class="city-list">
                <div v-for="(item, index) in areaData" :key="index" class="city-model"
                    :class="{ 'city-active': item.id == selectId }" @click="setCityId(item)">
                    {{ item.label }}
                    <div class="checkbox"></div>
                </div>
            </div>
        </van-popup>
        <!-- <PopCourse courseId="1238185957711151104" /> -->
        <AreaSelect ref="refArea" :list="areaData" :rightId="selectId" @confirm="setData" v-if="tabIndex == 2" />
        <AreaSelect ref="refVenue" :list="venueData" :rightId="selectId" @confirm="setData" v-if="tabIndex == 1" />
    </div>
</template>
<script>
import { newAppBack, initBack, saveCreateImg, webAppShare, gotoCoachDetail, universalPushVC } from '@/lib/appMethod';
import headeBar from '@/components/app/headBar';
import userMixin from '@/mixin/userMixin';
import wx from 'weixin-js-sdk';
import headerMixin from '@/mixin/headerMixin';
import PopCourse from '@/components/app/popCourse.vue';
import AreaSelect from "./areaSelect.vue";

const webPath = `${window.location.origin}/#/teacher-detail/ranking`;
const webUrl = 'pages/webView/index?webUrl=' + encodeURIComponent(webPath) + '&location=1';
export default {
    components: {
        headeBar,
        PopCourse,
        AreaSelect
    },
    mixins: [userMixin, headerMixin],
    data() {
        return {
            showSelect: false,
            chooseCityId: '',
            isShareWx: false,
            finished: false,
            loading: false,
            hasdata: true,
            recommendList: [
                {
                    "teacherId": "827127217690513408",
                    "teacherName": "112",
                    "headImg": "https://img.chaolu.com.cn/userfiles/1/files/teacher/teacher/pic/2021/03/jfyj.jpg",
                    "totalVote": 6,
                    "rank": 1
                },
                {
                    "teacherId": "1ba84c7d17794ab2a005b6c159cd9c7a",
                    "teacherName": "黄锦雯",
                    "headImg": "https://img.chaolu.com.cn/dev/01f470674a1f496ba61ef5d84fed0cad/userfiles/files/teacher/teacher/pic/2022/02/test1.jpg",
                    "totalVote": 5,
                    "rank": 2
                },
            ],
            pageNum: 1,
            cityList: [],
            areaData: [],
            venueData: [],
            showShare: false, // 是否显示分享弹窗
            shareParams: {
                title: '超鹿运动私教分排行榜',
                content: '超鹿运动私教分排行榜',
                multiple: '1',
                userName: 'gh_7f5b57b6a76e',
                miniProgramType: window.location.origin.indexOf('web.chaolu.com') > -1 ? '0' : '2',
                url: 'url',
                miniImage: 'https://img.chaolu.com.cn/ACT/teacher-detail/ranking/mini.png',
                path: webUrl,
            },
            teacherList: [

            ],
            nearStore: {
                venueName: '',
                venueId: ''
            },
            searchJson: {
                1: {
                    name: '门店',
                    len: 10
                },
                2: {
                    name: '区县',
                    len: 30
                },
                3: {
                    name: '城市',
                    len: 30
                }
            },
            showRule: false,
            tabIndex: 1,
            showTop: false,
            selectName: '',
            selectId: this.venueId,
            nearVenue: null
        }
    },
    computed: {

    },
    methods: {
        saveCreateImg,
        newAppBack,
        distance(val) {
            let num = val * 1
            if (num < 100) {
                return `小于100m`
            } else if (num < 1000) {
                return `${num.toFixed(0)}m`
            } else {
                return `${(num / 1000).toFixed(2)}km`
            }
        },
        sc(e) {
            if (e.target.scrollTop > 500) {
                this.showTop = true
            } else {
                this.showTop = false
            }
        },
        toTop() {
            this.$el.scrollTop = 0;
        },
        toTeacher(item) {
            gotoCoachDetail(item.teacherId)
        },
        toTeacherAppointment(item) {
            universalPushVC(51, item.teacherId, 1, 1)
        },
        seeOtherVenue() {
            if (this.nearVenue) {
                this.selectName = this.nearVenue.name;
                this.selectId = this.nearVenue.id;
                this.reset();
            } else {
                this.$toast('暂无数据')
            }
        },
        setTab(v) {
            if (v == this.tabIndex) return;
            this.tabIndex = v;
            this.fetchData();
        },
        async fetchData() {
            if (this.tabIndex == 1) {
                this.selectId = this.venueId || this.venueData[0].children[0].id; console.log(666666, this.selectId)
                for (let i = 0; i < this.venueData.length; i++) {
                    const model = (this.venueData[i].children || []).find(d => {
                        return d.id === this.selectId
                    })
                    if (model) {
                        this.selectName = model.label;
                        break;
                    }
                }
            }
            if (this.tabIndex == 2) {
                this.selectName = this.areaData[0].children[0].label;
                this.selectId = this.areaData[0].children[0].id;
            }
            if (this.tabIndex == 3) {
                this.selectId = this.cityId || this.areaData[0].id;
                this.selectName = this.areaData.find(d => d.id == this.cityId)?.label || this.areaData[0].label;
            }

            this.reset();
        },
        setCityId(item) {
            this.showSelect = false;
            this.selectId = item.id;
            this.selectName = item.label;
            this.reset();
        },
        openSelect() {
            if (this.tabIndex == 1) {
                this.$refs.refVenue.popShow = true;
                return;
            }
            if (this.tabIndex == 2) {
                this.$refs.refArea.popShow = true;
                return;
            }
            this.showSelect = true;
        },
        reset() {
            this.pageNum = 1;
            try {
                this.getRanking(true);
            } catch (error) {
                console.log(6666688)
            }

        },
        setAddress(obj) {
            console.log(obj);
        },
        chooseTeacher(index) {
            this.teacherIndex = index;
        },
        wxInvite() {
            if (!this.userId) {
                this.$toast('请稍后再试');
                return;
            }

            if (this.appTypeStr === 'mini') {
                wx.miniProgram.postMessage({
                    data: {
                        type: 'share',
                        shareImage: this.shareParams.miniImage,
                        shareTitle: this.shareParams.title,
                        shareUrl: this.shareParams.path,
                    },
                })
                this.isShareWx = true
            } else {
                let params = {
                    scene: 0,
                    ...this.shareParams
                }
                let miniParams = JSON.parse(JSON.stringify(params))
                miniParams.image = miniParams.miniImage
                webAppShare('3', miniParams)
            }
        },
        setData(item) {
            this.selectName = item.label;
            this.selectId = item.id;
            this.reset();
        },
        getRanking(refresh) {//获取排行榜

            let size = this.searchJson[this.tabIndex].len;
            const ps = {
                token: this.token,
                userId: this.userId,
                lat: this.local?.lat,
                lng: this.local?.lng
            }
            if (this.tabIndex == 1) {
                ps.venueId = this.selectId
            }
            if (this.tabIndex == 2) {
                ps.areaId = this.selectId
                size = 30;
            }
            if (this.tabIndex == 3) {
                ps.cityId = this.selectId
                size = 30;
            }
            this.$toast.loading({
                duration: 0,
                forbidClick: true,
                overlay: true,
                loadingType: 'spinner',
            })
            return this.$axios
                .post(this.baseURLApp + "/teacherDimension/app/getTeacherDimensionRank", ps)
                .then((res) => {
                    const data = res.data.teacherDimensionRankVOList;


                    if (
                        (!data || data.length == 0) &&
                        (this.teacherList.length == 0 || refresh)
                    ) {
                        this.hasdata = false;
                        this.teacherList = [];
                        this.nearVenue = {
                            id: res.data.recommendVenueId,
                            name: res.data.recommendVenueName
                        }
                        console.log(this.nearVenue);
                    } else {
                        this.hasdata = true;
                        if (refresh) {
                            this.teacherList = data;
                        } else {
                            this.teacherList.push(...data);
                        }

                        if (data.length < size) {
                            this.finished = true;
                            console.log('fs');
                        } //全部加载完了
                        else {
                            this.finished = false;
                        }
                    }
                    this.loading = false;
                    this.$toast.clear();
                }).catch(err => {
                    this.$toast.clear();
                    this.teacherList = [];
                    this.hasdata = false;
                    return;
                    this.teacherList = [
                        {
                            "teacherId": "760148758305443840",
                            "teacherName": "112",
                            "headImg": "https://img.chaolu.com.cn/userfiles/1/files/teacher/teacher/pic/2021/03/jfyj.jpg",
                            "totalVote": 6,
                            "rank": 1
                        },
                        {
                            "teacherId": "1ba84c7d17794ab2a005b6c159cd9c7a",
                            "teacherName": "黄锦雯",
                            "headImg": "https://img.chaolu.com.cn/dev/01f470674a1f496ba61ef5d84fed0cad/userfiles/files/teacher/teacher/pic/2022/02/test1.jpg",
                            "totalVote": 5,
                            "rank": 2
                        },
                        {
                            "teacherId": "0118368ec871432ca33b98cae6e356aa",
                            "teacherName": "叮噹",
                            "headImg": "https://img.chaolu.com.cn/userfiles/1/files/teacher/teacher/pic/2021/10/dd.jpg",
                            "totalVote": 4,
                            "rank": 3
                        },
                        {
                            "teacherId": "819226529987825664",
                            "teacherName": "江叶琴(江江)",
                            "headImg": "https://img.chaolu.com.cn/userfiles/1/files/teacher/teacher/pic/2021/03/cxkebao.png",
                            "totalVote": 1,
                            "rank": 4
                        }
                    ]
                })
        },
        loadList() {
            this.pageNum += 1;
            this.getRanking();
        },
        getCityAndVenue() {
            return this.$axios.post(this.baseURLApp + '/app/sys/area/lessionh5/top').then(res => {
                this.areaData = this.filterData(res.data || [], 4); console.log(66666666666, this.areaData);
                this.venueData = this.filterData(res.data || [], 5); console.log(77777777, this.venueData);
                if (this.venueData.length) {
                    this.selectName = this.venueData[0].children[0].label;
                }

            })
        },
        filterData(data, key) {

            const json = {}
            function mapMenu(data, cid) {
                data.forEach((item) => {
                    if (item.type == 3) {
                        //city
                        json[item.id] = { id: item.id, label: item.label, children: [] }
                        cid = item.id
                    } else if (item.type == key) {
                        //store
                        json[cid].children.push({ id: item.venueId || item.id, cid, label: item.label })
                    }
                    if (item.children && item.children.length) {
                        mapMenu(item.children, cid)
                    }
                })
            }
            mapMenu(data)
            return Object.values(json)
        },
    },
    mounted() {
        this.bindHeaderScroll(this.$el, 200, (scrollTop, morehalf) => {

        });
    },
    async created() {
        console.log(this.$route.query);
        initBack();
        await this.$getAllInfo(['userId', 'cityId', 'venueId', 'local']);
        await this.getCityAndVenue();
        this.fetchData();
    }
}
</script>
<style lang="less" scoped>
.page-pricoach-ranking {
    position: absolute;
    height: 100vh;
    top: 0;
    // bottom: 0;
    left: 0;
    right: 0;
    overflow: auto;
    background: #0B0908;

    div {
        box-sizing: border-box;
    }

    img {
        vertical-align: top;
        width: 100%;
    }

    .headbg {
        height: 530px;
        background: url(https://img.chaolu.com.cn/ACT/teacher-detail/ranking/headbg.png) no-repeat;
        background-size: 100% 100%;
        margin-bottom: -120px;

        .rs {
            position: absolute;
            right: 0;
            top: 161px;
            width: 48px;

            .sec1,
            .sec2 {
                padding: 6px 0 0 6px;
                margin-bottom: 20px;
                height: 72px;
                background: linear-gradient(90deg, #FFDA9F 0%, #E9BD78 100%);
                box-shadow: 2px 0px 0px 0px #BA7B1C, 0px 2px 0px 0px #BA7B1C, 0px -2px 0px 0px #BA7B1C;
                border-radius: 16px 0px 0px 16px;
                width: 100%;
                text-align: center;
                font-weight: 600;
                font-size: 22px;
                color: #382810;
            }
        }
    }

    .con {

        margin: 0 32px 0;

        .searchbar {
            .arealine {
                display: flex;
                justify-content: space-between;

                .tab {
                    display: flex;
                    width: fit-content;
                    padding: 6px 0;
                    height: 56px;
                    background: rgba(255, 255, 255, 0.2);
                    border-radius: 10px;

                    .tab-model {
                        width: 80px;
                        line-height: 44px;
                        margin: 0 4px;
                        font-size: 26px;
                        color: #fff;
                        text-align: center;

                        &.active {
                            background: linear-gradient(90deg, #FFDBA3 0%, #FFD189 100%);
                            border-radius: 8px;
                            color: #382810;
                            font-weight: 600;
                        }
                    }
                }

                .select {
                    height: 56px;
                    background: rgba(255, 255, 255, 0.2);
                    border-radius: 10px;
                    padding: 0 22px;
                    color: #fff;
                    font-size: 26px;
                    display: flex;
                    align-items: center;
                    line-height: 1;

                    .down {
                        transform: rotate(90deg);
                        margin-left: 20px;
                        margin-top: -4px;
                    }
                }
            }

            .filterline {
                display: flex;

                .fmodel {
                    font-size: 24px;
                    display: flex;
                    align-items: center;
                    line-height: 1;
                    padding: 0 17px;
                    background: rgba(255, 255, 255, 0.2);
                    color: #fff;
                    height: 48px;
                    margin-right: 16px;
                    border-radius: 6px;
                    margin-top: 24px;

                    .di {
                        font-size: 20px;
                        margin-left: 10px;
                    }
                }
            }

        }
    }

    .sbox {
        min-height: 840px;
        position: relative;

        .loading {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    .teacher-list {
        margin-top: 24px;
        .bg{
            position: relative;
            &::after {
                content: '';
                position: absolute;
                height: 152px;
                width: 620px;
                background: linear-gradient(90deg, #232C40 0%, #25264D 100%);
                border-radius: 20px;
                bottom:-10px;
                left: 20px;
            }
        }
        .teacher-model {
            background: #FFFFFF;
            border-radius: 20px;
            margin-bottom: 34px;
            position: relative;
            padding: 30px 22px 22px;
            z-index: 100;

            .v1 {
                display: flex;
                align-items: baseline;

                .img {
                    width: 180px;
                    height: 180px;
                    margin-right: 26px;
                    border-radius: 10px;
                    overflow: hidden;
                    background: #f5f5f5;
                    flex-shrink: 0;
                    position: relative;

                    .rbg {
                        width: 45px;
                        height: 45px;
                        text-align: center;
                        line-height: 40px;
                        font-weight: 600;
                        font-size: 24px;
                        color: #FFFFFF;
                        position: absolute;
                        left: 6px;
                        top: 0;
                        &.before{
                            background: url(https://img.chaolu.com.cn/ACT/teacher-detail/ranking/rbg.png);
                            background-size: 100% 100%;
                        }
                    }

                    img {
                        height: 100%;
                        width: 100%;
                    }
                }

                .l1 {
                    font-size: 24px;
                    font-weight: bold;
                    margin-bottom: 10px;
                    display: flex;
                    align-items: baseline;
                    line-height: 1;

                    // background: blue;
                    .name {
                        margin-right: 8px;
                        font-weight: 600;
                        font-size: 30px;
                        color: #000000;
                    }

                    .pt {
                        width: 50px;
                        height: 28px;
                        line-height: 28px;
                        background: #242831;
                        border-radius: 4px;
                        font-weight: 600;
                        font-size: 20px;
                        color: #FFFFFF;
                        margin-right: 19px;
                        text-align: center;
                        flex-shrink: 0;
                        position: relative;
                        top: -4px;
                    }

                    .score {
                        font-size: 40px;
                        color: #FF6E00;
                        font-family: 'BebasNeue';
                        position: relative;
                        top: 4px;
                    }

                    .unit {
                        font-weight: 600;
                        font-size: 24px;
                        color: #FF6E00;
                        margin-left: 4px;
                    }
                }

                .l2 {
                    display: flex;
                    flex-wrap: wrap;

                    .z {
                        width: auto;
                        height: 32px;
                        margin-right: 8px;
                    }

                    .znum {
                        height: 32px;
                        padding: 0 7px;
                        line-height: 1;
                        display: flex;
                        align-items: center;
                        font-weight: 600;
                        font-size: 18px;
                        background: #F5F5F5;
                        border-radius: 4px;
                        border: 1px solid rgba(0, 0, 0, 0.1);

                        .val {
                            color: #FF6200;
                        }
                    }
                }

                .l3 {
                    display: flex;
                    margin: 12px 0 10px;
                    .fl{
                        flex: 1;
                        display: flex;
                        flex-wrap: wrap;
                        padding-top: 10px;
                    }
                    .label {
                        font-size: 20px;
                        color: #3C454E;
                        height: 30px;
                        line-height: 30px;
                        margin-right: 8px;
                        background: #F5F5F5;
                        border-radius: 4px;
                        padding: 0 7px;
                        margin-bottom: 5px;
                    }

                    .sub {
                        width: 96px;
                        height: 48px;
                        line-height: 48px;
                        background: #FFDE00;
                        border-radius: 4px;
                        text-align: center;
                        font-weight: 600;
                        font-size: 24px;
                        color: #242831;
                        margin-left: auto;
                    }
                }

                .l4 {
                    display: flex;
                    align-items: center;

                    .dis,
                    .store {
                        font-size: 22px;
                        color: #000000;
                        margin-right: 18px;
                    }

                    .time {
                        font-size: 20px;
                        color: #333333;
                        margin-left: auto;
                    }
                }
            }

            .v2 {
                height: 56px;
                background: linear-gradient(90deg, #EDF4FF 0%, #EAF8FF 100%);
                border-radius: 10px;
                font-size: 20px;
                color: #000000;
                padding: 12px 21px 0;
                margin-top: 28px;
                position: relative;

                &::before {
                    content: '';
                    position: absolute;
                    left: 42px;
                    top: 0;
                    width: 12px;
                    height: 12px;
                    background: red;
                    background: #EDF4FF;
                    transform-origin: bottom;
                    transform: translateY(-50%) rotate(45deg);
                }
            }
        }
    }

    .bt {
        font-size: 22px;
        color: #FFFFFF;
        opacity: 0.6;
        text-align: center;
        margin: 58px 0 100px;
    }

    .nomore {
        font-size: 22px;
        color: #FFFFFF;
        margin-top: 62px;
        text-align: center;
        margin-bottom: 120px;
    }

    .totop {
        position: fixed;
        width: 102px;
        height: 102px;
        right: 24px;
        bottom: 421px;
        z-index: 999;
    }



    .nonedata {
        padding: 136px 0 240px;
        text-align: center;

        .noneimg {
            width: 176px;
            height: 176px;
            margin-bottom: 48px;
        }

        .ntitle {
            font-weight: 600;
            font-size: 28px;
            color: #FFFFFF;
            line-height: 1;
        }

        .ntip {
            font-size: 22px;
            color: #FFFFFF;
            line-height: 36px;
            margin: 20px 0 40px;
            opacity: 0.5;
        }

        .nsee {
            width: 320px;
            height: 80px;
            background: #FFDE00;
            border-radius: 40px;
            font-weight: 600;
            font-size: 28px;
            color: #000000;
            text-align: center;
            line-height: 78px;
            margin: 0 auto;
        }
    }

    .pop {
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;

        .pop-title {
            color: #432F17;
            font-weight: bold;
            background: linear-gradient(180deg, #FFEED3 0%, #FFFFFF 100%);
            font-size: 32px;
            text-align: center;
            line-height: 120px;
            position: relative;

            .pop-close {
                position: absolute;
                right: 32px;
                top: 38px;
                width: 44px;
                height: 44px;
                line-height: 44px;
                border-radius: 50%;
                background: rgba(39, 40, 49, 0.2);
                font-size: 28px;
            }
        }

    }

    .popSelect {
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;

        .buy-title {
            font-weight: bold;
            color: #242831;
            font-size: 32px;
            border-bottom: 1px solid #EEEEEE;
            height: 120px;
            padding: 0 32px;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .buy-close {
            font-size: 36px;
        }

        .city-list {
            padding: 8px 32px 32px;
            max-height: 600px;
            overflow: auto;

            .city-model {
                margin-top: 24px;
                height: 128px;
                background: #F5F5F5;
                border-radius: 8px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 32px;
                font-weight: bold;

                .checkbox {
                    width: 32px;
                    height: 32px;
                    border: 1px solid #DDD;
                    border-radius: 50%;
                    margin-right: 8px;
                }
            }

            .city-active {
                border: 4px solid #242831;

                .checkbox {
                    width: 48px;
                    height: 48px;
                    background: url(https://chaolucoachwebstatic.chaolu.com.cn/static/img/spring2023/check.png);
                    background-size: 100% 100%;
                    border: none;
                    margin: 0;
                }
            }
        }

        .next {
            line-height: 96px;
            background: #FFDE00;
            border-radius: 8px;
            margin-top: 64px;
            font-weight: bold;
            text-align: center;
        }
    }



    .pop-bt {
        background: transparent;

        .bg {
            width: 558px;
            height: 420px;
            background-image: url(https://chaolucoachwebstatic.chaolu.com.cn/static/img/group-invite-202304/ranking/suc.png);
            background-size: cover;
            color: #B64B0F;
            text-align: center;

            .s1 {
                font-weight: bold;
                font-size: 36px;
                padding-top: 190px;
                margin-bottom: 50px;
            }

            .s2 {
                font-weight: bold;
                font-size: 48px;
            }
        }

        .bg2 {
            width: 558px;
            height: 420px;
            background-image: url(https://chaolucoachwebstatic.chaolu.com.cn/static/img/group-invite-202304/ranking/notk.png);
            background-size: cover;
        }

        .close {
            width: 60px;
            height: 60px;
            margin: 64px auto 0;
            background-image: url(https://chaolucoachwebstatic.chaolu.com.cn/static/img/group-invite-202304/ranking/close.png);
            background-size: 100% 100%;
        }
    }

    .tip-box {
        position: relative;

        .tip-img {
            position: absolute;
            top: 7px;
            right: 130px;
            width: 300px;
        }
    }

    .pop-rule-box {
        color: #242831;
        padding: 24px 52px;

        .rule-title {
            font-weight: 600;
            font-size: 28px;
            color: #000000;
            margin-bottom: 16px;
        }

        .rule-content {
            font-size: 26px;
            margin-bottom: 40px;
            line-height: 40px;
            color: #222222;
        }

        .tt {
            margin-top: 40px;
        }
    }

}
</style>
