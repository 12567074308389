<template>
    <van-popup v-model="show" position="bottom" safe-area-inset-bottom class="pop-address">
        <div class="pop-head">
            <span class="l1">新建地址</span>
            <van-icon name="cross" class="pop-close" color="#9AA1A9" @click="show = false" />
        </div>
        <div class="a-line">
            <div class="a-label">收货人</div>
            <div class="a-value">
                <input class="ip" type="text" v-model.trim="name" placeholder="姓名" />
            </div>
        </div>
        <div class="a-line">
            <div class="a-label">手机号</div>
            <div class="a-value">
                <input class="ip" type="text" v-model.trim="phone" placeholder="手机号码" maxlength="11" />
            </div>
        </div>
        <div class="a-line">
            <div class="a-label">所在地区</div>
            <div @click="showAddress = true" class="a-value">
                <div class="ip" :class="{ default: !area }">
                    {{ area || '省、市、区' }}
                </div>
                <van-icon name="arrow" class="right-logo" />
            </div>
        </div>
        <div class="a-line" >
            <div class="a-label">详细地址</div>
            <div class="a-value">
                <input class="ip" type="text" v-model.trim="detail" placeholder="街道、小区楼栋、门牌号等" maxlength="35" />
                <!-- <textarea v-model.trim="detail" placeholder="街道、小区楼栋、门牌号等" class="ip" maxlength="80" rows="1"></textarea> -->
            </div>
        </div>
        <div @click="setAddress" class="a-sub" :class="{ gray: !(name && phone.length == 11&&/^1\d{10}$/.test(phone) && detail && area), }" :style="{background:color}">保存</div>
        <van-popup v-model:show="showAddress" position="bottom">
            <van-area :area-list="addressData" @confirm="chooseAddress" @cancel="showAddress = false" />
        </van-popup>
    </van-popup>
</template>
<script>
import addressData from '@/lib/address';
export default {
    props: {
        color: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            show: false,
            showAddress: false,
            addressData,
            name: '',
            phone: '',
            detail: '',
            area: ''
        }
    },
    methods: {
        chooseAddress(ret) {
            this.areaList = ret;
            this.area = ret[0].name + " " + ret[1].name + " " + ret[2].name;
            // data.province = ret[0].name;
            // data.city = ret[1].name;
            // data.area = ret[2].name;
            this.showAddress = false;
        },
        setAddress() {
            if (!this.name) {
                return;
            }
            if (!/^1\d{10}$/.test(this.phone) || this.phone.length != 11) {
                return;
            }
            if (!this.area) {
                return;
            }
            if (!this.detail) {
                return;
            }
            this.show = false;
            this.$emit('setAddress', { name: this.name, areaList: this.areaList, phone: this.phone, detail: this.detail });
        }
    }
}
</script>
<style lang="less" scoped>
.pop-address {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    padding-left: 32px;
    padding-right: 32px;

    .pop-head {
        height: 120px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .l1 {
            color: #242831;
            font-size: 32px;
            font-weight: bold;
        }

        .pop-close {
            font-size: 36px;
        }
    }

    .a-line {
        display: flex;
        margin-bottom: 44px;

        .a-label {
            flex-shrink: 0;
            width: 170px;
            color: #242831;
            font-size: 28px;
        }

        .a-value {
            flex: 1;
            border-bottom: 1px solid #EEEEEE;
            padding-bottom: 18px;
            position: relative;

            .ip {
                border: none;
                font-size: 28px;
            }

            .default {
                color: #ccc;
            }

            ::-webkit-input-placeholder {
                /* WebKit browsers */
                color: #ccc;
            }

            .right-logo {
                position: absolute;
                top: 4px;
                right: 0px;
            }
        }
    }

    .a-sub {
        margin-top: 300px;
        border-radius: 8px;
        line-height: 96px;
        color: #fff;
        font-size: 32px;
        background: linear-gradient(90deg, #FF7F37 0%, #FF3672 100%);
        text-align: center;
        margin-bottom: 24px;
    }

    .gray {
        opacity: 0.5;
        pointer-events: none;
    }
}</style>