<template>
    <van-popup v-model="showPop" position="bottom" safe-area-inset-bottom class="popdrawfrom">
        <div class="pop-title">
            <span class="ts">抽奖机会明细</span>
            <van-icon name="cross" class="pop-close" color="#fff" @click="showPop = false" />
        </div>
        <div class="draw-total">剩余次数：{{ numOfLottery }}次</div>
        <div class="drawfrom-box">
            <template v-if="hasData">
                <van-list v-model="loading" :finished="finished" @load="loadList" :immediate-check="false" :offset="50"
                    finished-text="没有更多了">
                    <div class="drawfrom-model" v-for="item in dataList">
                        <div class="drawfrom-way">
                            <div class="drawfrom-way-1">
                                {{ typeJson[item.diceUseType] }}
                                <span v-if="item.courseTitle && [2, 5, 6].includes(item.diceUseType)"> - {{ item.courseTitle
                                    }}</span>
                            </div>
                            <div class="drawfrom-way-2">
                                <span v-if="item.num > 0">+{{ item.num }}</span>
                                <span v-else class="color-red">{{ item.num }}</span>
                            </div>
                        </div>
                        <div class="drawfrom-time">{{ item.useDate }}</div>
                    </div>
                </van-list>
            </template>
            <div class="nulldata" v-else>
                <img src="https://img.chaolu.com.cn/ACT/anniversary-2024/index/null.png" />
                <div class="nulltip">暂无记录</div>
            </div>
        </div>
    </van-popup>
</template>
<script>
import userMixin from '@/mixin/userMixin';
export default {
    mixins: [userMixin],
    props: {
        numOfLottery: {}
    },
    data() {
        return {
            showPop: false,
            dataList: [],
            pageNum: 1,
            finished: false,
            loading: false,
            hasData: true,
            typeJson: {
                0: '开红包', 1: '登录活动页', 2: '打卡私教', 3: '邀请有礼活动', 4: '分享活动', 5: '打卡团课', 6: '打卡小班课', 7: '每日进馆30分钟'
            }
        }
    },
    methods: {
        loadList() {
            console.log('load')
            this.pageNum += 1;
            this.getData();
        },
        getData(refresh) {
            const size = 10;
            this.$axios.post(this.baseURLApp + "/prizeDrawActivity/2025/backToWorkRedEnvelope/getUserLotteryOpportunitiesDetails", {
                hasWx: true,
                userId: this.userId,
                current: this.pageNum,
                size
            }).then((res) => {
                const d = res.data.records || [];
                if (!d.length && this.dataList.length == 0) {
                    this.hasData = false;
                } else {
                    this.hasData = true;
                }
                if (refresh) {
                    this.dataList = d;
                } else {
                    this.dataList.push(...d);
                }
                this.finished = d.length < size;
                this.loading = false;
            })
        },
        show() {
            this.loading = true;
            this.pageNum = 1;
            this.dataList = [];
            this.getData(true);
            this.showPop = true;
        }
    }
}
</script>
<style scoped lang="less">
.popdrawfrom {
    border-radius: 24px 24px 0px 0px;
    background: #fff;

    .draw-total {
        font-size: 24px;
        color: #242831;
        text-align: center;
    }

    .drawfrom-box {
        height: 800px;
        overflow: auto
    }

    .drawfrom-model {
        padding: 42px 9px;
        margin: 0 32px;
        border-bottom: 1px solid #ECECEC;
        color: #242831;

        .drawfrom-way {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-weight: bold;
            line-height: 1;


            .drawfrom-way-1 {
                font-size: 28px;
            }

            .drawfrom-way-2 {
                font-size: 28px;

                .color-red {
                    color: #F03C18;
                }
            }
        }

        .drawfrom-time {
            font-size: 22px;
            margin-top: 26px;
            line-height: 1;
        }
    }

    .nulldata {
        height: 100%;
        padding-top: 200px;

        img {
            width: 294px;
            height: 294px;
            display: block;
            margin: 0 auto;
        }

        .nulltip {
            font-size: 28px;
            color: #6C727A;
            line-height: 1;
            margin-top: 30px;
            text-align: center;
        }
    }
}
</style>