<template>
  <div class="content">
    <headeBar left-arrow :color="header_color" title="私教分" :background="header_background" :opacity="header_opacity"
      :placeholder="true" @click-left="newAppBack" />
    <div class="main">
      <img class="avt" :src="info.imgUrl" alt="">
      <span class="name">{{ info.teacherName }}教练</span>
      <span class="go" @click='goClass'>约课</span>
    </div>
    <div class="box info">
      <div class="radar-box">
        <radarVue class="radar" :info="info" />
      </div>
      <div class="btns">
        <span class="rank" @click="goRank">私教榜单</span><span class="share" @click="isShareShow = true">分享好友</span>
      </div>
    </div>
    <div class="box explain">
      <img class="title" src="https://img.chaolu.com.cn/ACT/teacher-detail/socre/title.png" alt="">
      <p class="p-t">Q1：什么是超鹿私教分？</p>
      <p class="p-c">超鹿私教分是由多个维度对私教教练进行多维度量化评估，综合得分越高，代表综合表现越好，供会员进行教练选择参考，主要评估维度包含 专业水平、授课经验、会员服务、用户评价、综合素质。</p>
      <img class="ex" src="https://img.chaolu.com.cn/ACT/teacher-detail/socre/ex.png" alt="">
      <p class="p-t">Q2：为什么有的教练没有教练分评分？</p>
      <p class="p-c">超鹿私教分是由综合性长期评分得出，为保证评分绝对公正、公平、客观，给到会员尽可能中肯的评分体系参考，部分新入职超鹿运动的教练和评分统计数据不足的教练不进行展现。</p>
    </div>
    <!-- 海报 组件 -->
    <common-share :value="isShareShow" :shareParams="shareParams" @close="isShareShow = false"
      @share-success="isShareShow = false" :shareItem="['posterfriend', 'postermessage', 'save']"
      :miniSharePoster="appTypeStr === 'mini'">
      <template slot="createDom">
        <div class="canvascss"> 
          <div class="share-item">
            <div class="top">
              <div class="avt">
                <img :src="info.imgUrl" alt="">
              </div>
              <span class="name pb10">{{ info.teacherName }}</span>
            </div>
            <div class="r-box">
              <radarVue class="radar" :info="info" />
            </div>
            <div class="bottom">
              <img class="logo" src="https://img.chaolu.com.cn/ACT/teacher-detail/socre/logo.png" alt="">
              <img class="qr" v-if="userDetail.qrCode" :src="`data:image/png;base64,${userDetail.qrCode}`" />
            </div>
          </div>
         </div>
      </template>
    </common-share>
  </div>
</template>

<script>
import headeBar from '@/components/app/headBar';
import radarVue from './component/radar.vue'
import commonShare from '@/components/commonShare/newShare'
import userMixin from '@/mixin/userMixin';
import wx from 'weixin-js-sdk';
import headerMixin from '@/mixin/headerMixin';
import { newAppBack, universalPushVC } from '@/lib/appMethod'
import { getParam } from '@/lib/utils'


export default {
  components: {
    headeBar, radarVue, commonShare
  },
  mixins: [userMixin, headerMixin],
  data() {
    return {
      teacherId2: '',
      info: {},
      isShareShow: false,
      shareParams: {
        title: '',
        miniImage: 'https://img.chaolu.com.cn/ACT/double12-2024/mini.png',
        path: '',
        miniProgramType: window.location.origin.indexOf('web.chaolu.com') > -1 ? '0' : '2', // 正式0，开发1，体验2
        url: 'url',
        multiple: '0.95',
        userName: 'gh_7f5b57b6a76e',
      },
      userDetail: {}, //分享的用户信息
    }
  },
  async mounted() {
    await this.$getAllInfo(['local', 'token', 'userId']);
    this.bindHeaderScroll(window, 200, (scrollTop, morehalf) => { });
    this.teacherId2 = getParam().teacherId
    this.getTeacherScore()
    this.initQr()
  },
  methods: {
    newAppBack,
    initQr() {
      this.$axios.post(`${this.baseURLApp}/qrCode/createOrdinaryCode`, {
        token: this.token, userId: this.userId, width: 240, height: 240,
        webUrl: 'https://web.chaolu.com.cn/#/ordinary_004001',
        scene: JSON.stringify({
          teacherId: this.teacherId2,
          webUrl: `${window.location.origin}/#/teacher-detail/share`,
        }),
      }).then((res) => {
        this.userDetail = res.data
      })
    },
    getTeacherScore() {
      this.$axios.post(this.baseURLApp + '/teacherDimension/app/getTeacherDimensionInfo', {
        teacherId: this.teacherId2
      }).then((res) => {
        this.info = res.data
      })
    },
    goClass() {
      universalPushVC('51', this.teacherId2, 1, 1)
    },
    goRank() {
      this.$router.push({ path: '/teacher-detail/ranking' })
    }
  }
}
</script> 

<style lang="less" scoped>
* {
  line-height: 1.1;
}

.content {
  background-image: url(https://img.chaolu.com.cn/ACT/teacher-detail/socre/bg.png);
  background-color: #F5F5F5;
  min-height: 100vh;
  background-size: 100%;
  background-repeat: no-repeat;
  padding-bottom: 60px;

  .main {
    padding: 22px 50px;
    display: flex;
    align-items: center;

    .avt {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      border: 2px solid #fff;
      margin-right: 21px;
      object-fit: cover;
    }

    .name {
      font-size: 28px;
      color: #fff;
      font-weight: 600;
      flex: 1;
    }

    .go {
      padding: 13px 24px;
      background: #FFDE00;
      border-radius: 4px;
      font-size: 24px;
      font-weight: 600;
    }
  }

  .box {
    background-color: #fff;
    padding: 40px;
    margin: 0 33px;
    border-radius: 20px;

    &.info {
      text-align: center;

      .radar {
        transform: scale(1.15);
      }

      .btns {
        margin-top: 42px;
        display: flex;
        justify-content: center;

        span {
          color: #fff;
          padding: 24px 60px;
          border-radius: 100px;

          &.rank {
            background: linear-gradient(to bottom, #49D2D8, #0DB2E1);
            margin-right: 34px;
            border: 8px solid #D9F5F7;
          }

          &.share {
            background: linear-gradient(to bottom, #4FACFE, #478FF1);
            border: 8px solid #DCEEFF;
          }
        }
      }
    }

    &.explain {
      margin-top: 36px;
      padding: 36px 30px;

      .title {
        width: 150px;
      }

      .ex {
        width: 400px;
        margin: 51px 111px 37px;
      }

      .p-t {
        font-size: 28px;
        margin-top: 40px;
        font-weight: 600;
      }

      .p-c {
        font-size: 26px;
        color: #222222;
        margin-top: 14px;
        line-height: 1.8;
      }
    }
  }
}

.canvascss {
  width: 300PX;
  // height: 539PX;
  margin: 0 auto;
  box-sizing: border-box;

  .share-item {
    background-image: url(https://img.chaolu.com.cn/ACT/teacher-detail/socre/sharebg.png);
    background-color: #212225;
    background-size: 100%;
    background-repeat: no-repeat;
    text-align: center;

    .top {
      display: flex;
      flex-direction: column;
      color: #fff;
      align-items: center;
      font-size: 28px;
      padding-top: 75px;

      .avt {
        width: 100px;
        height: 100px;
        border-radius: 100px;
        border: 2px solid #fff;
        margin-bottom: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        img{
          width: 100px;
        }
      }
    }

    .r-box {
      background: #fff;
      margin: 30px 36px;
      border-radius: 20px;
      padding: 15px 0px;
    }

    .bottom {
      margin: 44px 40px;
      padding-bottom: 50px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .logo {
        width: 206px;
        height: 70px;
      }

      .qr {
        width: 96px;
        height: 96px;
      }
    }
  }
}
</style>