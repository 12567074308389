<template>
  <div class="container">
    <headBar ref="header" title="用户拓展CRM" left-arrow @click-left="appBack" background="#3665BD" color="#fff" />
    <div class="sticky" :style="{ 'top': stickyHeight + 'px' }">
      <div class="top">
        <div class="row-between-center">
          <div class="left pub_onefull">
            <div class="user" v-if="menuIndex == 0">
              <div class="avt">
                <img class="" :src="userInfo.headImg" alt="">
              </div>
              <div>
                <p class="f34 fw6 ellipsis-1">{{ userInfo.userName }}</p>
                <p class="f24 mt6 fw4">{{ userInfo.saleTeamName }}</p>
              </div>
            </div>
            <span v-else-if="menuIndex == 1" class="f36" @click="teamShow = true">
              {{ teamName }}<van-icon name="play" class="icon1" />
            </span>
            <span v-else-if="menuIndex == 2" class="f36">项目业绩</span>
          </div>
          <div class="right" @click="menuShow = true" v-if="userInfo.hasPermissions || userInfo.projectPermissions">
            <van-icon name="sort" />{{ menu[menuIndex] }}
          </div>
        </div>
        <p class="f22 mt28 ">数据更新于：{{ nowTime }}</p>
      </div>
      <van-tabs v-model="active" line-width="44px" line-height="2px" color='#3665BD' title-active-color="#3665BD"
        title-inactive-color="#3C454E" @change="getList">
        <van-tab title="项目概览" title-class="tabs" />
        <van-tab title="活动商城" title-class="tabs" />
        <van-tab title="历史活动" title-class="tabs" />
      </van-tabs>
    </div>
    <div v-if="active == 0">
      <totalListVue :menuIndex='menuIndex' ref="totalRef"/>
    </div>
    <div v-else>
      <van-pull-refresh class="page-refresh" v-model="refreshing" @refresh="getList" pulling-text="继续往下拉"
        loosing-text="该放手了，我要刷新啦..." loading-text="拼命加载中" :pull-distance="50">
        <div class="list-box">
          <div v-for="i in actList" class="items"
            @click="$router.push(`/user-expansion/data?menuIndex=${menuIndex}&isHistory=${active == 2}&activityId=${i.activityId}&userId=${userId}&saleTeamId=${saleTeamId}`)">
            <div class="row-between-center">
              <div>
                <p class="f32 fw6">{{ i.activityName }}</p>
                <p class="f22 mt12">{{ i.activityTime }}</p>
              </div>
              <div class="pub_onefull tr">
                <van-icon name="arrow" />
              </div>
            </div>
            <div class="num">
              <div>
                <p><span>{{ i.memberNum }}</span>人</p>
                <p>会员数</p>
              </div>
              <div>
                <p><span>{{ i.inDoorNum }}</span>人</p>
                <p>到馆</p>
              </div>
              <div>
                <p><span>{{ i.saleNum }}</span>份</p>
                <p>实际销售</p>
              </div>
              <div>
                <p><span>{{ i.writeOffNum }}</span>份</p>
                <p>实际核销</p>
              </div>
            </div>
          </div>
          <van-empty v-if="actList.length == 0" description="暂无数据" />
        </div>
      </van-pull-refresh>
    </div>
    <van-popup v-model="teamShow" round position="bottom" class="chose-team-pop">
      <div class="title">
        <span @click="cancelFun">取消</span>
        <span class="fw500">选择团队</span>
        <span @click="changeTeam">确认</span>
      </div>
      <van-tree-select height="450px" :items="teamInfo" :active-id.sync="unSaleTeamId"
        @click-item="(item) => unActItem = item" :main-active-index.sync="activeIndex" />
    </van-popup>
    <van-popup v-model="menuShow" position="bottom" class="menu-pop" safe-area-inset-bottom>
      <p v-for="(i, key) in menu" class="menu-item row-between-center" :class="key == menuIndex ? 'act' : ''"
        @click="changeMenu(key)"
        v-if="key == 0 || (userInfo.hasPermissions && key == 1) || (userInfo.projectPermissions && key == 2)">
        <span>{{ i }}</span>
        <van-icon name="success" class="blue" v-if="key == menuIndex" />
      </p>
    </van-popup>
  </div>
</template>
<script>
import headBar from "@/components/app/headBar";
import userMixin from '@/mixin/userMixin';
import { getFormatDate, getParam } from '@/lib/utils'
import { newAppBack, hideAppBackBtn } from '@/lib/appMethod'
import totalListVue from "./components/totalList.vue";

export default {
  components: { headBar,totalListVue },
  mixins: [userMixin],
  data() {
    return {
      stickyHeight: 0,
      // 菜单
      menu: ['我的业绩', '团队业绩', '项目业绩'],
      menuIndex: 0,
      menuShow: false,
      // 团队
      userInfo: {},
      nowTime: '',
      teamShow: false,
      teamInfo: [],
      activeIndex: 0,
      saleTeamId: 0,
      unSaleTeamId: 0,
      unActItem: {},
      teamName: '',
      // 活动列表
      active: 0,
      refreshing: false,
      actList: [],
    }
  },
  mounted() {
    setTimeout(() => {
      this.stickyHeight = this.$refs.header.$el.offsetHeight - 1
    }, 1000)
  },
  async created() {
    hideAppBackBtn()
    await this.$getAllInfo(['userId'])
    this.getUserInfo()
  },

  methods: {
    appBack: newAppBack,
    changeMenu(index) {
      this.menuIndex = index
      this.menuShow = false
      this.getList()
    },
    changeTeam() {
      if (this.unSaleTeamId != this.saleTeamId) {
        this.saleTeamId = this.unSaleTeamId
        this.teamName = this.unActItem.text
      }
      this.teamShow = false
      this.getList()
    },
    cancelFun() {
      this.teamShow = false
      this.unSaleTeamId = this.saleTeamId
    },
    getUserInfo() {
      this.$axios.post(this.baseURLApp + "/crm/saleMembersActivitySale/getUserBaseInformation",
        { userId: this.userId, source: 1 })
        .then((res) => {
          this.userInfo = res.data
          if (this.userInfo.hasPermissions || this.userInfo.projectPermissions) {
            this.menuIndex = getParam().menuIndex || 0
            this.active = getParam().isHistory == 'true' ? 2 : 0
            this.saleTeamId = getParam().saleTeamId || ''
            this.getTeamInfo()
          }
          this.getList()
        })
    },
    getTeamInfo() {
      this.$axios.post(this.baseURLApp + "/crm/saleTeamActivitySale/getSaleTeamList",
        { userId: this.userId, source: 1 })
        .then((res) => {
          let ids = []
          Object.keys(res.data).map(item => {
            if (res.data[item].detailVoList.length > 0) {
              this.teamInfo.push(
                {
                  text: res.data[item].name,
                  children: res.data[item].detailVoList.map(i => ({ text: i.saleTeamName, id: i.saleTeamId }))
                })
              ids.push(...res.data[item].detailVoList.map(i => i.saleTeamId))
            }
          })
          let sId = getParam().saleTeamId || ''
          if (sId && ids.includes(sId * 1)) {
            this.saleTeamId = sId
            this.teamInfo.map(i => {
              i.children.map(j => {
                if (j.id == this.saleTeamId) {
                  this.teamName = j.text
                }
              })
            })
          } else {
            this.saleTeamId = this.teamInfo[0].children[0].id
            this.teamName = this.teamInfo[0].children[0].text
          }
          this.unSaleTeamId = this.saleTeamId * 1
        })
    },
    getList() {
      this.nowTime = getFormatDate(new Date(), 'yyyy-MM-dd hh:mm:ss')
      if (this.active == 0) {
        setTimeout(()=>{
          this.$refs.totalRef.getTotalData()
        })
      } else {
        let d = { isHistory: this.active == 2 }
        this.menuIndex == 1 ? d.saleTeamId = this.saleTeamId : (d.userId = this.userId, d.isProject = this.menuIndex == 2)
        this.$axios.post(this.baseURLApp + "/userExpansionBing/query/getActivityList", d)
          .then((res) => {
            this.refreshing = false
            this.actList = res.data
          })
      }
    }
  },
}

</script>

<style lang="less" scoped>
/deep/.van-tabs__wrap {
  border-bottom: 2px solid #EEEEEE;
}

/deep/.tabs {
  flex: none !important;
  padding: 24px;
}

/deep/.van-sidebar-item--select::before {
  background-color: #3665BD;
}

/deep/.van-tree-select__item--active {
  color: #3665BD;
}

.container {
  * {
    box-sizing: border-box;
  }
}

.menu-pop {
  padding: 20px 32px calc(32px + constant(safe-area-inset-bottom));
  padding: 20px 32px calc(32px + env(safe-area-inset-bottom));
  color: #6C727A;
  font-size: 28px;
  font-weight: 400;

  .menu-item+.menu-item {
    border-top: 1px solid #EEEEEE;
    padding-top: 20px;
    margin-top: 20px;
  }

  .act {
    color: #242831;
    font-weight: 500;
  }
}

.blue {
  color: #267DFF;
}

.chose-team-pop {
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 104px;
    font-size: 32px;
    font-weight: 400;
    color: #242831;
    padding: 0px 24px;
  }
}

.sticky {
  position: sticky;
  z-index: 10;

  .top {
    background-color: #3665BD;
    color: #fff;
    padding: 32px 28px;

    .left {
      margin-right: 20px;

      .user {
        display: flex;
        align-items: center;

        .avt {
          width: 108px;
          height: 108px;
          background-color: #ffffff33;
          border-radius: 12px;
          margin-right: 28px;
          padding: 4px;

          img {
            width: 100px;
            height: 100px;
            border-radius: 8px;
          }
        }

      }

      .icon1 {
        transform: rotate(90deg) translateX(-7%);
        margin-left: 8px;
        font-size: 30px;
      }

    }

    .right {
      padding: 12px 16px;
      border: 2px solid rgba(255, 255, 255, 0.5);
      border-radius: 4px;
      font-size: 22px;
      display: flex;

      i {
        margin-right: 8px;
        font-size: 30px;
        transform: rotate(90deg);
      }
    }
  }

}

.list-box {
  padding: 28px 24px;
  min-height: 72vh;

  .items {
    background: #EDF5FF;
    border-radius: 16px;
    padding: 32px;
    color: #242831;

    .num {
      display: flex;
      margin-top: 40px;
      justify-content: space-between;

      >div {
        font-size: 24px;

        >p:first-child {
          color: #267DFF;

          >span:first-child {
            font-family: BebasNeueBold;
            font-size: 60px;
          }
        }
      }
    }
  }

  .items+.items {
    margin-top: 32px;
  }
}
</style>
