// app启动屏中转页
<template>
  <div class="page">
    <nav-bar headerTitle="超鹿训记 首练得礼包" ref="head" :showBack="true" :headerBack="newAppBack"></nav-bar>

    <img class="start-img" src="https://img.chaolu.com.cn/ACT/training-record/202412/first-training.jpg" alt="">

    <div @click="go" class="bottom-btn"></div>
  </div>
</template>

<script>
import userMixin from '@/mixin/userMixin'
import navBar from '@/components/nav-bar/nav-bar'
import { newAppBack, goAppPageByNumber, appOpenWeb } from '@/lib/appMethod'

export default {
  data() {
    return {
      search: {
        token: '',
        userId: '',
        venueId: '',
      },
      startImg: ''
    };
  },

  mixins: [userMixin],
  components: {
    navBar,
  },
  async created() {
    await this.$getAllInfo(['userId'])

    this.countPoint('501', '501', '5021', this.userId, 'enter')
  },
  methods: {
    newAppBack,
    go() {
      this.countPoint('501', '501', '5021', this.userId, 'tapStartExercise')
      if (this.appTypeStr === 'mini') {
        appOpenWeb('', `${window.location.origin}/#/training-record/introduction?channelCode=drill_plan`)
      } else {
        goAppPageByNumber('3015')
      }
    }


  },
};
</script>

<style lang="less" scoped>
.page {
  box-sizing: border-box;
  position: relative;
  * {
    box-sizing: border-box;
  }

  .start-img {
    width: 100%;
  }

  .bottom-btn {
    width: 420px;
    height: 80px;
    position: fixed;
    bottom: 600px;
    left: 170px;
    z-index: 1000;

  }

}</style>
