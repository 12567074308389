<template>
    <van-popup v-model="showMyPrize" position="bottom" safe-area-inset-bottom class="popmyprize">
        <div class="pop-title color2">
            <span class="ts">我的奖品</span>
            <van-icon name="cross" class="pop-close" color="#fff" @click="showMyPrize = false" />
        </div>
        <div class="myprize-box">
            <template v-if="hasData">
                <van-list v-model="loading" :finished="finished" @load="loadList" :immediate-check="false" :offset="50"
                    finished-text="没有更多了">
                    <div class="myprize-model" v-for="item in myPrizeList">
                        <div class="myprize-pic">
                            <img :src="item.picUrl" />
                        </div>
                        <div class="myprize-info">
                            <div class="myprize-info-name">
                                <div class="name">{{ item.prizeName }}</div>
                                <template v-if="item.sendType == 'MAIL'">
                                    <div class="get" v-if="item.claimStatus == 'SENDED'" @click="seeAddress(item)">查看
                                    </div>
                                    <div class="get" v-else @click="toAddress(item)">填写地址</div>
                                </template>

                            </div>
                            <div class="myprize-info-from">{{ item.obtainDate }} 获得</div>
                            <div class="myprize-info-use">{{ item.copywriting }}</div>
                        </div>
                    </div>
                </van-list>
            </template>
            <div class="nulldata" v-else>
                <img src="https://img.chaolu.com.cn/ACT/anniversary-2024/index/null.png" />
                <div class="nulltip">暂无记录</div>
            </div>
        </div>
        <van-popup v-model="showCollectionRecords" position="bottom" safe-area-inset-bottom class="popcr">
            <div class="pop-title">
                <span class="ts">领取记录</span>
                <van-icon name="cross" class="pop-close" color="#fff" @click="showCollectionRecords = false" />
            </div>
            <div class="collection-records">
                <div class="mode2">
                    <img src="https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/group-invite-202304/map.png"
                        alt="" class="maplogo" />
                    <div class="pub_onefull" v-if="getObj.address">
                        <div class="line1">{{ getObj.address.province + getObj.address.city +
                            getObj.address.district
                            }}
                        </div>
                        <div class="line2">{{ getObj.address.addressDetail }}</div>
                        <div class="line3">{{ getObj.address.name }} <span class="phone">{{ getObj.address.phone
                                }}</span> </div>
                    </div>
                </div>
                <div class="jg"></div>
                <div class="records-box">
                    <div class="records-model">
                        <div class="records-img">
                            <img :src="getObj.picUrl" alt="" />
                        </div>
                        <div class="records-info">
                            <div class="records-name">{{ getObj.prizeName }}</div>
                            <div class="records-tips">您已填写地址，奖品确认发货后将以短信形式通知您，并自动核销账户上的实物优惠券</div>
                        </div>
                    </div>
                </div>
            </div>
        </van-popup>
    </van-popup>
</template>
<script>
import userMixin from '@/mixin/userMixin';
export default {
    mixins: [userMixin],
    data() {
        return {
            showMyPrize: false,
            myPrizeList: [],
            pageNum: 1,
            finished: false,
            loading: false,
            hasData: true,
            showCollectionRecords: false,
            getObj: {}
        }
    },
    methods: {
        toAddress(item) {
            this.$router.push('/superdeer-activity/spring-awakening-2025/receive-reward?rewardId=' + item.userPrizeId)
        },
        async seeAddress(item) {
            this.$axios
                .post(this.baseURLApp + "/prizeDrawActivity/2025/backToWorkRedEnvelope/getUserPrizeDetail", {
                    userPrizeId: item.userPrizeId,
                    userId: this.userId,
                    "hasWx": true,
                })
                .then((res) => {
                    this.getObj = res.data;
                    this.showCollectionRecords = true;
                })
        },
        loadList() {
            console.log('load')
            this.pageNum += 1;
            this.getMyPrize();
        },
        getMyPrize(refresh) {
            const size = 10;
            this.$axios.post(this.baseURLApp + "/prizeDrawActivity/2025/backToWorkRedEnvelope/getUserPrizeDetails", {
                hasWx: true,
                userId: this.userId,
                current: this.pageNum,
                size
            }).then((res) => {
                const d = res.data.prizeInfos && res.data.prizeInfos.records || [];
                if (!d.length && this.myPrizeList.length == 0) {
                    this.hasData = false;
                } else {
                    this.hasData = true;
                }
                if (refresh) {
                    this.myPrizeList = d;
                } else {
                    this.myPrizeList.push(...d);
                }
                this.finished = d.length < size;
                this.loading = false;
            })
        },
        show() {
            this.loading = true;
            this.pageNum = 1;
            this.myPrizeList = [];
            this.getMyPrize(true);
            this.showMyPrize = true;
        }
    }
}
</script>
<style scoped lang="less">
.popmyprize {
    border-radius: 24px 24px 0px 0px;
    background: #F5F5F5;

    div {
        box-sizing: border-box;
    }

    img {
        vertical-align: top;
        width: 100%;
    }

    .color2 {
        background: linear-gradient(#FFCDCD 0%, #F5F5F5 100%);
    }

    .myprize-box {
        height: 800px;
        overflow: auto
    }

    .myprize-model {
        // height: 192px;
        background: #FFFFFF;
        border-radius: 16px;
        display: flex;
        padding: 24px;
        margin: 20px 32px 0;

        .myprize-pic {
            width: 144px;
            height: 144px;
            margin-right: 36px;

            img {
                height: 100%
            }
        }

        .myprize-info {
            flex: 1;
            padding-top: 4px;

            .myprize-info-name {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .name {
                    font-size: 32px;
                    color: #242831;
                    line-height: 1;
                    font-weight: bold;
                }

                .get {
                    width: 120px;
                    line-height: 52px;
                    background: #FE452A;
                    border-radius: 10px;
                    text-align: center;
                    font-weight: 600;
                    font-size: 22px;
                    color: #FFFFFF;
                }
            }

            .myprize-info-from {
                font-size: 22px;
                color: #242831;
                line-height: 1;
                margin: 24px 0 12px;
            }

            .myprize-info-use {
                font-size: 20px;
                color: #242831;
                line-height: 26px;
            }
        }
    }

    .popcr {
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;


        .collection-records {
            .mode1 {
                font-size: 26px;
                color: #5A0A00;
                text-align: center;
                padding: 32px 0;
            }

            .mode2 {
                display: flex;
                align-items: center;
                padding: 40px 32px 56px;
                position: relative;


                .maplogo {
                    width: 40px;
                    height: 40px;
                    margin-right: 20px;
                }

                .line1 {
                    font-size: 24px;
                    color: #666;
                }

                .line2 {
                    color: #242831;
                    font-size: 30px;
                    font-weight: bold;
                    margin: 20px 0;
                }

                .line3 {
                    color: #242831;
                    font-size: 26px;

                    .phone {
                        color: #666;
                    }
                }
            }

            .jg {
                height: 16px;
                background: #F5F5F5;
            }

            .records-box {
                min-height: 520px;
            }

            .records-model {
                display: flex;
                padding: 48px 32px 0;

                .records-img {
                    width: 160px;
                    height: 160px;
                    margin-right: 40px;
                }

                .records-name {
                    font-size: 28px;
                    font-weight: bold;
                    color: #242831;
                    margin-bottom: 20px;
                }

                .records-tips {
                    font-size: 22px;
                    color: #666;
                    line-height: 34px;
                }
            }
        }
    }

    .nulldata {
        height: 100%;
        padding-top: 200px;

        img {
            width: 294px;
            height: 294px;
            display: block;
            margin: 0 auto;
        }

        .nulltip {
            font-size: 28px;
            color: #6C727A;
            line-height: 1;
            margin-top: 30px;
            text-align: center;
        }
    }
}
</style>