<template>
  <div class="container pb pt">
    <nav-bar :headerStyle="{'background': 'transparent'}" ref="head" :headerBack="newAppBack" />
    <app-page>
      <div class="card">
        <p class="p1 f28 fw6">感谢你过去一年的坚持与努力<br>新年立个Flag，朝着目标前进吧！</p>
        <div class="flags">
        <div class="flag row-start-center" v-for="item,index in flags" :key="index" @click="choose(index)">
            <img class="star" :src="curIndex === index ? 'https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/flag-202201/star-1.png' : 'https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/flag-202201/star-2.png'" alt="">
            <div :class="['content', 'col-center-start', curIndex === index ? 'active' : '']">
              <p class="p2 f22 fw6">{{item.name}}</p>
              <p class="p3 f18">{{item.desc}}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="button-box fixed" style="background: transparent;">
        <div class="cl-button f32 fw5 row-center-center" style="background: #232831; color: #fff;" @click="share">分享海报，让大家一起见证吧~</div>
      </div>
    </app-page>

    <!--  分享配置  -->
      <common-share
          :value="showShare"
          :reload="true"
          :shareParams="shareParams"
          @close="showShare = false"
          @share-success="shareSuccess"
          :shareItem="shareItem"
          :miniSharePoster="appTypeStr === 'mini'"
          @click-share-item="clickShareItem"
      >
          <template slot="createDom">
              <!-- 需要绘制的dom 写这 -->
              <div class="share-poster-cont">
                <div class="share-content col-center-center">
                  <div class="f22 fw5 col-center-center" :style="`color: ${color};`">
                    <template v-if="$route.query.swiper === '0'">
                      <div class="col-center-center" v-if="yearData.ticket.status === '1'">
                        <p class="p1">过去的一年</p>
                        <p class="p1">我一共去了场馆<span class="p2 sw-1 f28 fw6">{{yearData.ticket.arrivalsCount}}</span>次</p>
                        <p class="p1"><span class="p2 sw-1 f28 fw6">{{yearData.ticket.maxDay}}</span>是我在馆最久的一天</p>
                        <p class="p1">专注运动</p>
                        <p class="p1">只为了让自己变得更好！</p>
                      </div>
                      <div class="col-center-center" v-else>
                        <p class="p1">慢慢变好，<br>
                          是给自己最好的礼物，<br>
                          还未在超鹿运动遇见你，<br>
                          希望今年里能在场馆看到你挥洒汗水的身影。
                        </p>
                      </div>
                    </template>

                    <template v-if="$route.query.swiper === '1'">
                      <div class="col-center-center" v-if="yearData.course.status === '1'">
                        <p class="p1">过去的一年，我累计上了<span class="p2 sw-2 f28 fw6">{{yearData.course.courseCount}}</span>节团课</p>
                        <p class="p1">累计消耗<span class="p2 sw-2 f28 fw6">{{yearData.course.courseCalorie}}</span>卡路里</p>
                        <p class="p1">其中<span class="p2 sw-2 f28 fw6">{{yearData.course.favoriteCourseName}}</span>课程是我的最爱，</p>
                        <p class="p1">一共约了<span class="p2 sw-2 f28 fw6">{{yearData.course.favoriteCourseCount}}</span>节</p>
                        <p class="p1"><span class="p2 sw-2 f28 fw6">{{yearData.course.favoriteCourseTeacherName}}</span>是我约课最多的教练</p>
                      </div>
                      <div class="col-center-center" v-else>
                        <p class="p1">打磨过的身材，<br>
                          胜任各种衣服的剪裁，<br>
                          今年我要努力加练！
                        </p>
                      </div>
                    </template>

                    <template v-if="$route.query.swiper === '2'">
                      <div class="col-center-center" v-if="yearData.per.status === '1'">
                        <p class="p1">我一共完成 <span class="p2 sw-3 f28 fw6">{{yearData.per.perCount}}</span>节私教课</p>
                        <p class="p1"><span class="p2 sw-3 f28 fw6">{{yearData.per.favoritePerTeacherName}}</span>是我的御用教练</p>
                        <p class="p1">在蜕变的路上</p>
                        <p class="p1">超鹿私教一直陪伴和鼓励我</p>
                      </div>
                      <div class="col-center-center" v-else>
                        <p class="p1">为了更多的火锅、<br>
                          烧烤、<br>
                          奶茶...<br>
                          是时候约一节私教啦！
                        </p>
                      </div>
                    </template>


                    <template v-if="$route.query.swiper === '3'">
                      <div class="col-center-center" v-if="yearData.camp.status === '1'">
                        <p class="p1">过去的一年</p>
                        <p class="p1">我完成了<span class="p2 sw-4 f28 fw6">{{yearData.camp.campCount}}</span>期训练营</p>
                        <p class="p1">对自我要求的不断精进</p>
                        <p class="p1">让我变得更好！</p>
                      </div>
                      <div class="col-center-center" v-else>
                        <p class="p1">放弃最轻松，但坚持一定很酷！<br>
                          渴望改变，就一起练！<br>
                        </p>
                      </div>
                    </template>
                  </div>
                </div>
                <div class="flag f46">{{ curIndex === '' ? '' : flags[curIndex].name}}</div>
                <div class="desc f24" :style="`color: ${color}`">#&nbsp;&nbsp;{{ curIndex === '' ? '' : flags[curIndex].desc}}&nbsp;&nbsp;#</div>
                <img :src="'data:image/png;base64,' + qrCode" alt="" class="qr">
                <img class="share-bg" :src="`https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/flag-202201/share${$route.query.swiper * 1 + 1}.jpg`" alt="">
              </div>
          </template>
      </common-share>

  </div>
</template>

<script>
import { closeWindow, newAppBack } from '@/lib/appMethod'
import navBar from '@/components/nav-bar/nav-bar'
import userMixin from '@/mixin/userMixin'
import commonShare from "@/components/commonShare";
import appPage from "@/common/components/appPage";
import wx from "weixin-js-sdk"
export default {
  mixins: [userMixin],
  components: {
    navBar,
    commonShare,
    appPage
  },
  data() {
    return {
      yearData: {
        per: {},
        ticket: {},
        course: {},
        camp: {},
      }, // 年度数据
      qrCode: '',
      flags: [
        {name: '坚持每周运动三次', desc: '让洗澡水见证流过的汗水'},
        {name: '减重5KG', desc: '惊艳朋友圈'},
        {name: '体脂<20%', desc: '拍照不靠后期P'},
        {name: '练出马甲线', desc: '马甲线很棒，我也想拥有'},
        {name: '多陪父母', desc: '所以约上他们一起上课！'},
        {name: '减肥的压力不给头像', desc: '不瘦十斤不换头像'},
        {name: '上课不划水', desc: '人生海海不划水也很精彩'},
        {name: '健康饮食', desc: '是吃货，各领域都能发光'},
        {name: '早睡早起', desc: '喊那么多次,该实现一次'},
        {name: '动作进阶', desc: '教练请你别放过'},
        {name: '少吃糖', desc: '今年只吃CP发的糖'},
        {name: '杠铃加片', desc: '杠铃重一点，体重轻一点'},
        {name: '保持好奇心', desc: '不同的课我都要试试看'},
        {name: '拥有蜜桃臀', desc: '翘臀不再靠P图'}
      ],
      curIndex: '',

      showShare: false,
      shareItem: ['posterfriend', 'postermessage', 'save'],
      shareParams: {
          title: '虎鹿瘦喜 新年flag',
          miniImage: 'https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/flag-202201/flag-share.png',
          path: '/pages/webView/index?webUrl='+window.location.origin+'/#/superdeer-activity/flag-202201/page-start&type=' + this.$route.query.type,
          miniProgramType: "0", // 正式0，开发1，体验2
          url:'url',
          multiple: "0.95",
          userName: "gh_7f5b57b6a76e",
      },
    }
  },
  computed: {
    color() {
      switch (this.$route.query.swiper) {
        case '0':
          return '#FF6E00'
          break;
        case '1':
          return '#018680'
          break;
        case '2':
          return '#ED3E43'
          break;
        default:
          return '#162572'
          break;
      }
    }
  },
  methods: {
    newAppBack,
    choose(index) {
      this.curIndex = index
    },
    share() {
      if (this.curIndex === '') {
        this.$toast({
          message: '还没选择新年Flag哦~',
          duration: 3000
        });
        return
      }
      this.showShare = true
      this.countPoint('43','311','1489')
    },
    closeWindow,
    shareSuccess() {
      this.showShare = false
      setTimeout(() => {
        this.$toast({
          message: '分享成功',
          duration: 4000
        });
      }, 1000);
      sessionStorage.setItem('startPath',this.$route.path);
    },
    clickShareItem(key) {
      switch (key){
        case 'postermessage': // 分享图片到朋友圈
          this.countPoint('43','311','1492')
          break;
        case 'save': // 保存图片
          this.countPoint('43','311','1493')
          break;
        case 'posterfriend': // 分享小程序给好友
          this.countPoint('43','311','1491')
          break;
      }
    },
    getData() {
      this.$axios.post(this.baseURLApp + '/summary/2021/detail', {
        token: this.token,
        userId: this.userId
      }).then((res) => {
        this.yearData = res.data
      })
    },
    getQrcode() {
      this.$axios.post(`${this.baseURLApp}/qrCode/createOrdinaryCode`, {
        userId: this.userId,
        token: this.token,
        width: 240,
        height: 240,
        webUrl: "https://web.chaolu.com.cn/#/ordinary_004001",
        scene: JSON.stringify({type: '1', source: "tiger-flag", webUrl: window.location.origin+'/#/superdeer-activity/flag-202201/page-start'})
      }).then(res => {
        this.qrCode = res.data.qrCode
      })
    },
  },
  async created() {
    await this.$getAllInfo()
    this.getQrcode()
    this.getData()
    this.countPoint('43','311','1488')
    console.log(this.appTypeStr);
    if (this.appTypeStr === 'mini') {
      wx.miniProgram.postMessage({
        data: {
          type: 'share',
          shareImage: this.shareParams.miniImage,
          shareTitle: this.shareParams.title,
          shareUrl: this.shareParams.path,
        }
      });
    }
    
  },
}
</script>
<style lang="less" scoped>
@import url(./index.less);
* {
  box-sizing: border-box;
}
.container {
  width: 100%;
  min-height: 100vh;
  background: url(https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/flag-202201/flag-bg1.png);
  background-position: center;
  background-size: cover;
  padding-top: calc(100px + constant(safe-area-inset-top));
  padding-top: calc(100px + env(safe-area-inset-top));
  .card {
    width: 750px;
    height: 1326px;
    padding: 118px 32px 46px;
    font-family: AlibabaPuHuiTiH;
    margin: 0 auto 0;
    background-image: url(https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/flag-202201/flag-bg2.png);
    background-size: 100% 100%;
    .p1 {
      position: relative;
      padding: 0 0 0 24px;
      font-family: PuHuiTi;
      &::after {
        content: '';
        width: 662px;
        position: absolute;
        bottom: -22px;
        left: 12px;
        border-bottom: 1px dashed #242831;

      }
    }
  }
  .flags {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin: 60px 0 0;
    padding: 0 24px;
    .flag {
      margin: 0 0 30px;
      &:nth-child(even) {
        margin-left: auto;
      }
      .star {
        width: 54px;
      }
      .p2, .p3 {
        color: #DD3403;
      }
      .content {
        width: 228px;
        height: 100px;
        margin: 0 0 0 8px;
        padding: 0 12px;
        color: #fff;
        background-image: url(https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/flag-202201/flag-bg.png);
        background-size: 100% 100%;
        text-align: left;
        position: relative;
        &.active {
          border: 2px solid #FF6C2D;
          border-radius: 12px;
        }
        .p3 {
          font-size: 18px;
        }
      }
    }
  }

  .share-poster-cont {
    width: 686px;
    margin: 0 auto;
    position: relative;
    .share-content {
      width: 550px;
      height: 270px;
      position: absolute;
      top: 120px;
      left: 70px;
      .p1 {
        line-height: 40px;
      }
      .p2 {
        font-family: BebasNeueBold;
        padding: 4px 12px 0;
        border-radius: 6px;
        &.sw-1 {
          color: #FF6E00;
          background: #FFF69E;
        }
        &.sw-2 {
          color: #016F6A;
          background: #B9E6FF;
        }
        &.sw-3 {
          color: #E95455;
          background: #FFE4D9;
        }
        &.sw-4 {
          color: #152572;
          background: #CCCAFF;
        }
      }
    }
    .qr {
      width: 108px;
      position: absolute;
      bottom: 74px;
      right: 42px;
      border-radius: 8px;
    }
    .flag {
      width: 443px;
      position: absolute;
      bottom: 90px;
      left: 59px;
      color: #fff;
      font-family: PuHuiTi;
      font-weight: 500;
      text-align: center;
    }
    .desc {
      width: 443px;
      position: absolute;
      bottom: 38px;
      left: 59px;
      color: red;
      text-align: center;
    }
    img {
      width: 100%;
      display: block;
    }
  }
}
</style>