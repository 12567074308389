<template>
    <div class="page-recommend iphonex-pb">
        <HeadeBar left-arrow @click-left="newAppBack" :placeholder="false" onlyBack color="#fff" class="rback">
        </HeadeBar>
        <div class="con" :class="{ bg1: has, bg2: has === false }">
            <template v-if="has">
                <div class="r-top">
                    <div class="title">系统根据您的问卷需求为您推荐以下课程</div>
                    <div class="reset" @click="re">重新填写</div>
                </div>
                <div class="r-model" v-for="r in recommendList">
                    <div class="pic">
                        <img :src="r.mainImage" />
                        <div class="pb">
                            <div class="sl">
                                <div v-for="item, index in 5" class="star" :class="{ active: index < r.score,half:index+1>r.score&&index-1<r.score }"></div>
                            </div>
                            <div class="cname">{{ r.title }}</div>
                        </div>

                    </div>
                    <div class="reason" v-if="r.recommendReason">{{ r.recommendReason }}</div>
                    <div class="sub puhui" @click="showCourse(r.courseId)">马上约课</div>
                </div>
                <img src="https://img.chaolu.com.cn/ACT/questionnaire/more.png" class="more" />
            </template>
            <div class="r-top" v-else>
                <div class="title">若需要定制化推荐请填写运动需求~</div>
                <div class="reset" @click="re">去填写</div>
            </div>
            <div class="m-model" v-for="r in moreList">
                <div class="m-info">
                    <div class="m-img">
                        <img :src="r.mainImage" />
                    </div>
                    <div class="m-full">
                        <div class="m-name">{{ r.title }}</div>
                        <div class="label-list" v-if="r.marks&&r.marks.length">
                            <span class="m-label" v-for="item, index in r.marks.split(',')">{{ item }}</span>
                        </div>
                        <div class="btl">
                            <div class="m-level" v-if="r.courseStrengthLabel">{{ r.courseStrengthLabel }}</div>
                            <div class="yy" @click="showCourse(r.courseId)">预约</div>
                        </div>
                    </div>
                </div>
                <div class="m-reason" v-if="r.recommendReason">
                    <div class="r1 puhui">
                        推荐理由
                    </div>
                    <div class="r2">{{ r.recommendReason }}</div>
                </div>
            </div>
        </div>
        <div style="display: none;">
            <img src="https://img.chaolu.com.cn/ACT/questionnaire/headbg.png" alt="">
            <img src="https://img.chaolu.com.cn/ACT/questionnaire/morebg.png" alt="">
        </div>
        <PopCourse ref="refPopCourse" />
    </div>
</template>

<script>
import { newAppBack, initBack, showGroupQuickDialog } from '@/lib/appMethod';
import HeadeBar from '@/components/app/headBar';
import userMixin from '@/mixin/userMixin';
import wx from 'weixin-js-sdk'
import PopCourse from '@/components/app/popCourse.vue';

export default {
    mixins: [userMixin],
    components: {
        HeadeBar,
        PopCourse
    },
    data() {
        return {
            moreList: [],
            recommendList: [],
            has: undefined
        }
    },
    methods: {
        newAppBack,
        re() {
            this.$router.replace({ path: '/group-questionnaire/index', query: { rewrite: 1 } });
        },
        showCourse(courseId) {
            // const courseId = '1238185957711151104';
            if (this.appTypeStr === 'mini') {
                this.$refs.refPopCourse.showFun(courseId)
            } else {
                showGroupQuickDialog(courseId)
            }
        },
        checkHas() {
            return this.$axios
                .post(this.baseURLApp + "/a/recommend/group/newcomer/question/isComplete", {
                    cityId: this.cityId,
                    userId: this.userId
                })
                .then((res) => {
                    if (res.data) {
                        this.has = true;
                    } else {
                        this.has = false;
                    }
                })
        }
    },
    mounted() {

    },
    async created() {
        initBack();
        await this.$getAllInfo(['userId', 'cityId', 'venueId', 'local']);
        this.checkHas().then(() => {
            if (this.has) {
                this.$axios
                    .post(this.baseURLApp + "/a/recommend/group/newcomer/question/recommend", {
                        userId: this.userId,
                        cityId:this.cityId
                    })
                    .then((res) => {
                        this.recommendList = res.data;
                    })
            }

        });

        this.$axios
            .post(this.baseURLApp + "/a/recommend/group/newcomer/question/moreRecommend", {
                userId: this.userId,
                cityId:this.cityId
            })
            .then((res) => {
                this.moreList = res.data;
            })
    }
}
</script>

<style lang="less" scoped>
.page-recommend {
    background: #FFE8AB;
    min-height: 100vh;
    
    // position: fixed;
    ////height: 100vh;
    // top: 0;
    // bottom: 0;
    // left: 0;
    // right: 0;
    // overflow: auto;
    // -webkit-overflow-scrolling: touch;

    div {
        box-sizing: border-box;
    }

    img {
        vertical-align: top;
        width: 100%;
    }

    .rback {
        /deep/.van-icon-arrow-left {
            width: 62px;
            height: 62px;
            line-height: 62px;
            border-radius: 50%;
            background: rgba(0, 0, 0, 0.4);
            text-align: center;
            font-size: 30px !important;
        }

    }

    .con {
        padding: 250px 32px 100px;
        background-repeat: no-repeat;
        background-size: 100% auto;

        &.bg1 {
            background-image: url(https://img.chaolu.com.cn/ACT/questionnaire/headbg.png)
        }

        &.bg2 {
            background-image: url(https://img.chaolu.com.cn/ACT/questionnaire/morebg.png)
        }
    }

    .r-top {
        display: flex;
        justify-content: space-between;
        margin-bottom: 35px;
        align-items: center;

        .title {
            font-weight: 600;
            font-size: 24px;
            line-height: 1;
            color: #3F2E22;
        }

        .reset {
            width: 140px;
            height: 48px;
            line-height: 48px;
            background: linear-gradient(90deg, #FF762B 0%, #FD4E26 100%);
            border-radius: 8px;
            font-weight: 600;
            font-size: 22px;
            color: #FFFFFF;
            text-align: center;
        }
    }

    .r-model {
        background: #FFFFFF;
        border-radius: 16px;
        padding: 36px 32px;
        margin-bottom: 27px;

        .pic {
            height: 220px;
            border-radius: 16px;
            position: relative;
            background: #F5F5F5;
            overflow: hidden;
            &::before{
                content:'';
                position: absolute;
                width: 100%;
                height: 100%;
                display: inline-block;
                background: linear-gradient(0deg, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0) 100%);
            }
            img {
                height: 100%;
                object-fit: cover;
            }

            .pb {
                position: absolute;
                left: 24px;
                bottom: 27px;

                .sl {
                    display: flex;
                    margin-bottom: 18px;

                    .star {
                        width: 30px;
                        height: 30px;
                        background: url(https://img.chaolu.com.cn/ACT/questionnaire/star2.png);
                        background-size: 100% 100%;
                        margin-right: 10px;
                    }

                    .active {
                        background-image: url(https://img.chaolu.com.cn/ACT/questionnaire/star.png);
                    }
                    .half {
                        background-image: url(https://img.chaolu.com.cn/ACT/questionnaire/star-half.png);
                    }
                }

                .cname {
                    font-weight: 600;
                    font-size: 30px;
                    color: #FFFFFF;
                    line-height: 1;
                }
            }

        }

        .reason {
            background: linear-gradient(0deg, #FFE7DD 0%, #FFE7CD 100%);
            border-radius: 16px;
            border: 2px solid #FF5A41;
            padding: 38px 23px 20px;
            font-size: 24px;
            color: #000000;
            line-height: 40px;
            margin: 35px 0 20px;
            position: relative;

            &::before {
                content: '';
                position: absolute;
                left: -2px;
                top: 0;
                margin-top: -17px;
                width: 163px;
                height: 44px;
                background: url(https://img.chaolu.com.cn/ACT/questionnaire/reason.png);
                background-size: 100% 100%;
            }
        }

        .sub {
            height: 80px;
            background: url(https://img.chaolu.com.cn/ACT/questionnaire/button.png);
            background-size: 100% 100%;
            text-align: center;
            font-size: 32px;
            color: #FFFFFF;
            line-height: 74px;
            margin-top: 20px;
        }
    }

    .more {
        height: 37px;
        margin: 88px 0 43px;
    }

    .m-model {
        background: #FFFFFF;
        border-radius: 16px;
        padding: 40px 32px 30px;
        margin-bottom: 20px;

        .m-info {
            display: flex;
            margin-bottom: 10px;
            .m-img {
                width: 140px;
                height: 140px;
                border-radius: 8px;
                margin-right: 24px;
                flex-shrink: 0;
                overflow: hidden;

                img{
                    height: 100%;
                    object-fit: cover;
                }
            }

            .m-full {
                flex: 1;
                overflow: hidden;
                .m-name {
                    font-weight: 600;
                    font-size: 30px;
                    color: #242831;
                    line-height: 1;
                }

                .label-list {
                    display: flex;
                    flex-wrap: wrap;
                    margin: 17px 0;

                    .m-label {
                        font-size: 20px;
                        color: #242831;
                        line-height: 1;

                        &::after {
                            content: '|';
                            margin: 0 10px;
                            position: relative;
                            top: -2px;
                        }

                        &:last-of-type::after {
                            display: none;
                        }
                    }
                }

                .btl {
                    display: flex;
                    align-items: center;

                    .m-level {
                        height: 32px;
                        background: #FFFFFF;
                        border-radius: 4px;
                        border: 1px solid #9AA1A9;
                        font-size: 20px;
                        color: #242831;
                        padding: 0 7px;
                        display: flex;
                        line-height: 1;
                        align-items: center;
                    }

                    .yy {
                        width: 120px;
                        height: 52px;
                        background: #FFDE00;
                        border-radius: 8px;
                        font-size: 24px;
                        color: #242831;
                        text-align: center;
                        line-height: 52px;
                        font-weight: bold;
                        margin-left: auto;
                    }
                }

            }
        }

        .m-reason {
            background: #F1F5FF;
            border-radius: 8px;
            margin-top: 10px;
            padding: 17px 20px 26px;

            .r1 {
                font-size: 26px;
                color: #5686FD;
                line-height: 32px;
                padding-left: 47px;
                position: relative;

                &::before {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 32px;
                    height: 32px;
                    background: url(https://img.chaolu.com.cn/ACT/questionnaire/good.png);
                    background-size: 100% 100%;
                }
            }

            .r2 {
                font-size: 22px;
                color: #000000;
                line-height: 36px;
                margin-top: 10px;
            }
        }
    }
}
</style>